import React, { FunctionComponent, createContext, useEffect } from "react";
import { useRefetchContext } from "../../../context/refetch-context";
import { Route, useGetRouteWithIdLazyQuery } from "../../../api/nachfahrbarkeit-api/generated";

interface IRouteContextProviderProps {
  routeId: string;
}

interface IRouteContext {
  route: Route | undefined;
  routeLoading: boolean;
}

export const RouteContext = createContext<IRouteContext>({ route: undefined, routeLoading: false });

export const RouteContextProvider: FunctionComponent<IRouteContextProviderProps> = (props) => {
  const { routeId, children } = props;
  const { needToRefetch, setNeedToRefetch } = useRefetchContext();
  const [routeQuery, { data: routeData, loading: routeLoading }] = useGetRouteWithIdLazyQuery();

  //initial Load
  useEffect(() => {
    routeQuery({ variables: { id: routeId } });
  }, [routeQuery, routeId]);

  //refetch
  useEffect(() => {
    if (needToRefetch) {
      routeQuery({ variables: { id: routeId } });
      setNeedToRefetch(false);
    }
  }, [needToRefetch, setNeedToRefetch, routeQuery, routeId]);

  const route = routeData?.getRouteWithId;
  return <RouteContext.Provider value={{ route: route as Route, routeLoading }}>{children}</RouteContext.Provider>;
};
