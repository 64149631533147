import React, { FunctionComponent, Fragment } from "react";
import { Dialog, DialogContent, DialogActions, Button, DialogContentText } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface ILogoutDialogProps {
  open: boolean;
  onClose: TFunction;
  onLogout: TFunction;
}

export const LogoutDialog: FunctionComponent<ILogoutDialogProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("general.logout_header")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            {t("general.button.no")}
          </Button>
          <Button onClick={props.onLogout} color="primary" autoFocus>
            {t("general.button.yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
