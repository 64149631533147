import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetRoutesForLocationAndMaterialQuery, Route } from "../../../api/nachfahrbarkeit-api/generated";
import { IRouteRow } from "./routes-table";

interface IUseRoutesTableMapper {
  mappedRoutes: IRouteRow[];
  setRoutesQuery: (query?: GetRoutesForLocationAndMaterialQuery) => void;
}

type PartialRoute = Pick<Route, "id" | "name" | "tour_number" | "filename" | "inserted_at" | "material">;

export const useRoutesTableMapper = (): IUseRoutesTableMapper => {
  const { t } = useTranslation();
  const [routesQuery, setRoutesQuery] = useState<GetRoutesForLocationAndMaterialQuery>();

  const convertCompletedRoutesToTableRow = (routes: GetRoutesForLocationAndMaterialQuery | undefined): IRouteRow[] => {
    const items = routes?.getRoutesForLocationAndMaterial;
    if (items == null) return [];

    return items.map(convertCompletedRouteToTableRow);
  };

  const convertCompletedRouteToTableRow = (route: PartialRoute): IRouteRow => ({
    id: route.id,
    materialName: route.material?.name || "-",
    tourName: route.name,
    tourNumber: route.tour_number,
    date: formatDate(route.inserted_at),
    filename: route.filename,
  });

  const formatDate = (date: string) => DateTime.fromISO(date).toFormat(t("date_time.date_time_format"));

  return {
    mappedRoutes: convertCompletedRoutesToTableRow(routesQuery),
    setRoutesQuery,
  };
};
