import { Box, CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { RouteContext } from "./route-context";
import { RouteDetailsTable } from "./route-details-table";
import { RouteDetailsMap } from "./route-details-map";
import { toast } from "react-toastify";
import { useUpdateRouteLocationMutation } from "../../../api/nachfahrbarkeit-api/generated";
import { PageHeading } from "../../../components/heading/page-heading";
import { Text } from "../../../components/text/text";
import { DriverLocationSelect } from "../../../components/selects/driver-location/driver-location-select";
import { ContentContainer } from "../../../components/content-container/content-container";
import { useRefetchContext } from "../../../context/refetch-context";

const useStyles = makeStyles((theme: Theme) => ({
  spinnerStyle: {
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  fractionInfo: {
    color: theme.palette.primary.main,
  },
}));

interface IRouteDetailsProps {}

export const RouteDetails: FunctionComponent<IRouteDetailsProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setNeedToRefetch } = useRefetchContext();
  const { routeLoading, route } = useContext(RouteContext);
  const [updateRouteLocation] = useUpdateRouteLocationMutation();

  const update = async (newLocationId: string) => {
    if (!route || !newLocationId) return;

    const result = await updateRouteLocation({
      variables: {
        routeId: route.id,
        locationId: newLocationId,
      },
    });

    if (result.errors) {
      toast.error(t("routes_management.location_select.error"));
    } else {
      toast.success(t("routes_management.location_select.success"));
      setNeedToRefetch(true);
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <PageHeading>{t("routes_management.title")}</PageHeading>
          {!routeLoading && route && (
            <>
              <Text className={classes.fractionInfo} variant={"h6"}>
                {`${t("route_details.tour_number")}: ${route.tour_number} | ${t("route_details.material")}: ${
                  route.material?.name || "-"
                }`}
              </Text>
              <Box mt={3} mb={-1}>
                <DriverLocationSelect driverLocationId={String(route.location_id)} onChange={update} />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      {routeLoading && !route && <CircularProgress className={classes.spinnerStyle} />}
      {!routeLoading && route && (
        <Grid item xs={12}>
          <Grid item>
            <ContentContainer>
              <RouteDetailsMap />
            </ContentContainer>
          </Grid>
          <Grid item>
            <ContentContainer>
              <RouteDetailsTable />
            </ContentContainer>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
