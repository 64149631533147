import React, { FunctionComponent } from "react";
import { MaterialManagementProvider } from "../../context/material-management-context";
import { RefetchProvider } from "../../context/refetch-context";
import { MaterialAdministration } from "./partials/material-administration";

interface IMaterialsManagementPageProps {}

export const MaterialsManagementPage: FunctionComponent<IMaterialsManagementPageProps> = (props) => (
  <MaterialManagementProvider>
    <RefetchProvider>
      <MaterialAdministration />
    </RefetchProvider>
  </MaterialManagementProvider>
);
