import { Grid } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FilterContainer } from "../../../components/filter-container/filter-container";
import { DriverLocationContextSelect } from "../../../components/selects/driver-location/driver-location-context-select";
import { MaterialSelect } from "../../../components/selects/material/material-select";

interface RoutesFilterProps {
  materialId?: string;
  onMaterialIdChange: (newMaterialId: string) => void;
}

export const RoutesFilter: FC<RoutesFilterProps> = (props) => {
  const { materialId, onMaterialIdChange } = props;
  const { t } = useTranslation();

  return (
    <FilterContainer>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <DriverLocationContextSelect allowEmpty />
        </Grid>

        <Grid item xs={6}>
          <MaterialSelect
            materialId={materialId}
            name={t("material_select.title")}
            onChange={onMaterialIdChange}
            allowEmpty
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};
