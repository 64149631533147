import { Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDeleteRouteMutation } from "../../../api/nachfahrbarkeit-api/generated";
import { useRefetchContext } from "../../../context/refetch-context";
import { ConfirmDialog } from "../confirm-dialog";

interface DeleteRouteDialogProps {
  routeId: string;
  open: boolean;
  onClose: () => void;
}

export const DeleteRouteDialog: VFC<DeleteRouteDialogProps> = (props) => {
  const { t } = useTranslation();
  const { routeId, open, onClose } = props;
  const { setNeedToRefetch } = useRefetchContext();

  const [deleteRoute, { loading }] = useDeleteRouteMutation({
    onCompleted: (data) => {
      if (data?.deleteRoute.error) {
        toast.error(t("delete_route_dialog.error"));
        return;
      }
      toast.success(t("delete_route_dialog.success"));
      setNeedToRefetch(true);
      onClose();
    },
  });

  return (
    <ConfirmDialog
      confirmText={t("delete_dialog.yes")}
      onClose={onClose}
      onConfirm={() => deleteRoute({ variables: { routeId } })}
      open={open}
      heading={t("delete_route_dialog.head")}
      loading={loading}
    >
      <Typography>{t("delete_route_dialog.body")}</Typography>
    </ConfirmDialog>
  );
};
