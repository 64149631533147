import { Button, Card, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useCreateDriverLocationMutation,
  useUpdateDriverLocationMutation,
} from "../../../api/nachfahrbarkeit-api/generated";
import { useDriverLocationManagementContext } from "../../../context/driver-location-management-context";
import { useRefetchContext } from "../../../context/refetch-context";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  buttonLabel: {
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: 8,
    paddingTop: 4,
  },
  actionButton: {
    width: 240,
  },
  card: {
    padding: theme.spacing(2),
  },
  driverLocation: {
    flexGrow: 1,
  },
}));

interface IDriverLocationDataProps {}

export const DriverLocationData: FunctionComponent<IDriverLocationDataProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setNeedToRefetch } = useRefetchContext();
  const { selectedDriverLocation, setSelectedDriverLocation } = useDriverLocationManagementContext();
  const [name, setName] = useState<string>("");
  const [id, setId] = useState<string>("");

  const [createDriverLocation] = useCreateDriverLocationMutation({
    onCompleted: (data) => {
      if (!data?.createDriverLocation) {
        toast.error(t("driver_location_management.create.error"));
        return;
      }
      toast.success(t("driver_location_management.create.success"));
      setSelectedDriverLocation(data.createDriverLocation);
      setNeedToRefetch(true);
    },
    onError: () => toast.error(t("driver_location_management.create.error")),
  });
  const [updateDriverLocation] = useUpdateDriverLocationMutation({
    onCompleted: (data) => {
      if (!data?.updateDriverLocation) {
        toast.error(t("driver_location_management.update.error"));
        return;
      }
      toast.success(t("driver_location_management.update.success"));
      setNeedToRefetch(true);
    },
    onError: () => toast.error(t("driver_location_management.update.error")),
  });

  useEffect(() => {
    if (selectedDriverLocation) {
      setName(selectedDriverLocation.name);
      setId(selectedDriverLocation.id);
    } else {
      setName("");
      setId("");
    }
  }, [selectedDriverLocation]);

  const onClickCreateDriverLocation = async () => {
    const validationError = validateFields();
    if (validationError) {
      toast.error(t(`driver_location_management.driver_location.data.${validationError}`));
      return;
    }
    await createDriverLocation({
      variables: {
        name: name,
      },
    });
  };

  const validateFields = (): string => {
    if (!name || name === "") {
      return "name_required";
    }
    return "";
  };

  const onSaveDriverLocation = async () => {
    const validationError = validateFields();
    if (validationError) {
      toast.error(t(`driver_location_management.driver_location.data.${validationError}`));
      return;
    }

    await updateDriverLocation({
      variables: {
        id,
        name,
      },
    });
  };

  return (
    <Card className={classes.card}>
      <Grid container direction="column" spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item className={classes.driverLocation}>
            <Typography className={classes.title}>
              {!selectedDriverLocation
                ? t("driver_location_management.create.info")
                : t("driver_location_management.update.info")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("driver_location_management.driver_location.data.name")}
            type="search"
            variant="outlined"
            value={name}
            fullWidth
            onChange={(event) => {
              setName(event.target.value);
            }}
            required
          />
        </Grid>
        <Grid item container direction="row" justify="flex-start" spacing={2}>
          <Grid item>
            <Button
              className={classes.actionButton}
              type="button"
              variant="contained"
              onClick={() => setSelectedDriverLocation(undefined)}
              fullWidth
            >
              {t("driver_location_management.action.cancel")}
            </Button>
          </Grid>
          <Grid item>
            {selectedDriverLocation ? (
              <Button
                className={classes.actionButton}
                type="button"
                variant="contained"
                onClick={onSaveDriverLocation}
                fullWidth
                color="primary"
              >
                {t("driver_location_management.action.save")}
              </Button>
            ) : (
              <Button
                className={classes.actionButton}
                type="button"
                variant="contained"
                onClick={onClickCreateDriverLocation}
                fullWidth
                color="primary"
              >
                {t("driver_location_management.action.create")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
