import { Grid } from "@material-ui/core";
import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useGetMaterialsQuery } from "../../../api/nachfahrbarkeit-api/generated";
import { SimpleDatePicker } from "../../../components/date-picker/simple-date-picker";
import { FilterContainer } from "../../../components/filter-container/filter-container";
import { DriverLocationContextSelect } from "../../../components/selects/driver-location/driver-location-context-select";
import { MaterialMultiSelect } from "../../../components/selects/material/material-multi-select";
import { SimpleTextField } from "../../../components/textfield/simple-textfield";
import { useCompletedRoutesOverviewContext } from "../../../context/completed-routes-overview-context";

interface ICompletedRoutesFilterProps {}

export const CompletedRoutesFilter: VFC<ICompletedRoutesFilterProps> = (props) => {
  const { t } = useTranslation();

  const { filter, setFilter } = useCompletedRoutesOverviewContext();

  const { data, loading, error } = useGetMaterialsQuery();

  const getMaterials = () => {
    return data?.getMaterials ?? [];
  };

  const isTourNumberValid = () => {
    return filter.tourNumber === "" || Number.isInteger(Number(filter.tourNumber));
  };

  return (
    <FilterContainer>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DriverLocationContextSelect />
        </Grid>

        <Grid item xs={6}>
          <MaterialMultiSelect
            options={getMaterials()}
            selectedIds={filter.materialIds}
            onChange={(newMaterialIds) => setFilter({ materialIds: newMaterialIds })}
            loading={loading}
            error={!!error}
          />
        </Grid>

        <Grid item xs={3}>
          <SimpleTextField
            label={t("completed_routes_overview.filter.tour_number")}
            value={filter.tourNumber}
            error={!isTourNumberValid()}
            onChange={(newTourNumber) => setFilter({ tourNumber: newTourNumber })}
          />
        </Grid>

        <Grid item xs={3}>
          <SimpleTextField
            label={t("completed_routes_overview.filter.tour_name")}
            value={filter.tourName}
            onChange={(newTourName) => setFilter({ tourName: newTourName })}
          />
        </Grid>

        <Grid item xs={2}>
          <SimpleTextField
            label={t("completed_routes_overview.filter.driver_name")}
            value={filter.driverName}
            onChange={(newDriverName) => setFilter({ driverName: newDriverName })}
          />
        </Grid>

        <Grid item xs={2}>
          <SimpleDatePicker
            label={t("completed_routes_overview.filter.from_date")}
            disableFuture
            maxDate={filter.to}
            value={filter.from}
            onChange={(newFromDate) => setFilter({ from: newFromDate })}
          />
        </Grid>

        <Grid item xs={2}>
          <SimpleDatePicker
            label={t("completed_routes_overview.filter.to_date")}
            disableFuture
            minDate={filter.from}
            value={filter.to}
            onChange={(newToDate) => setFilter({ to: newToDate })}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};
