import {
  Button,
  Card,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { Add, Clear } from "@material-ui/icons";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { User } from "../../../api/nachfahrbarkeit-api/generated";
import { ContentContainer } from "../../../components/content-container/content-container";
import { useUserManagementContext } from "../../../context/user-management-context";
import { UserRole, UserService } from "../../../services/user-service";
import { executeCallbackOnEnter } from "../../../utils/form";
import { UserListItem } from "./user-list-item";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: `calc(100% - ${theme.spacing(2)}px)`,
    display: "flex",
    flexDirection: "column",
  },
  containerTop: {
    padding: 0,
    marginBottom: theme.spacing(2),
    flex: "0 0 auto",
  },
  addIcon: {
    color: theme.palette.primary.main,
  },
  addButton: {
    borderWidth: 0.5,
    borderRadius: 3,
    borderColor: theme.palette.primary.main,
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
  },
  filterContainerCard: {
    padding: theme.spacing(2),
    width: 270,
  },
  itemsContainer: {
    padding: 0,
    flex: "1 1 auto",
    overflowY: "auto",
  },
  inputLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 4,
    paddingRight: 4,
  },
}));

const EMPTY_VALUE = "----";

interface IUserManagementSelectorProps {}

export const UserManagementSelector: FunctionComponent<IUserManagementSelectorProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filterFirstName, setFilterFirstName] = useState<string>("");
  const [filterLastName, setFilterLastName] = useState<string>("");
  const [filterRole, setFilterRole] = useState<UserRole | string>(EMPTY_VALUE);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  const { users, usersLoading, toggleCreateMode, isInCreateMode } = useUserManagementContext();

  const filterUsers = () => {
    const newFilteredUsers = users.filter(
      (user) =>
        (filterFirstName ? user.first_name.toLowerCase().includes(filterFirstName.toLowerCase()) : true) &&
        (filterLastName ? user.last_name.toLowerCase().includes(filterLastName.toLowerCase()) : true) &&
        (filterRole !== EMPTY_VALUE ? user.role === filterRole : true),
    );
    setFilteredUsers(newFilteredUsers);
  };

  const resetFilter = () => {
    setFilterLastName("");
    setFilterFirstName("");
    setFilterRole(EMPTY_VALUE);
    setFilteredUsers(users);
  };

  //Set Filtered Users after initial Load
  useEffect(() => {
    setFilteredUsers(users);
  }, [setFilteredUsers, users]);

  return (
    <ContentContainer style={classes.container}>
      <Grid className={classes.containerTop}>
        <Grid item>
          <Button type="button" variant="outlined" className={classes.addButton} onClick={toggleCreateMode} fullWidth>
            {!isInCreateMode && <Add className={classes.addIcon} />}
            {isInCreateMode && <Clear className={classes.addIcon} />}
          </Button>
        </Grid>
        <Grid item>
          <Card className={classes.filterContainerCard}>
            <Grid container direction={"column"} spacing={1}>
              <Grid item>
                <TextField
                  fullWidth
                  type="search"
                  variant="outlined"
                  placeholder={t("user_management.filter.first_name")}
                  value={filterFirstName}
                  onChange={(event) => setFilterFirstName(event.target.value)}
                  onKeyDown={executeCallbackOnEnter(filterUsers)}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  type="search"
                  variant="outlined"
                  placeholder={t("user_management.filter.last_name")}
                  value={filterLastName}
                  onChange={(event) => setFilterLastName(event.target.value)}
                  onKeyDown={executeCallbackOnEnter(filterUsers)}
                />
              </Grid>
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="role-label" className={classes.inputLabel}>
                    {t("user_management.filter.role")}
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="role-label"
                    value={filterRole}
                    displayEmpty
                    fullWidth
                    required
                    onChange={(event) => setFilterRole(event.target.value as UserRole)}
                  >
                    <MenuItem key={EMPTY_VALUE} value={EMPTY_VALUE}>
                      {t(`user_role.all`)}
                    </MenuItem>
                    {Object.values(UserService.getAvailableRoles()).map((userRole) => (
                      <MenuItem key={userRole} value={userRole}>
                        {t(`user_role.${userRole.toLowerCase()}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button variant="contained" type="button" fullWidth onClick={resetFilter}>
                  {t("user_management.filter.remove_filter")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" type="button" color="primary" fullWidth onClick={filterUsers}>
                  {t("user_management.filter.apply_filter")}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Container className={classes.itemsContainer}>
        {!usersLoading &&
          filteredUsers.map((user) => (
            <Grid item key={user.username}>
              <UserListItem user={user} />
            </Grid>
          ))}

        {usersLoading && (
          <Grid item container justify="center">
            <CircularProgress />
          </Grid>
        )}
      </Container>
    </ContentContainer>
  );
};
