import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from "@material-ui/core";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { IPagination } from "./pagination";
import { TableBodySkeleton } from "./table-body-skeleton";

export interface ISimpleTableHeader<K, V> {
  id: K;
  title: string;
  key: keyof V;
  renderHeadCell?: (header: ISimpleTableHeader<K, V>) => ReactNode;
  renderColumn?: (item: V) => ReactNode;
}

interface ISimpleTableProps<K, V> {
  headers: ISimpleTableHeader<K, V>[];
  items: V[];
  loading?: boolean;
  error?: boolean;
  pagination?: IPagination;
  getReactKey?: (item: V) => string;
  renderBodyCell?: (item: V, key: keyof V) => ReactNode;
}

export const SimpleTable = <K extends string, V>(props: ISimpleTableProps<K, V>): JSX.Element => {
  const { headers, items, loading, error, pagination } = props;
  const { t } = useTranslation();

  const renderHeadCell = (header: ISimpleTableHeader<K, V>) => {
    if (header.renderHeadCell) return header.renderHeadCell(header);

    return <TableCell align="center">{header.title}</TableCell>;
  };

  const renderBodyCell = (head: ISimpleTableHeader<K, V>, item: V) => {
    if (props.renderBodyCell) return props.renderBodyCell(item, head.key);
    if (head.renderColumn) return head.renderColumn(item);

    return <TableCell align="center">{item[head.key]}</TableCell>;
  };

  const getReactKey = (item: V) => {
    if (props.getReactKey) return props.getReactKey(item);
    if ("id" in item) return item["id"];
    if ("uuid" in item) return item["uuid"];
    return undefined;
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    if (!pagination) return;
    pagination.onPageChange(newPage);
  };

  const handleTakeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!pagination) return;
    const newTake = parseInt(event.target.value);
    pagination.onTakeChange(newTake);
  };

  const hasError = () => error;
  const isLoading = () => !hasError() && loading;
  const isValid = () => !hasError() && !isLoading();
  const isEmpty = () => isValid() && items.length <= 0;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <React.Fragment key={header.id}>{renderHeadCell(header)}</React.Fragment>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {/* error */}
        {(hasError() || isEmpty()) && (
          <TableRow>
            <TableCell colSpan={100} align="center">
              {hasError() && t("errors.fetch_error")}
              {isEmpty() && t("errors.empty")}
            </TableCell>
          </TableRow>
        )}

        {/* loading */}
        {isLoading() && <TableBodySkeleton rows={5} columns={headers.length} />}

        {/* no error && not loading */}
        {isValid() &&
          items.map((item, index) => (
            <TableRow key={getReactKey(item) ?? index}>
              {headers.map((header) => (
                <React.Fragment key={header.id}>{renderBodyCell(header, item)}</React.Fragment>
              ))}
            </TableRow>
          ))}
      </TableBody>

      {pagination && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 250, { label: "All", value: 10000 }]}
              colSpan={headers.length}
              count={pagination.total}
              rowsPerPage={pagination.take}
              page={pagination.page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleTakeChange}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};
