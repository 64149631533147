import { ApolloProvider } from "@apollo/react-hooks";
import { defaultDataIdFromObject, InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { withClientState } from "apollo-link-state";
import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { toast } from "react-toastify";
import store from "store";
import { App } from "./app/app";
import { UserProvider } from "./context/user-context";
import { ROUTES } from "./router/router";
import { STORE_KEYS, UserService } from "./services/user-service";
import i18n from "./translations/i18n";
import { config, vars } from "./utils/config";
import { ToastContainer } from "react-toastify";
import { createUploadLink } from "apollo-upload-client";
import "react-toastify/dist/ReactToastify.css";
import "react-image-lightbox/style.css";
import "./index.css";
import { LoadScript } from "@react-google-maps/api";
import { DriverLocationProvider } from "./context/driver-location-filter-context";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";

const BASE_API_URI = config().env(vars.REACT_APP_GRAPHQL_URL);
const GOOGLE_MAPS_API_KEY = "AIzaSyD1U84s3SM_0K-YcPAMRzGCJKZk3tk3K28";

// always use https
if (window.location.protocol !== "https:" && window.location.hostname !== "localhost") {
  window.location.protocol = "https:";
}

const appCache = new InMemoryCache({
  dataIdFromObject(responseObject): string | null {
    switch (responseObject.__typename) {
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
  fragmentMatcher: undefined,
});

const authLink = setContext((_, { headers }) => {
  const token = store.get(STORE_KEYS.TOKEN);
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const stateLink = withClientState({
  cache: appCache,
  resolvers: {},
  defaults: {
    authStatus: {
      __typename: "authStatus",
      status: store.get(STORE_KEYS.TOKEN) ? "loggedIn" : "loggedOut",
    },
  },
});

const apolloLinkError: ApolloLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === "Access denied! You need to be authorized to perform this action!") {
        UserService.logout(client);
        appCache.writeData({
          data: {
            authStatus: {
              __typename: "authStatus",
              status: "loggedIn",
            },
          },
        });
        window.location.href = `${window.location.origin}${ROUTES.LOGIN.PATH}`;
      } else if (message === "Access denied! You don't have permission for this action!") {
        toast.error(i18next.t("errors.forbidden"));
      } else if (message === "Argument Validation Error") {
        toast.error(i18next.t("errors.general_api_error"));
      }

      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: ApolloLink.from([
    apolloLinkError,
    stateLink,
    authLink,
    createUploadLink({ uri: BASE_API_URI }),
    new HttpLink({
      uri: BASE_API_URI,
      credentials: "same-origin",
    }),
  ]),
  cache: appCache,
  defaultOptions: {
    watchQuery: { fetchPolicy: "no-cache", errorPolicy: "all" },
    query: { fetchPolicy: "no-cache", errorPolicy: "all" },
    mutate: { errorPolicy: "all" },
  },
});

// eslint-disable-next-line
const castedClient = client as any;

ReactDOM.render(
  <LoadScript id="script-loader" googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["places"]} region="at">
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ApolloProvider client={castedClient}>
          <MuiPickersUtilsProvider utils={LuxonUtils} locale={i18n.language}>
            <UserProvider>
              <DriverLocationProvider>
                <App />
              </DriverLocationProvider>
            </UserProvider>
            <ToastContainer />
          </MuiPickersUtilsProvider>
        </ApolloProvider>
      </BrowserRouter>
    </I18nextProvider>
  </LoadScript>,
  document.getElementById("root"),
);
