import { Button, Chip, CircularProgress, Grid } from "@material-ui/core";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DriverLocation,
  GetUserQuery,
  useGetUserLazyQuery,
  User,
  UserRole,
} from "../../../api/nachfahrbarkeit-api/generated";
import { Text } from "../../../components/text/text";
import { useUserManagementContext } from "../../../context/user-management-context";
import { sortASC } from "../../../utils/sort";
import { getOptionsForRole, LocationType } from "../helper/get-options-for-role";
import { ILocationCollectionItem, LocationAssignmentDialog } from "./location-assignment/location-assignment-dialog";

interface IUserManagementTownSelectorProps {
  selectedRole?: UserRole | undefined;
  selectedGuidedDriverLocations: ILocationCollectionItem[];
  setSelectedGuidedDriverLocations: (locations: ILocationCollectionItem[]) => void;
  toggleOpenDialog: boolean;
  setToggleOpenDialog: (toggleOpenDialog: boolean) => void;
  disabled?: boolean;
}

export const UserManagementTownSelector: FunctionComponent<IUserManagementTownSelectorProps> = (props) => {
  const { t } = useTranslation();
  const {
    selectedRole,
    toggleOpenDialog,
    setToggleOpenDialog,
    selectedGuidedDriverLocations,
    setSelectedGuidedDriverLocations,
    disabled,
  } = props;
  const { selectedUser } = useUserManagementContext();

  const [selectedLocationType, setSelectedLocationType] = useState<LocationType | null>(null);

  const [newSelectedLocationType, setNewSelectedLocationType] = useState<LocationType | null>(null);

  const onQueryCompleted = (data: GetUserQuery) => {
    const userDetails = (data?.getUser as User) || {
      driver_locations: [] as DriverLocation[],
    };
    const { driver_locations } = userDetails;
    const { locationType } = getOptionsForRole(selectedRole, driver_locations);

    setSelectedLocationType(locationType);
    const mappedDriverLocations = ((driver_locations as DriverLocation[]) ?? []).map((driverLocation) => {
      return { id: driverLocation.id.toString(), name: driverLocation.name };
    });
    setSelectedGuidedDriverLocations(mappedDriverLocations.sort((a, b) => sortASC(a.name, b.name)));
  };

  const [userQuery, { loading }] = useGetUserLazyQuery({
    onCompleted: onQueryCompleted,
  });

  useEffect(() => {
    const { locationType } = getOptionsForRole(selectedRole);
    setSelectedLocationType(locationType);
  }, [selectedRole, setSelectedLocationType]);

  useEffect(() => {
    if (selectedUser && selectedUser.username) {
      const username = selectedUser.username;
      userQuery({ variables: { username } });
    }
  }, [selectedUser, userQuery]);

  const { hasAssignedLocations, possibleLocationTypes } = getOptionsForRole(selectedRole);

  useEffect(() => {
    if (toggleOpenDialog) {
      setNewSelectedLocationType(possibleLocationTypes[0]);
      setToggleOpenDialog(false);
    }
  }, [toggleOpenDialog, setToggleOpenDialog, possibleLocationTypes]);

  const getCollectionForLocationType = (locationType: LocationType | null) => {
    switch (locationType) {
      case LocationType.LOCATION:
        return selectedGuidedDriverLocations;
      default:
        return [];
    }
  };

  const onSave = (items: ILocationCollectionItem[]) => {
    const sorted = items.sort((a, b) => sortASC(a.name, b.name));
    switch (newSelectedLocationType) {
      case LocationType.LOCATION:
        setSelectedGuidedDriverLocations(sorted);
        break;
    }

    setSelectedLocationType(newSelectedLocationType);
    setNewSelectedLocationType(null);
  };

  const title = selectedLocationType
    ? t(`location_type.${selectedLocationType.toLowerCase()}_plural`, {
        count: getCollectionForLocationType(selectedLocationType).length,
      })
    : "";

  return (
    <Fragment>
      <LocationAssignmentDialog
        type={newSelectedLocationType}
        collection={getCollectionForLocationType(newSelectedLocationType)}
        open={!!newSelectedLocationType}
        onSave={onSave}
        onClose={() => setNewSelectedLocationType(null)}
      />
      <Grid container direction="column" spacing={2}>
        {hasAssignedLocations && (
          <Grid container item justify="space-between">
            <Grid item>
              <Text bold>{title}</Text>
            </Grid>
            {!disabled && (
              <Grid item>
                <Grid container spacing={1}>
                  {possibleLocationTypes.map((type) => (
                    <Grid item key={type}>
                      <Button color="primary" variant="contained" onClick={() => setNewSelectedLocationType(type)}>
                        {t("user_management.data.assign", {
                          collection: t(`location_type.${type.toLowerCase()}`),
                        })}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {loading && (
          <Grid item container justify="center">
            <CircularProgress color="primary" />
          </Grid>
        )}
        {!loading && hasAssignedLocations && getCollectionForLocationType(selectedLocationType).length > 0 && (
          <Grid item container spacing={1}>
            {getCollectionForLocationType(selectedLocationType).map((item) => (
              <Grid item key={item.id}>
                <Chip label={item.name} />
              </Grid>
            ))}
          </Grid>
        )}
        {!loading && hasAssignedLocations && getCollectionForLocationType(selectedLocationType).length === 0 && (
          <Grid item container spacing={2} justify="center">
            <Text color="secondary">
              {selectedLocationType
                ? t("user_management.data.nothing_assigned", {
                    collection: title,
                  })
                : ""}
            </Text>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};
