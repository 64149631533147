import { Card, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  card: {
    padding: theme.spacing(2),
  },
}));

interface SimpleEditItemProps<T> {
  item: T;
  getIdentifier: (item: T) => string;
  onEdit?: (item: T) => void;
  onRemove?: (item: T) => void;
}

export const SimpleEditItem = <T,>(props: SimpleEditItemProps<T>): JSX.Element => {
  const classes = useStyles();
  const { item, getIdentifier, onEdit, onRemove } = props;

  return (
    <Grid item>
      <Card className={classes.card}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.title}>{getIdentifier(item)}</Typography>
          </Grid>
          <Grid item>
            <Grid container>
              {onEdit && (
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      onEdit(item);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Grid>
              )}

              {onRemove && (
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      onRemove(item);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
