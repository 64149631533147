import React, { FunctionComponent, useState } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ImportRouteDialog } from "../../dialogs/import-route/import-route-dialog";
import { Route } from "../../../api/nachfahrbarkeit-api/generated";
import { ImportRoutesProvider } from "../../dialogs/import-route/import-route-context";

interface IImportRouteButtonProps {
  routes: Pick<Route, "id" | "name" | "filename" | "tour_number" | "inserted_at">[];
}

export const ImportRouteButton: FunctionComponent<IImportRouteButtonProps> = (props) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { routes } = props;

  return (
    <ImportRoutesProvider routes={routes}>
      <ImportRouteDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
      <Button variant="contained" type="button" color="primary" onClick={() => setDialogOpen(true)}>
        {t("routes_management.import_routes")}
      </Button>
    </ImportRoutesProvider>
  );
};
