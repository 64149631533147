import {
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  User,
  useUpdateUserMutation,
} from "../../../api/nachfahrbarkeit-api/generated";
import { ConfirmDialog } from "../../../components/dialogs/confirm-dialog";
import { DriverLocationSelect } from "../../../components/selects/driver-location/driver-location-select";
import { Text } from "../../../components/text/text";
import { useRefetchContext } from "../../../context/refetch-context";
import { useUserContext } from "../../../context/user-context";
import { useUserManagementContext } from "../../../context/user-management-context";
import { UserRole, UserService } from "../../../services/user-service";
import { isIE } from "../../../utils/browser";
import { ILocationCollectionItem } from "./location-assignment/location-assignment-dialog";
import { PasswordChanger } from "./password-changer";
import { UserManagementEmptyState } from "./user-management-empty-state";
import { UserManagementTownSelector } from "./user-management-town-selector";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
    height: "100%",
  },
  heading: {
    fontSize: 18,
  },
  deleteButton: {
    backgroundColor: "#f44336",
    color: "white",
    "&:hover": {
      backgroundColor: "#b71c1c",
    },
  },
  containerCard: {
    minHeight: 310,
    height: isIE ? "100%" : "calc(100vh - 110px)",
    overflowY: "auto",
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
  },
  contentContainer: {
    marginBottom: theme.spacing(1),
    flex: isIE ? "1 0 0" : undefined,
  },
  emptyState: {
    height: "100%",
    width: "100%",
  },
  inputLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 4,
    paddingRight: 4,
  },
}));

interface IUserManagementContentProps {}

export const UserManagementContent: FunctionComponent<IUserManagementContentProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { selectedUser, isInCreateMode, updateSelectedUser } = useUserManagementContext();
  const { user } = useUserContext();
  const { setNeedToRefetch } = useRefetchContext();

  const onMutationComplete = (message: string, user: User | undefined) => {
    toast.success(message);
    updateSelectedUser(user);
    setNeedToRefetch(true);
  };

  const [createUserMutation, { loading: createLoading }] = useCreateUserMutation({
    onCompleted: (data) => onMutationComplete(t("user_management.data.user_created"), data.createUser as User),
    onError: () => {
      toast.error(t("user_management.data.could_not_create_user"));
    },
  });

  const [updateUserMutation, { loading: updateLoading }] = useUpdateUserMutation({
    onCompleted: (data) => onMutationComplete(t("user_management.data.user_updated"), data.updateUser as User),
    onError: () => {
      toast.error(t("user_management.data.could_not_update_user"));
    },
  });

  const [deleteUserMutation, { loading: deleteLoading }] = useDeleteUserMutation({
    onCompleted: () => onMutationComplete(t("user_management.data.user_deleted"), undefined),
    onError: () => {
      toast.error(t("user_management.data.could_not_delete_user"));
    },
  });

  // is loggedin user a GUIDANCE_ADMIN?
  const isGuidanceAdmin = user ? user.role === UserRole.GUIDANCE_ADMIN : true;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [role, setRole] = useState<UserRole>(UserRole.GUIDED_DRIVER);
  const [password, setPassword] = useState<string>("");
  const [defaultDriverLocationId, setDefaultDriverLocationId] = useState<string>("");
  const [selectedGuidedDriverLocations, setSelectedGuidedDriverLocations] = useState<ILocationCollectionItem[]>([]);
  const [toggleOpenDialog, setToggleOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    if (isGuidanceAdmin && role === UserRole.GUIDED_DRIVER) {
      // assign the same driver locations of the guidance admin
      setSelectedGuidedDriverLocations(user?.driver_locations?.map((dl) => ({ id: dl.id, name: dl.name })) ?? []);
    } else {
      setSelectedGuidedDriverLocations([]);
    }
    setDefaultDriverLocationId(role === UserRole.GUIDED_DRIVER ? "" : selectedUser?.default_driver_location_id ?? "");
  }, [isGuidanceAdmin, role, user, selectedUser]);

  useEffect(() => {
    setFirstName(selectedUser ? selectedUser.first_name : "");
    setLastName(selectedUser ? selectedUser.last_name : "");
    setUsername(selectedUser ? selectedUser.username : "");
    setRole(selectedUser ? (selectedUser.role as UserRole) : UserRole.GUIDED_DRIVER);
    setPassword("");
  }, [selectedUser, setFirstName, setLastName, setUsername, setDefaultDriverLocationId]);

  const getLocationIds = () => {
    return {
      guidedDriverLocationIds: selectedGuidedDriverLocations.map((driverLocations) => driverLocations.id),
    };
  };

  const handleCreate = () => {
    createUserMutation({
      variables: {
        username,
        firstName,
        lastName,
        role,
        password,
        defaultDriverLocationId: defaultDriverLocationId || null,
        ...getLocationIds(),
      },
    });
  };

  const handleUpdate = () => {
    updateUserMutation({
      variables: {
        username,
        firstName,
        lastName,
        role,
        defaultDriverLocationId: defaultDriverLocationId || null,
        ...getLocationIds(),
      },
    });
  };

  const handleDelete = () => {
    deleteUserMutation({
      variables: {
        username,
      },
    });
    setDeleteDialogOpen(false);
  };

  const locationsValid = ![UserRole.SUPER_ADMIN, UserRole.GUIDED_DRIVER, UserRole.GUIDANCE_ADMIN].includes(role)
    ? selectedGuidedDriverLocations.length > 0
    : true;

  const createValid =
    firstName &&
    lastName &&
    password &&
    password.length > 6 &&
    role &&
    username &&
    locationsValid &&
    (role === UserRole.GUIDED_DRIVER || defaultDriverLocationId);

  const updateValid =
    firstName && lastName && locationsValid && (role === UserRole.GUIDED_DRIVER || defaultDriverLocationId);

  let disableAction: boolean;
  if (isInCreateMode) {
    disableAction = !createValid;
  } else {
    disableAction = !updateValid;
  }

  const isCreateButtonDisabled = () => {
    const flag = createLoading || updateLoading || deleteLoading || disableAction;
    return flag;
  };

  return (
    <Fragment>
      <ConfirmDialog
        heading={t("user_management.data.delete_dialog.heading")}
        text={t("user_management.data.delete_dialog.text")}
        confirmText={t("user_management.data.delete_dialog.confirm_text")}
        open={deleteDialogOpen}
        onConfirm={handleDelete}
        onClose={() => setDeleteDialogOpen(false)}
      />
      <Card className={classes.containerCard}>
        {/* SHOWN FORM IF IS IN CREATE OR EDIT MODE */}
        {(isInCreateMode || selectedUser) && (
          <Grid container direction="column" className={classes.container} justify="space-between">
            <Grid container item direction={"row"} spacing={2} className={classes.contentContainer}>
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <Text className={classes.heading} bold>
                    {selectedUser &&
                      t("user_management.data.title", {
                        firstName: selectedUser.first_name,
                        lastName: selectedUser.last_name,
                      })}
                    {isInCreateMode && t("user_management.new_user.title")}
                  </Text>
                </Grid>
                {/* FORM */}
                <Grid item xs={6}>
                  <TextField
                    id="outlined-search"
                    fullWidth
                    label={t("user_management.data.first_name")}
                    variant="outlined"
                    value={firstName}
                    onChange={(ev) => {
                      setFirstName(ev.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-search"
                    fullWidth
                    label={t("user_management.data.last_name")}
                    variant="outlined"
                    value={lastName}
                    onChange={(ev) => {
                      setLastName(ev.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-search"
                    fullWidth
                    label={t("user_management.data.username")}
                    variant="outlined"
                    disabled={!!selectedUser}
                    value={username}
                    onChange={(ev) => {
                      setUsername(ev.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PasswordChanger username={username} value={password} setValue={setPassword} />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="role-label" className={classes.inputLabel}>
                      {t("user_management.data.role")}
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="role-label"
                      value={role}
                      fullWidth
                      required
                      disabled={isGuidanceAdmin}
                      onChange={(event) => setRole(event.target.value as UserRole)}
                    >
                      {Object.values(UserService.getAvailableRoles()).map((userRole) => (
                        <MenuItem key={userRole} value={userRole}>
                          {t(`user_role.${userRole.toLowerCase()}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {role !== UserRole.GUIDED_DRIVER && (
                  <Grid item xs={6}>
                    <DriverLocationSelect
                      driverLocationId={defaultDriverLocationId}
                      onChange={setDefaultDriverLocationId}
                      name={t("user_management.data.default_driver_location")}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <UserManagementTownSelector
                    selectedRole={role}
                    selectedGuidedDriverLocations={selectedGuidedDriverLocations}
                    setSelectedGuidedDriverLocations={setSelectedGuidedDriverLocations}
                    toggleOpenDialog={toggleOpenDialog}
                    setToggleOpenDialog={setToggleOpenDialog}
                    disabled={user?.role !== UserRole.SUPER_ADMIN}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* BUTTON BAR */}
            <Grid
              item
              xs
              container
              direction={"row"}
              alignItems="flex-end"
              alignContent="flex-end"
              justify="space-between"
              className={isIE ? classes.buttonContainer : undefined}
            >
              <Grid item>
                {selectedUser && (
                  <Button
                    variant="contained"
                    onClick={() => setDeleteDialogOpen(true)}
                    disabled={createLoading || updateLoading || deleteLoading}
                  >
                    {!deleteLoading && t("user_management.data.delete_user")}
                    {deleteLoading && <CircularProgress size={24} color="primary" />}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={isInCreateMode ? handleCreate : handleUpdate}
                  disabled={isCreateButtonDisabled()}
                >
                  {!(createLoading || updateLoading) && isInCreateMode && t("user_management.data.create_user")}
                  {!(createLoading || updateLoading) && selectedUser && t("user_management.data.save_user")}
                  {(createLoading || updateLoading) && <CircularProgress size={24} color="primary" />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* EMPTY STATE */}
        {!isInCreateMode && !selectedUser && <UserManagementEmptyState />}
      </Card>
    </Fragment>
  );
};
