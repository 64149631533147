import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useDriverLocation } from "../../../context/driver-location-filter-context";
import { DriverLocationSelect } from "./driver-location-select";

interface IDriverLocationContextSelectProps {
  allowEmpty?: boolean;
}

export const DriverLocationContextSelect: FunctionComponent<IDriverLocationContextSelectProps> = (props) => {
  const { t } = useTranslation();
  const { driverLocationId, setDriverLocationId } = useDriverLocation();

  const { allowEmpty } = props;

  return (
    <DriverLocationSelect
      driverLocationId={driverLocationId}
      name={t("driver_location_context.title")}
      onChange={setDriverLocationId}
      allowEmpty={allowEmpty}
    />
  );
};
