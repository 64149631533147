import { useState } from "react";

interface IUsePagination {
  page: number;
  setPage: (newPage: number) => void;
  take: number;
  setTake: (newTake: number) => void;
}

export const usePagination = (defaultTake = 10): IUsePagination => {
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(defaultTake);

  const handlePageChange = (newPage: number) => {
    if (page === newPage) return;

    setPage(Math.max(0, newPage));
  };

  const handleTakeChange = (newTake: number) => {
    if (take === newTake) return;
    if (page !== 0) {
      setPage(0);
    }
    setTake(newTake);
  };

  return { page, setPage: handlePageChange, take, setTake: handleTakeChange };
};
