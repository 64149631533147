import React, { VFC } from "react";
import { RefetchProvider } from "../../context/refetch-context";
import { RoutesOverviewProvider } from "../../context/routes-overview-context";
import { RoutesOverview } from "./partials/routes-overview";

interface IRouteManagementPageProps {}

export const RouteManagementPage: VFC<IRouteManagementPageProps> = (props) => {
  return (
    <RefetchProvider>
      <RoutesOverviewProvider>
        <RoutesOverview />
      </RoutesOverviewProvider>
    </RefetchProvider>
  );
};
