import { Card, makeStyles, Theme } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  filterCard: {
    padding: theme.spacing(2),
  },
  filterHeading: {
    marginBottom: 5,
  },
}));

interface FilterContainerProps {}

export const FilterContainer: FC<FilterContainerProps> = ({ children }) => {
  const classes = useStyles();

  return <Card className={classes.filterCard}>{children}</Card>;
};
