import { useApolloClient } from "@apollo/react-hooks";
import {
  Drawer,
  Fade,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import lodash from "lodash";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { useUserContext } from "../../context/user-context";
import { IRouteItem, ROUTES } from "../../router/router";
import { UserService } from "../../services/user-service";
import { theme } from "../../theme/theme";
import { LogoutDialog } from "../dialogs/logout-dialog";
import { UserInfo } from "./user-info";

export const DRAWER_WIDTH_OPEN = 220;
export const DRAWER_WIDTH_CLOSED = 73;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    flexShrink: 1,
    whiteSpace: "nowrap",
    overflowX: "hidden",
  },
  drawerOpen: {
    width: DRAWER_WIDTH_OPEN,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: DRAWER_WIDTH_CLOSED,
  },
  drawerPaper: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "0 4px 16px 0 rgba(100, 101, 103, 0.25)",
  },
  logo: {
    width: "80%",
    height: "100%",
  },
  logoContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    height: 80,
    backgroundColor: theme.palette.secondary.dark,
  },
  listItem: {
    paddingLeft: "14px",
    color: "white",
    paddingTop: 10,
    paddingBottom: 10,
  },
  listItemText: {
    color: "white !important",
    fontSize: "14px",
  },

  listItemTextSelected: {
    color: "white !important",
    fontSize: "14px",
    fontWeight: "bold",
  },
  listItemTextRoot: {
    padding: 0,
  },
  list: {
    width: "100%",
    padding: 0,
  },
  subList: {
    width: "calc(100% - 25px)",
    paddingLeft: 25,
    padding: 0,
    borderTopColor: theme.palette.secondary.main,
    borderTopWidth: 1,
    borderTopStyle: "solid",
  },
  listItemIcon: {
    color: "white",
  },
  listItemIconRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: 30,
    marginRight: 10,
  },
  listItemIconRootCollapsed: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginRight: 10,
  },
  listItemRoot: {
    width: "100%",
    height: 50,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  subListItemRoot: {
    height: "unset",
    paddingTop: 2,
    paddingBottom: 2,
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  iconContainerCollapsed: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  labelContainer: {
    backgroundColor: theme.palette.secondary.dark,
    color: "white",
  },
  labelContainerOpen: {
    height: 40,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  labelContainerClose: {
    height: 0,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  sidebarGrid: {
    flexWrap: "unset",
    overflow: "hidden",
    height: "100%",
  },
  iconContainer: {
    position: "relative",
    display: "flex",
  },
  administrationIcon: {
    position: "absolute",
    bottom: -2,
    left: 10,
    width: 15,
    height: 15,
    stroke: theme.palette.secondary.main,
  },
  administrationIconSelected: {
    stroke: theme.palette.primary.main,
  },
}));

interface IMenuItem {
  title: string;
  icon: string;
  id: string;
  route?: string;
  subRoutes?: string[];
  subMenuItems?: IMenuItem[];
  action?: () => void;
}

interface ISidebarProps {
  label?: string | undefined;
  onOpenChange: (isOpen: boolean) => void;
}

export const Sidebar: React.FunctionComponent<ISidebarProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const { label, onOpenChange } = props;
  const routes = useMemo(() => Object.values(ROUTES.PORTAL.ROUTES), []);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState<boolean>(false);
  const client = useApolloClient();

  const history = useHistory();
  const location = useLocation();
  const { resetUser } = useUserContext();

  useEffect(() => {
    onOpenChange(open);
  });

  const mapRoutesToMenuItems = (routes: IRouteItem[] | undefined): IMenuItem[] => {
    if (!routes) {
      return [];
    }

    return routes
      .filter((route) => route.ROLES.includes(UserService.getRole()))
      .filter((route) => route.I18NKEY != null && route.ICON != null)
      .map((route) => {
        return {
          title: route.I18NKEY ? t(route.I18NKEY) : "",
          icon: route.ICON ?? "",
          id: route.PATH,
          route: route.PATH,
          subRoutes: route.SUBROUTES?.map((route) => route.PATH),
          subMenuItems: mapRoutesToMenuItems(route.SUBROUTES),
        };
      });
  };

  const routerItems: IMenuItem[] = mapRoutesToMenuItems(routes);

  const bottomActions: IMenuItem[] = [
    {
      id: "close",
      title: t("general.button.close"),
      icon: open ? "chevron_left" : "chevron_right",
      action: () => setOpen(!open),
    },
    {
      id: "logout",
      title: t("general.logout"),
      icon: "exit_to_app",
      action: () => {
        setIsLogoutDialogOpen(true);
      },
    },
  ];

  const onMenuItemClick = (item: IMenuItem) => {
    if (item.route) {
      history.push(item.route);
    } else if (item.action) {
      item.action();
    } else if (item.subMenuItems && item.subMenuItems.length > 0 && item.subMenuItems[0].route) {
      history.push(item.subMenuItems[0].route);
    }
  };

  const isItemSelected = (item: IMenuItem): boolean => {
    if (
      !!matchPath(location.pathname, { path: item.route }) ||
      lodash.some(item.subRoutes || [], (subRoute) => !!matchPath(location.pathname, { path: subRoute }))
    ) {
      return true;
    }
    return false;
  };

  const renderMenu = (menuItems: IMenuItem[], isSubList: boolean) => {
    return (
      <List className={isSubList ? classes.subList : classes.list}>
        {menuItems.map((item, index) => (
          <Fragment key={index}>
            <ListItem
              button={true}
              disableGutters={true}
              classes={{
                root: classNames(classes.listItemRoot, {
                  [classes.subListItemRoot]: isSubList,
                }),
                selected: classes.selected,
              }}
              onClick={() => onMenuItemClick(item)}
              selected={isItemSelected(item)}
            >
              <ListItemIcon
                classes={{
                  root: classNames(classes.listItemIconRoot, {
                    [classes.listItemIconRootCollapsed]: !open,
                  }),
                }}
              >
                <div className={classes.iconContainer}>
                  <Icon
                    className={classNames(classes.listItemIcon, {
                      [classes.iconContainerCollapsed]: !open,
                    })}
                  >
                    {item.icon}
                  </Icon>
                </div>
              </ListItemIcon>

              <Fade in={open}>
                <ListItemText
                  primary={item.title}
                  classes={{
                    primary: isItemSelected(item) ? classes.listItemTextSelected : classes.listItemText,
                    root: classes.listItemTextRoot,
                  }}
                />
              </Fade>
            </ListItem>
            {!lodash.isNil(item.subMenuItems) &&
              !lodash.isEmpty(item.subMenuItems) &&
              renderMenu(item.subMenuItems, true)}
          </Fragment>
        ))}
      </List>
    );
  };

  useEffect(() => {
    const resize = () => {
      const newOpenState = window.innerWidth > 992;
      setOpen(newOpenState);
    };
    //will mount
    window.addEventListener("resize", resize);
    //will unmount
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const filterMenuItems = useCallback((menuItems: IMenuItem[]): IMenuItem[] => {
    const mappedItems = menuItems.map((menuItem) => {
      let filteredSubMenuItems: IMenuItem[] = [];
      if (menuItem.subMenuItems && menuItem.subMenuItems.length > 0) {
        filteredSubMenuItems = filterMenuItems(menuItem.subMenuItems);
      }

      return {
        ...menuItem,
        subMenuItems: filteredSubMenuItems,
      };
    });

    return mappedItems;
  }, []);

  const filteredMenuItems = useMemo(() => filterMenuItems(routerItems), [filterMenuItems, routerItems]);

  return (
    <Drawer
      variant={"permanent"}
      anchor={"left"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: classNames([
          classes.drawerPaper,
          {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          },
        ]),
      }}
      open={open}
    >
      <Grid
        container
        alignContent={"stretch"}
        direction={"column"}
        className={classes.sidebarGrid}
        justify="space-between"
      >
        <Grid item container>
          <Grid item className={classes.logoContainer} xs={12}>
            <Grid container={true} alignContent={"center"} justify={"center"} style={{ height: "100%" }}>
              <img src={theme.LOGO_PATH} className={classes.logo} alt="Logo" />
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            {label && (
              <Grid
                item
                container
                xs={12}
                justify="center"
                alignContent="center"
                className={classNames(classes.labelContainer, {
                  [classes.labelContainerOpen]: open,
                  [classes.labelContainerClose]: !open,
                })}
              >
                <Fade in={open}>
                  <Typography>{label}</Typography>
                </Fade>
              </Grid>
            )}
            <Grid item xs={12} container alignItems="center">
              {renderMenu(filteredMenuItems, false)}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="flex-end" spacing={1}>
          <Grid item xs={12}>
            {renderMenu(bottomActions, false)}
          </Grid>
          <Grid item xs={12}>
            <UserInfo sidebarCollapsed={!open} />
          </Grid>
        </Grid>
      </Grid>
      <LogoutDialog
        onLogout={() => {
          UserService.logout(client);
          resetUser();
          history.push(ROUTES.LOGIN.PATH);
        }}
        onClose={() => setIsLogoutDialogOpen(false)}
        open={isLogoutDialogOpen}
      />
    </Drawer>
  );
};
