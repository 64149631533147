import React, { FunctionComponent, Fragment } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import { toast } from "react-toastify";
import { useGetDriverLocationsQuery } from "../../../api/nachfahrbarkeit-api/generated";
import { SimpleSelect } from "../simple-select";

interface IDriverLocationSelectProps {
  name?: string;
  driverLocationId?: string;
  onChange: (driverLocationId: string) => void;
  // Add 'None' entry to select
  allowEmpty?: boolean;
}

export const DriverLocationSelect: FunctionComponent<IDriverLocationSelectProps> = (props) => {
  const { t } = useTranslation();
  const { name, driverLocationId, onChange, allowEmpty } = props;

  const {
    data: driverLocationData,
    loading: driverLocationsLoading,
    error: driverLocationsError,
  } = useGetDriverLocationsQuery();

  if (driverLocationsError) {
    toast.error(t("driver_location_select.error"));
    return <></>;
  }

  if (driverLocationsLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Skeleton height={50} width="100%" />
      </Grid>
    );
  }

  const driverLocations = driverLocationData
    ? driverLocationData.driverLocations.map((driverLocation) => ({
        value: driverLocation.id,
        title: driverLocation.name,
      }))
    : [];
  if (allowEmpty) {
    driverLocations.push({ title: t("driver_location_select.none"), value: "" });
  }

  return (
    <SimpleSelect
      options={driverLocations}
      name={name || t("driver_location_select.title")}
      onChange={onChange}
      value={driverLocationId ?? ""}
    />
  );
};
