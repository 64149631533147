import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AppTheme = {
  __typename?: 'AppTheme';
  color_primary: Scalars['String'];
  color_primary_dark: Scalars['String'];
  color_accent: Scalars['String'];
  color_primary_light: Scalars['String'];
  text_color_primary: Scalars['String'];
  text_color_secondary: Scalars['String'];
  text_hint_color: Scalars['String'];
  text_subtitle: Scalars['String'];
  default_background_color: Scalars['String'];
  red: Scalars['String'];
  collection_point_color: Scalars['String'];
  merchant_color: Scalars['String'];
  depot_color: Scalars['String'];
  disposal_trailer_color: Scalars['String'];
  color_divider: Scalars['String'];
  traveled_color: Scalars['String'];
  route_color: Scalars['String'];
  upcoming_color: Scalars['String'];
  accuracy_color: Scalars['String'];
  white_transparent: Scalars['String'];
};

export type CompletedRoute = {
  __typename?: 'CompletedRoute';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  progress: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  routeId: Scalars['ID'];
  driverId: Scalars['ID'];
  tourNumber: Scalars['Int'];
  tourName: Scalars['String'];
  material: Material;
  driverName: Scalars['String'];
};

export type CompletedRouteFilter = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  tourNumber?: Maybe<Scalars['Int']>;
  tourName: Scalars['String'];
  driverName: Scalars['String'];
  materialIds: Array<Scalars['ID']>;
};

export type CompletedRouteInput = {
  uuid: Scalars['ID'];
  progress: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  routeId: Scalars['ID'];
};


export type DriverLocation = {
  __typename?: 'DriverLocation';
  id: Scalars['ID'];
  name: Scalars['String'];
  user_group_id?: Maybe<Scalars['ID']>;
  inserted_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
};

/** EventType */
export enum EventType {
  backwards = 'backwards',
  bulk = 'bulk'
}

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token: Scalars['String'];
  user: User;
};

export type Material = {
  __typename?: 'Material';
  id: Scalars['ID'];
  name: Scalars['String'];
  user_group_id?: Maybe<Scalars['Float']>;
  inserted_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export type MaterialInput = {
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  upsertCompletedRoute: CompletedRoute;
  createDriverLocation: DriverLocation;
  updateDriverLocation: DriverLocation;
  removeDriverLocation: OperationResult;
  createMaterial: Material;
  updateMaterial: Material;
  removeMaterial: OperationResult;
  uploadRoutes: Array<Route>;
  deleteRoute: OperationResult;
  updateRouteLocation: OperationResult;
  addRouteStopEvent: OperationResult;
  removeRouteStopEventInfo: OperationResult;
  importRouteFromRona: Route;
  login: LoginResponse;
  createUser: User;
  updateUser: User;
  deleteUser: Scalars['String'];
  updatePassword: User;
};


export type MutationUpsertCompletedRouteArgs = {
  completedRouteInput: CompletedRouteInput;
};


export type MutationCreateDriverLocationArgs = {
  name: Scalars['String'];
};


export type MutationUpdateDriverLocationArgs = {
  name: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationRemoveDriverLocationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateMaterialArgs = {
  material: MaterialInput;
};


export type MutationUpdateMaterialArgs = {
  material: MaterialInput;
  materialId: Scalars['ID'];
};


export type MutationRemoveMaterialArgs = {
  materialId: Scalars['ID'];
};


export type MutationUploadRoutesArgs = {
  routes: Array<RouteInput>;
  materialId: Scalars['ID'];
  driverLocationId: Scalars['ID'];
};


export type MutationDeleteRouteArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateRouteLocationArgs = {
  locationId: Scalars['ID'];
  routeId: Scalars['ID'];
};


export type MutationAddRouteStopEventArgs = {
  documentationImages: Array<Scalars['Upload']>;
  eventInfo: Scalars['String'];
  routeEventType: EventType;
  routeStopId: Scalars['ID'];
};


export type MutationRemoveRouteStopEventInfoArgs = {
  routeStopEventId: Scalars['ID'];
};


export type MutationImportRouteFromRonaArgs = {
  driverLocationId: Scalars['ID'];
  materialId: Scalars['ID'];
  route: RonaRouteInput;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationCreateUserArgs = {
  guidedDriverLocationIds: Array<Scalars['ID']>;
  role: UserRole;
  default_driver_location_id?: Maybe<Scalars['ID']>;
  password: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  username: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  guidedDriverLocationIds: Array<Scalars['ID']>;
  role: UserRole;
  default_driver_location_id?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  username: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  username: Scalars['String'];
};


export type MutationUpdatePasswordArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type OperationResult = {
  __typename?: 'OperationResult';
  error: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type PaginatedCompletedRouteResponse = {
  __typename?: 'PaginatedCompletedRouteResponse';
  items: Array<CompletedRoute>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export type PortalTheme = {
  __typename?: 'PortalTheme';
  primary_main: Scalars['String'];
  primary_light: Scalars['String'];
  primary_dark: Scalars['String'];
  primary_contrast_text: Scalars['String'];
  secondary_main: Scalars['String'];
  secondary_light: Scalars['String'];
  secondary_dark: Scalars['String'];
  secondary_contrast_text: Scalars['String'];
  error: Scalars['String'];
  background: Scalars['String'];
  text: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getCompletedRoutes: PaginatedCompletedRouteResponse;
  driverLocations: Array<DriverLocation>;
  getMaterialWithID: Material;
  getMaterials: Array<Material>;
  getRouteWithId: Route;
  getRoutes: Array<Route>;
  getRoutesFromRona: Array<RonaRoute>;
  getRoutesForLocationAndMaterial: Array<Route>;
  getMe: User;
  defaultTheme: Theme;
  getUsers: Array<User>;
  getUser: User;
  version: Scalars['String'];
};


export type QueryGetCompletedRoutesArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
  locationId: Scalars['ID'];
  filters?: Maybe<CompletedRouteFilter>;
};


export type QueryGetMaterialWithIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetRouteWithIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetRoutesForLocationAndMaterialArgs = {
  materialId: Scalars['ID'];
  locationId: Scalars['ID'];
};


export type QueryGetUserArgs = {
  username: Scalars['String'];
};

export type RonaRoute = {
  __typename?: 'RonaRoute';
  filename: Scalars['String'];
  imported: Scalars['Boolean'];
};

export type RonaRouteInput = {
  filename: Scalars['String'];
  tourName: Scalars['String'];
  tourNumber: Scalars['Int'];
};

export type Route = {
  __typename?: 'Route';
  id: Scalars['ID'];
  url: Scalars['String'];
  stops: Array<RouteStop>;
  name: Scalars['String'];
  tour_number: Scalars['Float'];
  filename: Scalars['String'];
  inserted_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  location_id?: Maybe<Scalars['Float']>;
  material_id?: Maybe<Scalars['Float']>;
  user_group_id?: Maybe<Scalars['Float']>;
  ronaFilename?: Maybe<Scalars['String']>;
  configuration: RouteConfiguration;
  material?: Maybe<Material>;
};

export type RouteConfiguration = {
  __typename?: 'RouteConfiguration';
  id: Scalars['ID'];
  next_points: Scalars['Int'];
  min_distance_to_point: Scalars['Int'];
  distance_to_start_navigation: Scalars['Int'];
};

export type RouteInput = {
  file: Scalars['Upload'];
  tourName: Scalars['String'];
  tourNumber: Scalars['Int'];
};

export type RouteStop = {
  __typename?: 'RouteStop';
  id: Scalars['ID'];
  route_id: Scalars['Float'];
  route: Route;
  sequence_number: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  address?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  heading?: Maybe<Scalars['String']>;
  inserted_at?: Maybe<Scalars['DateTime']>;
  events?: Maybe<Array<RouteStopEvent>>;
};

export type RouteStopEvent = {
  __typename?: 'RouteStopEvent';
  id: Scalars['ID'];
  stop_id: Scalars['Float'];
  stop: RouteStop;
  event_type: EventType;
  info?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['Float']>;
  inserted_at?: Maybe<Scalars['DateTime']>;
  images: Array<RouteStopEventImage>;
};

export type RouteStopEventImage = {
  __typename?: 'RouteStopEventImage';
  id: Scalars['ID'];
  url: Scalars['String'];
  inserted_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export type Theme = {
  __typename?: 'Theme';
  id: Scalars['ID'];
  name: Scalars['String'];
  portal?: Maybe<PortalTheme>;
  app?: Maybe<AppTheme>;
  logo_url: Scalars['String'];
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  usertype: UserType;
  username: Scalars['String'];
  password: Scalars['String'];
  inserted_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  role: Scalars['String'];
  theme: Theme;
  driver_locations?: Maybe<Array<DriverLocation>>;
  default_driver_location_id?: Maybe<Scalars['ID']>;
  user_group_id?: Maybe<Scalars['ID']>;
  groupHasRonaParticipation: Scalars['Boolean'];
};

/** UserRole */
export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  GUIDED_DRIVER = 'GUIDED_DRIVER',
  GUIDANCE_ADMIN = 'GUIDANCE_ADMIN'
}

/** Type of the User (Industriekunde/Gemeindekunde) */
export enum UserType {
  industry = 'industry',
  community = 'community'
}

export type AddRouteStopEventMutationVariables = Exact<{
  documentationImages: Array<Scalars['Upload']> | Scalars['Upload'];
  eventInfo: Scalars['String'];
  routeEventType: EventType;
  routeStopId: Scalars['ID'];
}>;


export type AddRouteStopEventMutation = (
  { __typename?: 'Mutation' }
  & { addRouteStopEvent: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'error'>
  ) }
);

export type CreateDriverLocationMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateDriverLocationMutation = (
  { __typename?: 'Mutation' }
  & { createDriverLocation: (
    { __typename?: 'DriverLocation' }
    & Pick<DriverLocation, 'id' | 'name' | 'inserted_at' | 'updated_at'>
  ) }
);

export type CreateMaterialMutationVariables = Exact<{
  material: MaterialInput;
}>;


export type CreateMaterialMutation = (
  { __typename?: 'Mutation' }
  & { createMaterial: (
    { __typename?: 'Material' }
    & Pick<Material, 'id' | 'name'>
  ) }
);

export type CreateUserMutationVariables = Exact<{
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: UserRole;
  password: Scalars['String'];
  defaultDriverLocationId?: Maybe<Scalars['ID']>;
  guidedDriverLocationIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'role' | 'username' | 'default_driver_location_id'>
  ) }
);

export type DeleteRouteMutationVariables = Exact<{
  routeId: Scalars['ID'];
}>;


export type DeleteRouteMutation = (
  { __typename?: 'Mutation' }
  & { deleteRoute: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'error'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  username: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type ImportRouteFromRonaMutationVariables = Exact<{
  route: RonaRouteInput;
  driverLocationId: Scalars['ID'];
  materialId: Scalars['ID'];
}>;


export type ImportRouteFromRonaMutation = (
  { __typename?: 'Mutation' }
  & { importRouteFromRona: (
    { __typename?: 'Route' }
    & Pick<Route, 'id'>
  ) }
);

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'usertype' | 'username' | 'inserted_at' | 'updated_at' | 'deleted_at' | 'role'>
    ) }
  ) }
);

export type RemoveDriverLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveDriverLocationMutation = (
  { __typename?: 'Mutation' }
  & { removeDriverLocation: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'error'>
  ) }
);

export type RemoveMaterialMutationVariables = Exact<{
  materialId: Scalars['ID'];
}>;


export type RemoveMaterialMutation = (
  { __typename?: 'Mutation' }
  & { removeMaterial: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'error'>
  ) }
);

export type RemoveRouteStopEventInfoMutationVariables = Exact<{
  routeStopEventId: Scalars['ID'];
}>;


export type RemoveRouteStopEventInfoMutation = (
  { __typename?: 'Mutation' }
  & { removeRouteStopEventInfo: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'error'>
  ) }
);

export type UpdateDriverLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateDriverLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateDriverLocation: (
    { __typename?: 'DriverLocation' }
    & Pick<DriverLocation, 'id' | 'name' | 'inserted_at' | 'updated_at'>
  ) }
);

export type UpdateMaterialMutationVariables = Exact<{
  material: MaterialInput;
  materialId: Scalars['ID'];
}>;


export type UpdateMaterialMutation = (
  { __typename?: 'Mutation' }
  & { updateMaterial: (
    { __typename?: 'Material' }
    & Pick<Material, 'id' | 'name'>
  ) }
);

export type UpdatePasswordMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'role' | 'username'>
  ) }
);

export type UpdateRouteLocationMutationVariables = Exact<{
  locationId: Scalars['ID'];
  routeId: Scalars['ID'];
}>;


export type UpdateRouteLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateRouteLocation: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'error'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: UserRole;
  defaultDriverLocationId?: Maybe<Scalars['ID']>;
  guidedDriverLocationIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'role' | 'username' | 'default_driver_location_id'>
  ) }
);

export type UploadRoutesMutationVariables = Exact<{
  routes: Array<RouteInput> | RouteInput;
  driverLocationId: Scalars['ID'];
  materialId: Scalars['ID'];
}>;


export type UploadRoutesMutation = (
  { __typename?: 'Mutation' }
  & { uploadRoutes: Array<(
    { __typename?: 'Route' }
    & Pick<Route, 'id'>
  )> }
);

export type GetCompletedRoutesQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  locationId: Scalars['ID'];
  filters?: Maybe<CompletedRouteFilter>;
}>;


export type GetCompletedRoutesQuery = (
  { __typename?: 'Query' }
  & { getCompletedRoutes: (
    { __typename?: 'PaginatedCompletedRouteResponse' }
    & Pick<PaginatedCompletedRouteResponse, 'page' | 'take' | 'total'>
    & { items: Array<(
      { __typename?: 'CompletedRoute' }
      & Pick<CompletedRoute, 'id' | 'routeId' | 'tourNumber' | 'tourName' | 'progress' | 'startDate' | 'endDate' | 'driverName'>
      & { material: (
        { __typename?: 'Material' }
        & Pick<Material, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type DefaultThemeQueryVariables = Exact<{ [key: string]: never; }>;


export type DefaultThemeQuery = (
  { __typename?: 'Query' }
  & { defaultTheme: (
    { __typename?: 'Theme' }
    & Pick<Theme, 'id' | 'name' | 'logo_url'>
    & { portal?: Maybe<(
      { __typename?: 'PortalTheme' }
      & Pick<PortalTheme, 'primary_main' | 'primary_light' | 'primary_dark' | 'primary_contrast_text' | 'secondary_main' | 'secondary_light' | 'secondary_dark' | 'secondary_contrast_text' | 'error' | 'background' | 'text'>
    )> }
  ) }
);

export type GetDriverLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDriverLocationsQuery = (
  { __typename?: 'Query' }
  & { driverLocations: Array<(
    { __typename?: 'DriverLocation' }
    & Pick<DriverLocation, 'id' | 'name'>
  )> }
);

export type GetMaterialWithIdQueryVariables = Exact<{
  getMaterialWithIdId: Scalars['ID'];
}>;


export type GetMaterialWithIdQuery = (
  { __typename?: 'Query' }
  & { getMaterialWithID: (
    { __typename?: 'Material' }
    & Pick<Material, 'id' | 'name'>
  ) }
);

export type GetMaterialsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMaterialsQuery = (
  { __typename?: 'Query' }
  & { getMaterials: Array<(
    { __typename?: 'Material' }
    & Pick<Material, 'id' | 'name'>
  )> }
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { getMe: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'username' | 'usertype' | 'role' | 'password' | 'default_driver_location_id' | 'groupHasRonaParticipation'>
    & { driver_locations?: Maybe<Array<(
      { __typename?: 'DriverLocation' }
      & Pick<DriverLocation, 'id' | 'name'>
    )>>, theme: (
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'logo_url'>
      & { portal?: Maybe<(
        { __typename?: 'PortalTheme' }
        & Pick<PortalTheme, 'primary_main' | 'primary_light' | 'primary_dark' | 'primary_contrast_text' | 'secondary_main' | 'secondary_light' | 'secondary_dark' | 'secondary_contrast_text' | 'error' | 'background' | 'text'>
      )> }
    ) }
  ) }
);

export type GetRoutesForLocationAndMaterialQueryVariables = Exact<{
  locationId: Scalars['ID'];
  materialId: Scalars['ID'];
}>;


export type GetRoutesForLocationAndMaterialQuery = (
  { __typename?: 'Query' }
  & { getRoutesForLocationAndMaterial: Array<(
    { __typename?: 'Route' }
    & Pick<Route, 'id' | 'name' | 'tour_number' | 'filename' | 'inserted_at'>
    & { material?: Maybe<(
      { __typename?: 'Material' }
      & Pick<Material, 'id' | 'name' | 'inserted_at'>
    )> }
  )> }
);

export type GetRouteWithIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRouteWithIdQuery = (
  { __typename?: 'Query' }
  & { getRouteWithId: (
    { __typename?: 'Route' }
    & Pick<Route, 'id' | 'name' | 'tour_number' | 'filename' | 'inserted_at' | 'url' | 'location_id'>
    & { stops: Array<(
      { __typename?: 'RouteStop' }
      & Pick<RouteStop, 'id' | 'sequence_number' | 'latitude' | 'longitude' | 'address' | 'text' | 'distance' | 'heading'>
      & { events?: Maybe<Array<(
        { __typename?: 'RouteStopEvent' }
        & Pick<RouteStopEvent, 'id' | 'info' | 'event_type'>
        & { images: Array<(
          { __typename?: 'RouteStopEventImage' }
          & Pick<RouteStopEventImage, 'id' | 'url' | 'inserted_at'>
        )> }
      )>> }
    )>, material?: Maybe<(
      { __typename?: 'Material' }
      & Pick<Material, 'id' | 'name' | 'inserted_at'>
    )> }
  ) }
);

export type GetRoutesFromRonaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRoutesFromRonaQuery = (
  { __typename?: 'Query' }
  & { getRoutesFromRona: Array<(
    { __typename?: 'RonaRoute' }
    & Pick<RonaRoute, 'filename' | 'imported'>
  )> }
);

export type GetUserQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'role' | 'username'>
    & { driver_locations?: Maybe<Array<(
      { __typename?: 'DriverLocation' }
      & Pick<DriverLocation, 'id' | 'name'>
    )>> }
  ) }
);

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { getUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'role' | 'username' | 'default_driver_location_id'>
  )> }
);


export const AddRouteStopEventDocument = gql`
    mutation addRouteStopEvent($documentationImages: [Upload!]!, $eventInfo: String!, $routeEventType: EventType!, $routeStopId: ID!) {
  addRouteStopEvent(
    documentationImages: $documentationImages
    eventInfo: $eventInfo
    routeEventType: $routeEventType
    routeStopId: $routeStopId
  ) {
    error
  }
}
    `;
export type AddRouteStopEventMutationFn = Apollo.MutationFunction<AddRouteStopEventMutation, AddRouteStopEventMutationVariables>;

/**
 * __useAddRouteStopEventMutation__
 *
 * To run a mutation, you first call `useAddRouteStopEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRouteStopEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRouteStopEventMutation, { data, loading, error }] = useAddRouteStopEventMutation({
 *   variables: {
 *      documentationImages: // value for 'documentationImages'
 *      eventInfo: // value for 'eventInfo'
 *      routeEventType: // value for 'routeEventType'
 *      routeStopId: // value for 'routeStopId'
 *   },
 * });
 */
export function useAddRouteStopEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRouteStopEventMutation, AddRouteStopEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddRouteStopEventMutation, AddRouteStopEventMutationVariables>(AddRouteStopEventDocument, options);
      }
export type AddRouteStopEventMutationHookResult = ReturnType<typeof useAddRouteStopEventMutation>;
export type AddRouteStopEventMutationResult = Apollo.MutationResult<AddRouteStopEventMutation>;
export type AddRouteStopEventMutationOptions = Apollo.BaseMutationOptions<AddRouteStopEventMutation, AddRouteStopEventMutationVariables>;
export const CreateDriverLocationDocument = gql`
    mutation createDriverLocation($name: String!) {
  createDriverLocation(name: $name) {
    id
    name
    inserted_at
    updated_at
  }
}
    `;
export type CreateDriverLocationMutationFn = Apollo.MutationFunction<CreateDriverLocationMutation, CreateDriverLocationMutationVariables>;

/**
 * __useCreateDriverLocationMutation__
 *
 * To run a mutation, you first call `useCreateDriverLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverLocationMutation, { data, loading, error }] = useCreateDriverLocationMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateDriverLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDriverLocationMutation, CreateDriverLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateDriverLocationMutation, CreateDriverLocationMutationVariables>(CreateDriverLocationDocument, options);
      }
export type CreateDriverLocationMutationHookResult = ReturnType<typeof useCreateDriverLocationMutation>;
export type CreateDriverLocationMutationResult = Apollo.MutationResult<CreateDriverLocationMutation>;
export type CreateDriverLocationMutationOptions = Apollo.BaseMutationOptions<CreateDriverLocationMutation, CreateDriverLocationMutationVariables>;
export const CreateMaterialDocument = gql`
    mutation createMaterial($material: MaterialInput!) {
  createMaterial(material: $material) {
    id
    name
  }
}
    `;
export type CreateMaterialMutationFn = Apollo.MutationFunction<CreateMaterialMutation, CreateMaterialMutationVariables>;

/**
 * __useCreateMaterialMutation__
 *
 * To run a mutation, you first call `useCreateMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaterialMutation, { data, loading, error }] = useCreateMaterialMutation({
 *   variables: {
 *      material: // value for 'material'
 *   },
 * });
 */
export function useCreateMaterialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMaterialMutation, CreateMaterialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMaterialMutation, CreateMaterialMutationVariables>(CreateMaterialDocument, options);
      }
export type CreateMaterialMutationHookResult = ReturnType<typeof useCreateMaterialMutation>;
export type CreateMaterialMutationResult = Apollo.MutationResult<CreateMaterialMutation>;
export type CreateMaterialMutationOptions = Apollo.BaseMutationOptions<CreateMaterialMutation, CreateMaterialMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($username: String!, $firstName: String!, $lastName: String!, $role: UserRole!, $password: String!, $defaultDriverLocationId: ID, $guidedDriverLocationIds: [ID!]!) {
  createUser(
    username: $username
    firstName: $firstName
    lastName: $lastName
    role: $role
    password: $password
    default_driver_location_id: $defaultDriverLocationId
    guidedDriverLocationIds: $guidedDriverLocationIds
  ) {
    id
    first_name
    last_name
    role
    username
    default_driver_location_id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      role: // value for 'role'
 *      password: // value for 'password'
 *      defaultDriverLocationId: // value for 'defaultDriverLocationId'
 *      guidedDriverLocationIds: // value for 'guidedDriverLocationIds'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteRouteDocument = gql`
    mutation deleteRoute($routeId: ID!) {
  deleteRoute(id: $routeId) {
    error
  }
}
    `;
export type DeleteRouteMutationFn = Apollo.MutationFunction<DeleteRouteMutation, DeleteRouteMutationVariables>;

/**
 * __useDeleteRouteMutation__
 *
 * To run a mutation, you first call `useDeleteRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRouteMutation, { data, loading, error }] = useDeleteRouteMutation({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useDeleteRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRouteMutation, DeleteRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteRouteMutation, DeleteRouteMutationVariables>(DeleteRouteDocument, options);
      }
export type DeleteRouteMutationHookResult = ReturnType<typeof useDeleteRouteMutation>;
export type DeleteRouteMutationResult = Apollo.MutationResult<DeleteRouteMutation>;
export type DeleteRouteMutationOptions = Apollo.BaseMutationOptions<DeleteRouteMutation, DeleteRouteMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($username: String!) {
  deleteUser(username: $username)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ImportRouteFromRonaDocument = gql`
    mutation importRouteFromRona($route: RonaRouteInput!, $driverLocationId: ID!, $materialId: ID!) {
  importRouteFromRona(
    route: $route
    driverLocationId: $driverLocationId
    materialId: $materialId
  ) {
    id
  }
}
    `;
export type ImportRouteFromRonaMutationFn = Apollo.MutationFunction<ImportRouteFromRonaMutation, ImportRouteFromRonaMutationVariables>;

/**
 * __useImportRouteFromRonaMutation__
 *
 * To run a mutation, you first call `useImportRouteFromRonaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportRouteFromRonaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importRouteFromRonaMutation, { data, loading, error }] = useImportRouteFromRonaMutation({
 *   variables: {
 *      route: // value for 'route'
 *      driverLocationId: // value for 'driverLocationId'
 *      materialId: // value for 'materialId'
 *   },
 * });
 */
export function useImportRouteFromRonaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportRouteFromRonaMutation, ImportRouteFromRonaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ImportRouteFromRonaMutation, ImportRouteFromRonaMutationVariables>(ImportRouteFromRonaDocument, options);
      }
export type ImportRouteFromRonaMutationHookResult = ReturnType<typeof useImportRouteFromRonaMutation>;
export type ImportRouteFromRonaMutationResult = Apollo.MutationResult<ImportRouteFromRonaMutation>;
export type ImportRouteFromRonaMutationOptions = Apollo.BaseMutationOptions<ImportRouteFromRonaMutation, ImportRouteFromRonaMutationVariables>;
export const LoginDocument = gql`
    mutation login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    user {
      id
      first_name
      last_name
      usertype
      username
      inserted_at
      updated_at
      deleted_at
      role
    }
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RemoveDriverLocationDocument = gql`
    mutation removeDriverLocation($id: ID!) {
  removeDriverLocation(id: $id) {
    error
  }
}
    `;
export type RemoveDriverLocationMutationFn = Apollo.MutationFunction<RemoveDriverLocationMutation, RemoveDriverLocationMutationVariables>;

/**
 * __useRemoveDriverLocationMutation__
 *
 * To run a mutation, you first call `useRemoveDriverLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDriverLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDriverLocationMutation, { data, loading, error }] = useRemoveDriverLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDriverLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveDriverLocationMutation, RemoveDriverLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveDriverLocationMutation, RemoveDriverLocationMutationVariables>(RemoveDriverLocationDocument, options);
      }
export type RemoveDriverLocationMutationHookResult = ReturnType<typeof useRemoveDriverLocationMutation>;
export type RemoveDriverLocationMutationResult = Apollo.MutationResult<RemoveDriverLocationMutation>;
export type RemoveDriverLocationMutationOptions = Apollo.BaseMutationOptions<RemoveDriverLocationMutation, RemoveDriverLocationMutationVariables>;
export const RemoveMaterialDocument = gql`
    mutation removeMaterial($materialId: ID!) {
  removeMaterial(materialId: $materialId) {
    error
  }
}
    `;
export type RemoveMaterialMutationFn = Apollo.MutationFunction<RemoveMaterialMutation, RemoveMaterialMutationVariables>;

/**
 * __useRemoveMaterialMutation__
 *
 * To run a mutation, you first call `useRemoveMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMaterialMutation, { data, loading, error }] = useRemoveMaterialMutation({
 *   variables: {
 *      materialId: // value for 'materialId'
 *   },
 * });
 */
export function useRemoveMaterialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMaterialMutation, RemoveMaterialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveMaterialMutation, RemoveMaterialMutationVariables>(RemoveMaterialDocument, options);
      }
export type RemoveMaterialMutationHookResult = ReturnType<typeof useRemoveMaterialMutation>;
export type RemoveMaterialMutationResult = Apollo.MutationResult<RemoveMaterialMutation>;
export type RemoveMaterialMutationOptions = Apollo.BaseMutationOptions<RemoveMaterialMutation, RemoveMaterialMutationVariables>;
export const RemoveRouteStopEventInfoDocument = gql`
    mutation removeRouteStopEventInfo($routeStopEventId: ID!) {
  removeRouteStopEventInfo(routeStopEventId: $routeStopEventId) {
    error
  }
}
    `;
export type RemoveRouteStopEventInfoMutationFn = Apollo.MutationFunction<RemoveRouteStopEventInfoMutation, RemoveRouteStopEventInfoMutationVariables>;

/**
 * __useRemoveRouteStopEventInfoMutation__
 *
 * To run a mutation, you first call `useRemoveRouteStopEventInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRouteStopEventInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRouteStopEventInfoMutation, { data, loading, error }] = useRemoveRouteStopEventInfoMutation({
 *   variables: {
 *      routeStopEventId: // value for 'routeStopEventId'
 *   },
 * });
 */
export function useRemoveRouteStopEventInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveRouteStopEventInfoMutation, RemoveRouteStopEventInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveRouteStopEventInfoMutation, RemoveRouteStopEventInfoMutationVariables>(RemoveRouteStopEventInfoDocument, options);
      }
export type RemoveRouteStopEventInfoMutationHookResult = ReturnType<typeof useRemoveRouteStopEventInfoMutation>;
export type RemoveRouteStopEventInfoMutationResult = Apollo.MutationResult<RemoveRouteStopEventInfoMutation>;
export type RemoveRouteStopEventInfoMutationOptions = Apollo.BaseMutationOptions<RemoveRouteStopEventInfoMutation, RemoveRouteStopEventInfoMutationVariables>;
export const UpdateDriverLocationDocument = gql`
    mutation updateDriverLocation($id: ID!, $name: String!) {
  updateDriverLocation(id: $id, name: $name) {
    id
    name
    inserted_at
    updated_at
  }
}
    `;
export type UpdateDriverLocationMutationFn = Apollo.MutationFunction<UpdateDriverLocationMutation, UpdateDriverLocationMutationVariables>;

/**
 * __useUpdateDriverLocationMutation__
 *
 * To run a mutation, you first call `useUpdateDriverLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverLocationMutation, { data, loading, error }] = useUpdateDriverLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateDriverLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDriverLocationMutation, UpdateDriverLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateDriverLocationMutation, UpdateDriverLocationMutationVariables>(UpdateDriverLocationDocument, options);
      }
export type UpdateDriverLocationMutationHookResult = ReturnType<typeof useUpdateDriverLocationMutation>;
export type UpdateDriverLocationMutationResult = Apollo.MutationResult<UpdateDriverLocationMutation>;
export type UpdateDriverLocationMutationOptions = Apollo.BaseMutationOptions<UpdateDriverLocationMutation, UpdateDriverLocationMutationVariables>;
export const UpdateMaterialDocument = gql`
    mutation updateMaterial($material: MaterialInput!, $materialId: ID!) {
  updateMaterial(material: $material, materialId: $materialId) {
    id
    name
  }
}
    `;
export type UpdateMaterialMutationFn = Apollo.MutationFunction<UpdateMaterialMutation, UpdateMaterialMutationVariables>;

/**
 * __useUpdateMaterialMutation__
 *
 * To run a mutation, you first call `useUpdateMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaterialMutation, { data, loading, error }] = useUpdateMaterialMutation({
 *   variables: {
 *      material: // value for 'material'
 *      materialId: // value for 'materialId'
 *   },
 * });
 */
export function useUpdateMaterialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMaterialMutation, UpdateMaterialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateMaterialMutation, UpdateMaterialMutationVariables>(UpdateMaterialDocument, options);
      }
export type UpdateMaterialMutationHookResult = ReturnType<typeof useUpdateMaterialMutation>;
export type UpdateMaterialMutationResult = Apollo.MutationResult<UpdateMaterialMutation>;
export type UpdateMaterialMutationOptions = Apollo.BaseMutationOptions<UpdateMaterialMutation, UpdateMaterialMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($username: String!, $password: String!) {
  updatePassword(username: $username, password: $password) {
    id
    first_name
    last_name
    role
    username
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdateRouteLocationDocument = gql`
    mutation updateRouteLocation($locationId: ID!, $routeId: ID!) {
  updateRouteLocation(locationId: $locationId, routeId: $routeId) {
    error
  }
}
    `;
export type UpdateRouteLocationMutationFn = Apollo.MutationFunction<UpdateRouteLocationMutation, UpdateRouteLocationMutationVariables>;

/**
 * __useUpdateRouteLocationMutation__
 *
 * To run a mutation, you first call `useUpdateRouteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRouteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRouteLocationMutation, { data, loading, error }] = useUpdateRouteLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useUpdateRouteLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRouteLocationMutation, UpdateRouteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateRouteLocationMutation, UpdateRouteLocationMutationVariables>(UpdateRouteLocationDocument, options);
      }
export type UpdateRouteLocationMutationHookResult = ReturnType<typeof useUpdateRouteLocationMutation>;
export type UpdateRouteLocationMutationResult = Apollo.MutationResult<UpdateRouteLocationMutation>;
export type UpdateRouteLocationMutationOptions = Apollo.BaseMutationOptions<UpdateRouteLocationMutation, UpdateRouteLocationMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($username: String!, $firstName: String!, $lastName: String!, $role: UserRole!, $defaultDriverLocationId: ID, $guidedDriverLocationIds: [ID!]!) {
  updateUser(
    username: $username
    firstName: $firstName
    lastName: $lastName
    role: $role
    default_driver_location_id: $defaultDriverLocationId
    guidedDriverLocationIds: $guidedDriverLocationIds
  ) {
    id
    first_name
    last_name
    role
    username
    default_driver_location_id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      role: // value for 'role'
 *      defaultDriverLocationId: // value for 'defaultDriverLocationId'
 *      guidedDriverLocationIds: // value for 'guidedDriverLocationIds'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadRoutesDocument = gql`
    mutation uploadRoutes($routes: [RouteInput!]!, $driverLocationId: ID!, $materialId: ID!) {
  uploadRoutes(
    routes: $routes
    driverLocationId: $driverLocationId
    materialId: $materialId
  ) {
    id
  }
}
    `;
export type UploadRoutesMutationFn = Apollo.MutationFunction<UploadRoutesMutation, UploadRoutesMutationVariables>;

/**
 * __useUploadRoutesMutation__
 *
 * To run a mutation, you first call `useUploadRoutesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadRoutesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadRoutesMutation, { data, loading, error }] = useUploadRoutesMutation({
 *   variables: {
 *      routes: // value for 'routes'
 *      driverLocationId: // value for 'driverLocationId'
 *      materialId: // value for 'materialId'
 *   },
 * });
 */
export function useUploadRoutesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadRoutesMutation, UploadRoutesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UploadRoutesMutation, UploadRoutesMutationVariables>(UploadRoutesDocument, options);
      }
export type UploadRoutesMutationHookResult = ReturnType<typeof useUploadRoutesMutation>;
export type UploadRoutesMutationResult = Apollo.MutationResult<UploadRoutesMutation>;
export type UploadRoutesMutationOptions = Apollo.BaseMutationOptions<UploadRoutesMutation, UploadRoutesMutationVariables>;
export const GetCompletedRoutesDocument = gql`
    query getCompletedRoutes($page: Int!, $take: Int!, $locationId: ID!, $filters: CompletedRouteFilter) {
  getCompletedRoutes(
    page: $page
    take: $take
    locationId: $locationId
    filters: $filters
  ) {
    items {
      id
      routeId
      tourNumber
      tourName
      progress
      startDate
      endDate
      driverName
      material {
        id
        name
      }
    }
    page
    take
    total
  }
}
    `;

/**
 * __useGetCompletedRoutesQuery__
 *
 * To run a query within a React component, call `useGetCompletedRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedRoutesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      locationId: // value for 'locationId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCompletedRoutesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCompletedRoutesQuery, GetCompletedRoutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCompletedRoutesQuery, GetCompletedRoutesQueryVariables>(GetCompletedRoutesDocument, options);
      }
export function useGetCompletedRoutesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompletedRoutesQuery, GetCompletedRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCompletedRoutesQuery, GetCompletedRoutesQueryVariables>(GetCompletedRoutesDocument, options);
        }
export type GetCompletedRoutesQueryHookResult = ReturnType<typeof useGetCompletedRoutesQuery>;
export type GetCompletedRoutesLazyQueryHookResult = ReturnType<typeof useGetCompletedRoutesLazyQuery>;
export type GetCompletedRoutesQueryResult = Apollo.QueryResult<GetCompletedRoutesQuery, GetCompletedRoutesQueryVariables>;
export const DefaultThemeDocument = gql`
    query defaultTheme {
  defaultTheme {
    id
    name
    portal {
      primary_main
      primary_light
      primary_dark
      primary_contrast_text
      secondary_main
      secondary_light
      secondary_dark
      secondary_contrast_text
      error
      background
      text
    }
    logo_url
  }
}
    `;

/**
 * __useDefaultThemeQuery__
 *
 * To run a query within a React component, call `useDefaultThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultThemeQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultThemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DefaultThemeQuery, DefaultThemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DefaultThemeQuery, DefaultThemeQueryVariables>(DefaultThemeDocument, options);
      }
export function useDefaultThemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefaultThemeQuery, DefaultThemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DefaultThemeQuery, DefaultThemeQueryVariables>(DefaultThemeDocument, options);
        }
export type DefaultThemeQueryHookResult = ReturnType<typeof useDefaultThemeQuery>;
export type DefaultThemeLazyQueryHookResult = ReturnType<typeof useDefaultThemeLazyQuery>;
export type DefaultThemeQueryResult = Apollo.QueryResult<DefaultThemeQuery, DefaultThemeQueryVariables>;
export const GetDriverLocationsDocument = gql`
    query getDriverLocations {
  driverLocations {
    id
    name
  }
}
    `;

/**
 * __useGetDriverLocationsQuery__
 *
 * To run a query within a React component, call `useGetDriverLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDriverLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDriverLocationsQuery, GetDriverLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDriverLocationsQuery, GetDriverLocationsQueryVariables>(GetDriverLocationsDocument, options);
      }
export function useGetDriverLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDriverLocationsQuery, GetDriverLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDriverLocationsQuery, GetDriverLocationsQueryVariables>(GetDriverLocationsDocument, options);
        }
export type GetDriverLocationsQueryHookResult = ReturnType<typeof useGetDriverLocationsQuery>;
export type GetDriverLocationsLazyQueryHookResult = ReturnType<typeof useGetDriverLocationsLazyQuery>;
export type GetDriverLocationsQueryResult = Apollo.QueryResult<GetDriverLocationsQuery, GetDriverLocationsQueryVariables>;
export const GetMaterialWithIdDocument = gql`
    query getMaterialWithId($getMaterialWithIdId: ID!) {
  getMaterialWithID(id: $getMaterialWithIdId) {
    id
    name
  }
}
    `;

/**
 * __useGetMaterialWithIdQuery__
 *
 * To run a query within a React component, call `useGetMaterialWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaterialWithIdQuery({
 *   variables: {
 *      getMaterialWithIdId: // value for 'getMaterialWithIdId'
 *   },
 * });
 */
export function useGetMaterialWithIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetMaterialWithIdQuery, GetMaterialWithIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMaterialWithIdQuery, GetMaterialWithIdQueryVariables>(GetMaterialWithIdDocument, options);
      }
export function useGetMaterialWithIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMaterialWithIdQuery, GetMaterialWithIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMaterialWithIdQuery, GetMaterialWithIdQueryVariables>(GetMaterialWithIdDocument, options);
        }
export type GetMaterialWithIdQueryHookResult = ReturnType<typeof useGetMaterialWithIdQuery>;
export type GetMaterialWithIdLazyQueryHookResult = ReturnType<typeof useGetMaterialWithIdLazyQuery>;
export type GetMaterialWithIdQueryResult = Apollo.QueryResult<GetMaterialWithIdQuery, GetMaterialWithIdQueryVariables>;
export const GetMaterialsDocument = gql`
    query getMaterials {
  getMaterials {
    id
    name
  }
}
    `;

/**
 * __useGetMaterialsQuery__
 *
 * To run a query within a React component, call `useGetMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaterialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaterialsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMaterialsQuery, GetMaterialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMaterialsQuery, GetMaterialsQueryVariables>(GetMaterialsDocument, options);
      }
export function useGetMaterialsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMaterialsQuery, GetMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMaterialsQuery, GetMaterialsQueryVariables>(GetMaterialsDocument, options);
        }
export type GetMaterialsQueryHookResult = ReturnType<typeof useGetMaterialsQuery>;
export type GetMaterialsLazyQueryHookResult = ReturnType<typeof useGetMaterialsLazyQuery>;
export type GetMaterialsQueryResult = Apollo.QueryResult<GetMaterialsQuery, GetMaterialsQueryVariables>;
export const GetMeDocument = gql`
    query getMe {
  getMe {
    id
    first_name
    last_name
    username
    usertype
    role
    password
    default_driver_location_id
    driver_locations {
      id
      name
    }
    theme {
      id
      name
      portal {
        primary_main
        primary_light
        primary_dark
        primary_contrast_text
        secondary_main
        secondary_light
        secondary_dark
        secondary_contrast_text
        error
        background
        text
      }
      logo_url
    }
    groupHasRonaParticipation
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetRoutesForLocationAndMaterialDocument = gql`
    query getRoutesForLocationAndMaterial($locationId: ID!, $materialId: ID!) {
  getRoutesForLocationAndMaterial(
    locationId: $locationId
    materialId: $materialId
  ) {
    id
    name
    tour_number
    filename
    inserted_at
    material {
      id
      name
      inserted_at
    }
  }
}
    `;

/**
 * __useGetRoutesForLocationAndMaterialQuery__
 *
 * To run a query within a React component, call `useGetRoutesForLocationAndMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutesForLocationAndMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutesForLocationAndMaterialQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      materialId: // value for 'materialId'
 *   },
 * });
 */
export function useGetRoutesForLocationAndMaterialQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRoutesForLocationAndMaterialQuery, GetRoutesForLocationAndMaterialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRoutesForLocationAndMaterialQuery, GetRoutesForLocationAndMaterialQueryVariables>(GetRoutesForLocationAndMaterialDocument, options);
      }
export function useGetRoutesForLocationAndMaterialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoutesForLocationAndMaterialQuery, GetRoutesForLocationAndMaterialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRoutesForLocationAndMaterialQuery, GetRoutesForLocationAndMaterialQueryVariables>(GetRoutesForLocationAndMaterialDocument, options);
        }
export type GetRoutesForLocationAndMaterialQueryHookResult = ReturnType<typeof useGetRoutesForLocationAndMaterialQuery>;
export type GetRoutesForLocationAndMaterialLazyQueryHookResult = ReturnType<typeof useGetRoutesForLocationAndMaterialLazyQuery>;
export type GetRoutesForLocationAndMaterialQueryResult = Apollo.QueryResult<GetRoutesForLocationAndMaterialQuery, GetRoutesForLocationAndMaterialQueryVariables>;
export const GetRouteWithIdDocument = gql`
    query getRouteWithId($id: ID!) {
  getRouteWithId(id: $id) {
    id
    name
    tour_number
    filename
    inserted_at
    url
    location_id
    stops {
      id
      sequence_number
      latitude
      longitude
      address
      text
      distance
      heading
      events {
        id
        info
        event_type
        images {
          id
          url
          inserted_at
        }
      }
    }
    material {
      id
      name
      inserted_at
    }
  }
}
    `;

/**
 * __useGetRouteWithIdQuery__
 *
 * To run a query within a React component, call `useGetRouteWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteWithIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRouteWithIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRouteWithIdQuery, GetRouteWithIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRouteWithIdQuery, GetRouteWithIdQueryVariables>(GetRouteWithIdDocument, options);
      }
export function useGetRouteWithIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRouteWithIdQuery, GetRouteWithIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRouteWithIdQuery, GetRouteWithIdQueryVariables>(GetRouteWithIdDocument, options);
        }
export type GetRouteWithIdQueryHookResult = ReturnType<typeof useGetRouteWithIdQuery>;
export type GetRouteWithIdLazyQueryHookResult = ReturnType<typeof useGetRouteWithIdLazyQuery>;
export type GetRouteWithIdQueryResult = Apollo.QueryResult<GetRouteWithIdQuery, GetRouteWithIdQueryVariables>;
export const GetRoutesFromRonaDocument = gql`
    query getRoutesFromRona {
  getRoutesFromRona {
    filename
    imported
  }
}
    `;

/**
 * __useGetRoutesFromRonaQuery__
 *
 * To run a query within a React component, call `useGetRoutesFromRonaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutesFromRonaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutesFromRonaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoutesFromRonaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoutesFromRonaQuery, GetRoutesFromRonaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRoutesFromRonaQuery, GetRoutesFromRonaQueryVariables>(GetRoutesFromRonaDocument, options);
      }
export function useGetRoutesFromRonaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoutesFromRonaQuery, GetRoutesFromRonaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRoutesFromRonaQuery, GetRoutesFromRonaQueryVariables>(GetRoutesFromRonaDocument, options);
        }
export type GetRoutesFromRonaQueryHookResult = ReturnType<typeof useGetRoutesFromRonaQuery>;
export type GetRoutesFromRonaLazyQueryHookResult = ReturnType<typeof useGetRoutesFromRonaLazyQuery>;
export type GetRoutesFromRonaQueryResult = Apollo.QueryResult<GetRoutesFromRonaQuery, GetRoutesFromRonaQueryVariables>;
export const GetUserDocument = gql`
    query getUser($username: String!) {
  getUser(username: $username) {
    id
    first_name
    last_name
    role
    username
    driver_locations {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers {
  getUsers {
    id
    first_name
    last_name
    role
    username
    default_driver_location_id
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;