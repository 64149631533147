import i18next from "i18next";
import lodash from "lodash";
import store from "store";
import jwt from "jsonwebtoken";
import { IRouteItem, ROUTES } from "../router/router";

export enum STORE_KEYS {
  TOKEN = "jwt_token",
}

export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  GUIDED_DRIVER = "GUIDED_DRIVER",
  GUIDANCE_ADMIN = "GUIDANCE_ADMIN",
}

export class UserService {
  public static login(token: string): void {
    store.set(STORE_KEYS.TOKEN, token);
  }

  public static isUserLoggedIn(): boolean {
    return !lodash.isNil(store.get(STORE_KEYS.TOKEN));
  }

  // eslint-disable-next-line
  public static logout(client: any): void {
    client.clearStore();
    store.remove(STORE_KEYS.TOKEN);
  }

  public static getRole(): UserRole {
    const payload = jwt.decode(store.get(STORE_KEYS.TOKEN));
    return lodash.get(payload, "role", "");
  }

  public static getAvailableRoles(): string[] {
    return Object.values(UserRole);
  }

  public static getAvailablePaths(): IRouteItem[] {
    const routes = Object.values(ROUTES.PORTAL.ROUTES);
    const allRoutes: IRouteItem[] = [];
    routes.forEach((route) => {
      if (route.ROLES.includes(UserService.getRole())) {
        allRoutes.push(route);
        // only allowed 1 deep nesting
        (route.SUBROUTES ?? []).forEach((subRoute) => {
          if (subRoute.ROLES.includes(UserService.getRole())) {
            allRoutes.push(subRoute);
          }
        });
      }
    });
    return allRoutes;
  }

  public static getLanguage(): string {
    return i18next.language.split("_")[0];
  }
}
