import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { Theme, makeStyles, Grid, TextField, IconButton, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Edit, Done, Clear } from "@material-ui/icons";
import { toast } from "react-toastify";
import { useUserManagementContext } from "../../../context/user-management-context";
import { useUpdatePasswordMutation } from "../../../api/nachfahrbarkeit-api/generated";

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    padding: 3,
  },
}));

interface IPasswordChangerProps {
  username: string;
  value: string;
  setValue: (value: string) => void;
}

export const PasswordChanger: FunctionComponent<IPasswordChangerProps> = (props) => {
  const { value, setValue, username } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { selectedUser, isInCreateMode } = useUserManagementContext();

  useEffect(() => {
    setIsEditing(false);
  }, [selectedUser, setIsEditing]);

  const onMutationComplete = () => {
    toast.success(t("user_management.data.password_changed"));
    onCancel();
  };

  const [updatePasswordMutation, { loading }] = useUpdatePasswordMutation({ onCompleted: onMutationComplete });

  const onCancel = () => {
    setIsEditing(false);
    setValue("");
  };

  const onSave = () => {
    updatePasswordMutation({
      variables: {
        username,
        password: value,
      },
    });
  };

  return (
    <Fragment>
      <Grid container alignItems="center" alignContent="center" justify="center" spacing={1}>
        <Grid item xs>
          <TextField
            id="outlined-search"
            fullWidth
            disabled={selectedUser && !isEditing}
            label={t("user_management.data.password")}
            variant="outlined"
            value={value}
            type="password"
            onChange={(ev) => {
              setValue(ev.target.value);
            }}
          />
        </Grid>
        {!isInCreateMode && !isEditing && (
          <Fragment>
            <Grid item>
              <IconButton size="small" color="primary" onClick={() => setIsEditing(true)}>
                <Edit />
              </IconButton>
            </Grid>
          </Fragment>
        )}
        {!isInCreateMode && isEditing && (
          <Fragment>
            <Grid item>
              {!loading && (
                <IconButton size="small" color="primary" onClick={onSave} disabled={!value || value.length <= 6}>
                  <Done />
                </IconButton>
              )}
              {loading && <CircularProgress color="primary" size={24} className={classes.loader} />}
            </Grid>
            <Grid item>
              <IconButton size="small" color="primary" onClick={onCancel} disabled={loading}>
                <Clear />
              </IconButton>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
};
