import { Chip, CircularProgress, Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useGetDriverLocationsQuery } from "../../../../api/nachfahrbarkeit-api/generated";
import { sortASC } from "../../../../utils/sort";
import { ILocationCollectionItem } from "./location-assignment-dialog";

interface IGuidedDriverLocationSelectionProps {
  selectedItems: ILocationCollectionItem[];
  onClick: (item: ILocationCollectionItem) => void;
  searchString: string;
}

export const GuidedDriverLocationSelection: FunctionComponent<IGuidedDriverLocationSelectionProps> = (props) => {
  const { onClick, selectedItems, searchString } = props;
  const { data, loading } = useGetDriverLocationsQuery();

  const locations =
    data?.driverLocations
      .sort((a, b) => sortASC(a.name, b.name))
      .filter((driverLocation) => driverLocation.name.toLowerCase().includes(searchString.toLowerCase())) || [];

  return (
    <Grid container spacing={1}>
      {loading && (
        <Grid item container justify="center">
          <CircularProgress color="primary" />
        </Grid>
      )}
      {!loading &&
        locations.map((driverLocation) => (
          <Grid item key={driverLocation.id}>
            <Chip
              label={driverLocation.name}
              color={
                selectedItems.find(
                  (item) => item.id === driverLocation.id.toString() && item.name === driverLocation.name,
                )
                  ? "primary"
                  : "default"
              }
              onClick={() => {
                const mappedLocation = {
                  id: driverLocation.id.toString(),
                  name: driverLocation.name,
                };
                onClick(mappedLocation);
              }}
            />
          </Grid>
        ))}
    </Grid>
  );
};
