import { Button, Card, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateMaterialMutation, useUpdateMaterialMutation } from "../../../api/nachfahrbarkeit-api/generated";
import { useMaterialManagementContext } from "../../../context/material-management-context";
import { useRefetchContext } from "../../../context/refetch-context";

const MIN_NAME_LENGTH = 2;

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  buttonLabel: {
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: 8,
    paddingTop: 4,
  },
  actionButton: {
    width: 240,
  },
  card: {
    padding: theme.spacing(2),
  },
  material: {
    flexGrow: 1,
  },
}));

interface IMaterialDataProps {}

export const MaterialData: FunctionComponent<IMaterialDataProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setNeedToRefetch } = useRefetchContext();
  const { selectedMaterial, setSelectedMaterial } = useMaterialManagementContext();
  const [name, setName] = useState<string>("");
  const [id, setId] = useState<string>("");

  const [createMaterial] = useCreateMaterialMutation({
    onCompleted: (data) => {
      if (!data?.createMaterial) {
        toast.error(t("material_management.create.error"));
        return;
      }
      toast.success(t("material_management.create.success"));
      setSelectedMaterial(data.createMaterial);
      setNeedToRefetch(true);
    },
    onError: () => toast.error(t("material_management.create.error")),
  });

  const [updateMaterial] = useUpdateMaterialMutation({
    onCompleted: (data) => {
      if (!data?.updateMaterial) {
        toast.error(t("material_management.update.error"));
        return;
      }
      toast.success(t("material_management.update.success"));
      setNeedToRefetch(true);
    },
    onError: () => toast.error(t("material_management.update.error")),
  });

  useEffect(() => {
    if (selectedMaterial) {
      setName(selectedMaterial.name);
      setId(selectedMaterial.id);
    } else {
      setName("");
      setId("");
    }
  }, [selectedMaterial]);

  const onClickCreateMaterial = async () => {
    const validationError = validateFields();
    if (validationError) {
      toast.error(t(`material_management.material.data.${validationError}`, { length: MIN_NAME_LENGTH }));
      return;
    }
    await createMaterial({
      variables: {
        material: {
          name,
        },
      },
    });
  };

  const validateFields = (): string => {
    if (!name || name === "") {
      return "name_required";
    } else if (name.length < 2) {
      return "name_too_short";
    }
    return "";
  };

  const onSaveMaterial = async () => {
    const validationError = validateFields();
    if (validationError) {
      toast.error(t(`material_management.material.data.${validationError}`, { length: MIN_NAME_LENGTH }));
      return;
    }

    await updateMaterial({
      variables: {
        materialId: id,
        material: {
          name,
        },
      },
    });
  };

  return (
    <Card className={classes.card}>
      <Grid container direction="column" spacing={3}>
        <Grid container item direction="row" spacing={3}>
          <Grid item className={classes.material}>
            <Typography className={classes.title}>
              {!selectedMaterial ? t("material_management.create.info") : t("material_management.update.info")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("material_management.material.data.name")}
            type="search"
            variant="outlined"
            value={name}
            fullWidth
            onChange={(event) => {
              setName(event.target.value);
            }}
            required
          />
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" spacing={2}>
          <Grid item>
            <Button
              className={classes.actionButton}
              type="button"
              variant="contained"
              onClick={() => setSelectedMaterial(undefined)}
              fullWidth
            >
              {t("material_management.action.cancel")}
            </Button>
          </Grid>
          <Grid item>
            {selectedMaterial ? (
              <Button
                className={classes.actionButton}
                type="button"
                variant="contained"
                onClick={onSaveMaterial}
                fullWidth
                color="primary"
              >
                {t("material_management.action.save")}
              </Button>
            ) : (
              <Button
                className={classes.actionButton}
                type="button"
                variant="contained"
                onClick={onClickCreateMaterial}
                fullWidth
                color="primary"
              >
                {t("material_management.action.create")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
