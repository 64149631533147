import React, { FunctionComponent } from "react";

interface ITabPanelProps {
  index: number;
  value: number;
}

export const TabPanel: FunctionComponent<ITabPanelProps> = (props) => {
  const { index, value } = props;
  return index === value ? <>{props.children}</> : null;
};
