import React, { FunctionComponent } from "react";
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, makeStyles, Theme } from "@material-ui/core";

interface ISimpleSelectPair {
  title: string;
  value: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 4,
    paddingRight: 4,
  },
}));

interface ISimpleSelectProps {
  onChange: (value: string) => void;
  value: string;
  options: ISimpleSelectPair[];
  name?: string;
}

export const SimpleSelect: FunctionComponent<ISimpleSelectProps> = (props) => {
  const { onChange, options, name, value } = props;
  const classes = useStyles();

  const handleChangeEvent = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string);
  };

  // avoid not found
  const valueExistsInOptions = !!options.find((option) => option.value === value);
  if (!valueExistsInOptions) {
    onChange("");
  }

  return (
    <FormControl fullWidth variant="outlined">
      {name && (
        <InputLabel id="label" htmlFor="outlined-select" className={classes.inputLabel}>
          {name}
        </InputLabel>
      )}
      <Select
        labelId="label"
        value={value}
        onChange={handleChangeEvent}
        inputProps={{
          name,
          id: "outlined-select",
        }}
        input={<OutlinedInput name="outlined-input" id="outlined-input" />}
      >
        {options.map(({ title, value }) => (
          <MenuItem key={value} value={value}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
