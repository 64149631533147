import { useState, useContext, createContext, FC } from "react";

interface IDriverLocationContextType {
  driverLocationId: string | undefined;
  setDriverLocationId: (value: string | undefined) => void;
}

export const DriverLocationContext = createContext<IDriverLocationContextType>({} as IDriverLocationContextType);

export const DriverLocationProvider: FC = (props) => {
  const value = useDriverLocationProvider();

  return <DriverLocationContext.Provider value={value}>{props.children}</DriverLocationContext.Provider>;
};

export const useDriverLocation = (): IDriverLocationContextType => {
  return useContext(DriverLocationContext);
};

const useDriverLocationProvider = (): IDriverLocationContextType => {
  const [driverLocationId, setDriverLocationId] = useState<string | undefined>("");

  return {
    driverLocationId,
    setDriverLocationId,
  };
};
