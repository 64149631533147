import { ApolloError } from "apollo-boost";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useGetUsersLazyQuery, User } from "../api/nachfahrbarkeit-api/generated";
import { sortASC } from "../utils/sort";
import { useRefetchContext } from "./refetch-context";

interface IUserManagementContextType {
  users: User[];
  selectedUser: User | undefined;
  updateSelectedUser: (user: User | undefined) => void;
  usersLoading: boolean;
  usersError: ApolloError | undefined;
  isInCreateMode: boolean;
  toggleCreateMode: () => void;
}

interface IUserManagementProps {
  children: JSX.Element;
}

const UserManagementContext = createContext<IUserManagementContextType>({} as IUserManagementContextType);

export const UserManagementProvider = (props: IUserManagementProps): JSX.Element => {
  const value = useUserManagementProvider(props);
  return <UserManagementContext.Provider value={value}>{props.children}</UserManagementContext.Provider>;
};

export const useUserManagementContext = (): IUserManagementContextType => {
  return useContext(UserManagementContext);
};

const useUserManagementProvider = (props: IUserManagementProps): IUserManagementContextType => {
  const [usersQuery, { data, loading: usersLoading, error: usersError }] = useGetUsersLazyQuery();
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [isInCreateMode, setIsInCreateMode] = useState<boolean>(false);

  const { needToRefetch, setNeedToRefetch } = useRefetchContext();

  //initial Load
  useEffect(() => {
    usersQuery();
  }, [usersQuery]);

  //refetch
  useEffect(() => {
    if (needToRefetch) {
      usersQuery();
      setNeedToRefetch(false);
    }
  }, [needToRefetch, setNeedToRefetch, usersQuery]);

  const toggleCreateMode = () => {
    setIsInCreateMode(!isInCreateMode);
    setSelectedUser(undefined);
  };

  const updateSelectedUser = (user: User | undefined) => {
    setIsInCreateMode(false);
    setSelectedUser(selectedUser !== user ? user : undefined);
  };

  const users = data ? (data.getUsers.sort((a, b) => sortASC(a.username, b.username)) as User[]) : [];

  return { users, selectedUser, updateSelectedUser, usersLoading, usersError, isInCreateMode, toggleCreateMode };
};
