import { createContext, FC, useContext, useMemo, useState } from "react";
import {
  RonaRouteInput,
  Route,
  RouteInput,
  useImportRouteFromRonaMutation,
  useUploadRoutesMutation,
} from "../../../api/nachfahrbarkeit-api/generated";
import { useDriverLocation } from "../../../context/driver-location-filter-context";

export enum ImportRouteType {
  FileImport,
  RonaImport,
}

interface IImportRouteProps {
  children: React.ReactNode;
  routes: Pick<Route, "id" | "name" | "filename" | "tour_number" | "inserted_at">[];
}

interface IImportRoutesContext {
  files: RouteInput[];
  setFiles: (files: RouteInput[]) => void;
  selectedLocation: string | undefined;
  selectedMaterial: string | undefined;
  setSelectedMaterial: (material: string | undefined) => void;
  importType: ImportRouteType;
  setImportType: (importType: ImportRouteType) => void;
  ronaRoute: RonaRouteInput | null;
  setRonaRoute: (route: RonaRouteInput | null) => void;
  disableImportButton: boolean;
  upload: () => Promise<boolean>;
  duplicateRoutes: string[];
}

export const ImportRoutesContext = createContext<IImportRoutesContext>({} as IImportRoutesContext);

export const useImportRoutesContext = (): IImportRoutesContext => {
  return useContext(ImportRoutesContext);
};

const useImportRoutesProvider = (props: IImportRouteProps): IImportRoutesContext => {
  const [files, setFiles] = useState<RouteInput[]>([]);
  const { driverLocationId: selectedLocation } = useDriverLocation();
  const [selectedMaterial, setSelectedMaterial] = useState<string | undefined>(undefined);
  const [importType, setImportType] = useState<ImportRouteType>(ImportRouteType.FileImport);
  const [ronaRoute, setRonaRoute] = useState<RonaRouteInput | null>(null);

  const [uploadRoutes] = useUploadRoutesMutation();
  const [importRouteFromRona] = useImportRouteFromRonaMutation();

  const disableImportButton = useMemo(() => {
    if (!selectedLocation || !selectedMaterial) {
      return true;
    }
    if (importType === ImportRouteType.FileImport && (files.length === 0 || files.some((file) => !file.tourName))) {
      return true;
    }
    if (
      importType === ImportRouteType.RonaImport &&
      (!ronaRoute || !ronaRoute.tourName || isNaN(ronaRoute.tourNumber))
    ) {
      return true;
    }
    return false;
  }, [files, selectedLocation, selectedMaterial, importType, ronaRoute]);

  const upload = async (): Promise<boolean> => {
    const result = importType === ImportRouteType.FileImport ? uploadFiles() : importFromRona();

    return result;
  };

  const uploadFiles = async (): Promise<boolean> => {
    const result = await uploadRoutes({
      variables: {
        routes: files,
        driverLocationId: Number(selectedLocation).toString(),
        materialId: Number(selectedMaterial).toString(),
      },
    });
    if (result.data?.uploadRoutes) {
      return true;
    } else {
      return false;
    }
  };

  const importFromRona = async (): Promise<boolean> => {
    if (ronaRoute) {
      const result = await importRouteFromRona({
        variables: {
          route: ronaRoute,
          driverLocationId: Number(selectedLocation).toString(),
          materialId: Number(selectedMaterial).toString(),
        },
      });
      if (result.data?.importRouteFromRona) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const duplicateRoutes = useMemo(() => {
    const routes =
      importType === ImportRouteType.FileImport
        ? props.routes.filter((r) => files.find((f) => f.tourNumber === r.tour_number))
        : props.routes.filter((r) => r.tour_number === ronaRoute?.tourNumber);

    return routes.map((r) => r.name);
  }, [files, importType, props.routes, ronaRoute]);

  return {
    files,
    setFiles,
    selectedLocation,
    selectedMaterial,
    setSelectedMaterial,
    importType,
    setImportType,
    ronaRoute,
    setRonaRoute,
    disableImportButton,
    upload,
    duplicateRoutes,
  };
};

export const ImportRoutesProvider: FC<IImportRouteProps> = (props) => {
  const value = useImportRoutesProvider(props);
  return <ImportRoutesContext.Provider value={value}>{props.children}</ImportRoutesContext.Provider>;
};
