import React, { createContext, FC, useContext, useEffect, useState } from "react";
import { Route, useGetRoutesForLocationAndMaterialQuery } from "../api/nachfahrbarkeit-api/generated";
import { IRouteRow } from "../pages/route-management/partials/routes-table";
import { useRoutesTableMapper } from "../pages/route-management/partials/use-routes-table-mapper";
import { useDriverLocation } from "./driver-location-filter-context";
import { useRefetchContext } from "./refetch-context";

type PartialRoute = Pick<Route, "id" | "tour_number" | "name" | "inserted_at" | "filename" | "material">;

interface IRoutesOverviewContext {
  filters: IFilter;
  setFilters: (newFilter: IFilter) => void;
  fetchRoutes: VoidFunction;
  loading: boolean;
  error: boolean;
  routesRows: IRouteRow[];
  routes: PartialRoute[];
}

interface IFilter {
  materialId?: string;
}

const useRoutesOverviewProvider = (): IRoutesOverviewContext => {
  const [filters, setFilters] = useState<IFilter>(defaultValue.filters);
  const { mappedRoutes, setRoutesQuery } = useRoutesTableMapper();

  const { needToRefetch, setNeedToRefetch } = useRefetchContext();
  const { driverLocationId } = useDriverLocation();

  const { data, loading, error, refetch } = useGetRoutesForLocationAndMaterialQuery({
    fetchPolicy: "network-only",
    variables: {
      locationId: driverLocationId ?? "",
      materialId: filters.materialId ?? "",
    },
  });

  useEffect(() => {
    setRoutesQuery(data);
  }, [data, setRoutesQuery]);

  useEffect(() => {
    if (needToRefetch) {
      refetch();
      setNeedToRefetch(false);
    }
  }, [refetch, needToRefetch, setNeedToRefetch]);

  const handleFiltersChange = (newFilters: Partial<IFilter>) => {
    setFilters({ ...filters, ...newFilters });
  };

  return {
    filters,
    setFilters: handleFiltersChange,
    fetchRoutes: refetch,
    loading,
    error: !!error,
    routesRows: mappedRoutes,
    routes: data?.getRoutesForLocationAndMaterial || [],
  };
};

const defaultValue: IRoutesOverviewContext = {
  filters: {},
  setFilters: () => {},
  fetchRoutes: () => {},
  loading: false,
  error: false,
  routesRows: [],
  routes: [],
};

const RoutesOverviewContext = createContext<IRoutesOverviewContext>(defaultValue);

export const RoutesOverviewProvider: FC = ({ children }): JSX.Element => {
  const value = useRoutesOverviewProvider();
  return <RoutesOverviewContext.Provider value={value}>{children}</RoutesOverviewContext.Provider>;
};

export const useRoutesOverviewContext = (): IRoutesOverviewContext => {
  return useContext(RoutesOverviewContext);
};
