import { Checkbox, TextField } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import React, { VFC } from "react";

export interface IKeyLabelPair {
  key: string;
  label: string;
}

interface MultiSelectProps {
  options: IKeyLabelPair[];
  selected: string[];
  label?: string;
  onChange: (newSelected: string[]) => void;
  loading?: boolean;
  error?: boolean;
}

export const MultiSelect: VFC<MultiSelectProps> = (props) => {
  const { options, selected, onChange, loading, label } = props;
  return (
    <Autocomplete
      value={options.filter((m) => selected.includes(m.key)) || []}
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, val) => option.key === val.key}
      onChange={(_, selected) => onChange(selected.map((s) => s.key))}
      size="small"
      loading={loading}
      multiple
      fullWidth
      disableCloseOnSelect
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            color="primary"
            icon={<CheckBoxOutlineBlankIcon color="primary" fontSize="small" />}
            checkedIcon={<CheckBoxIcon color="primary" fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </>
      )}
      renderInput={(params) => {
        // enables wrapping of selected items inside <select>
        const newParams = {
          ...params,
          InputProps: {
            ...params.InputProps,
            style: { minHeight: "40px", maxHeight: "120px", height: "auto" },
          },
        };
        return <TextField {...newParams} variant="outlined" size={"small"} label={label} />;
      }}
    />
  );
};
