import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "../../../../utils/useDebounce";
import { LocationType } from "../../helper/get-options-for-role";
import { GuidedDriverLocationSelection } from "./guided-driver-location-selection";

const useStyles = makeStyles((theme: Theme) => ({
  selection: {
    padding: theme.spacing(1),
    minHeight: 200,
    maxHeight: 700,
    overflowY: "scroll",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(0.5),
  },
}));

export interface ILocationCollectionItem {
  id: string;
  name: string;
}

interface ILocationAssignmentDialogProps {
  type: LocationType | null;
  open: boolean;
  onClose: () => void;
  onSave: (locations: ILocationCollectionItem[]) => void;
  collection: ILocationCollectionItem[];
}

export const LocationAssignmentDialog: FunctionComponent<ILocationAssignmentDialogProps> = (props) => {
  const { type, onClose, onSave, collection, open } = props;
  const [selectedItems, setSelectedItems] = useState<ILocationCollectionItem[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const debouncedSearchString = useDebounce(searchString, 500) as string;
  const classes = useStyles();
  const { t } = useTranslation();

  const onSaveClicked = () => {
    onSave(selectedItems);
    onClose();
  };

  const onClick = (clickedItem: ILocationCollectionItem) => {
    switch (type) {
      case LocationType.LOCATION:
        updateSelectedMultiple(clickedItem);
        break;
    }
  };

  const updateSelectedMultiple = (clickedItem: ILocationCollectionItem) => {
    if (selectedItems.find((item) => item.id === clickedItem.id)) {
      setSelectedItems(selectedItems.filter((item) => item.id !== clickedItem.id));
    } else {
      setSelectedItems([...selectedItems, clickedItem]);
    }
  };

  const renderSelection = (): JSX.Element | undefined => {
    switch (type) {
      case LocationType.LOCATION:
        return (
          <GuidedDriverLocationSelection
            selectedItems={selectedItems}
            onClick={onClick}
            searchString={debouncedSearchString}
          />
        );
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (!!type) {
      setSearchString("");
      setSelectedItems(collection);
    }
  }, [type, setSelectedItems, collection, open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t("user_management.data.assign", {
          collection: t(`location_type.${type?.toLowerCase()}`, { count: collection.length }),
        })}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              placeholder={t("search")}
              fullWidth
              type="search"
              value={searchString}
              onChange={(event) => setSearchString(event.target.value)}
              InputProps={{ startAdornment: <Search color="secondary" /> }}
            />
          </Grid>
          <Grid item className={classes.selection}>
            {renderSelection()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button color="primary" variant="contained" onClick={onSaveClicked}>
          {t("assign")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
