import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Material } from "../../../api/nachfahrbarkeit-api/generated";
import { IKeyLabelPair, MultiSelect } from "../../multi-select/multi-select";

type PartialMaterial = Pick<Material, "id" | "name">;

interface MaterialMultiSelectProps {
  options: PartialMaterial[];
  selectedIds: string[];
  onChange: (newSelected: string[]) => void;
  loading?: boolean;
  error?: boolean;
}

export const MaterialMultiSelect: VFC<MaterialMultiSelectProps> = (props) => {
  const { options, selectedIds, onChange } = props;
  const { t } = useTranslation();

  const getOptions = (): IKeyLabelPair[] => {
    return options.map((material) => ({ key: material.id, label: material.name }));
  };

  return (
    <MultiSelect
      options={getOptions()}
      selected={selectedIds}
      label={t("material_multi_select.label")}
      onChange={onChange}
    />
  );
};
