/*global google*/
import React, { FunctionComponent, useState, useEffect } from "react";
import { Marker, InfoWindow, useGoogleMap } from "@react-google-maps/api";
import { theme } from "../../theme/theme";
import StartIcon from "../../assets/images/google-maps/route-start.png";
import EndIcon from "../../assets/images/google-maps/route-end.png";
import { MarkerType } from "./custom-map";

interface ICustomMarkerProps {
  id: number;
  lat: number;
  lng: number;
  onClick?: (id: number) => void;
  isSelected: boolean;
  number?: string;
  type?: Exclude<MarkerType, "circle">;
  disableInfoWindow?: boolean;
  disableZoom?: boolean;
  renderCustomInfoWindow?: (markerId: number) => JSX.Element | "";
}

export const CustomMarker: FunctionComponent<ICustomMarkerProps> = (props) => {
  const {
    id,
    lat,
    lng,
    disableInfoWindow,
    disableZoom,
    renderCustomInfoWindow = () => {
      return "";
    },
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const instance = useGoogleMap();

  useEffect(() => {
    if (!props.isSelected) {
      setIsOpen(false);
    } else if (instance) {
      if (!disableZoom) {
        instance.setZoom(15);
      }
      if (!isOpen) {
        onMarkerClicked();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSelected, instance]);

  const onMarkerClicked = () => {
    if (instance) {
      if (!disableZoom) {
        (instance as any).zoom = 15;
      }

      if (!disableInfoWindow) {
        setIsOpen(true);
      } else {
        instance.setCenter(new google.maps.LatLng(lat, lng));
      }

      if (props.onClick) {
        props.onClick(props.id);
      }
    }
  };

  const getIcon = (): google.maps.Icon | string => {
    let color = theme.PRIMARY_COLOR.main.replace("#", "");
    const text = props.number ? props.number : "%E2%80%A2";
    if (!props.type) {
      return `https://chart.apis.google.com/chart?chst=d_map_spin&chld=0.6|0|${color}|10|b|${text}`;
    }

    const iconSize = new google.maps.Size(36, 36);
    switch (props.type) {
      case "start":
        return {
          url: StartIcon,
          scaledSize: iconSize,
        };
      case "end":
        return {
          url: EndIcon,
          scaledSize: iconSize,
        };
      case "default":
      default:
        color = "e86161";
    }

    return `https://chart.apis.google.com/chart?chst=d_map_spin&chld=0.5|0|${color}|10|b|${text}`;
  };

  const infoWindowPixelOffset = new google.maps.Size(0, -30.75);
  return (
    <Marker
      key={`map_${id}`}
      position={{
        lat: lat,
        lng: lng,
      }}
      icon={getIcon()}
      onClick={onMarkerClicked}
    >
      {isOpen && (
        <InfoWindow
          onCloseClick={() => setIsOpen(false)}
          options={{
            pixelOffset: infoWindowPixelOffset,
          }}
          position={{
            lat,
            lng,
          }}
        >
          {renderCustomInfoWindow(id)}
        </InfoWindow>
      )}
    </Marker>
  );
};

CustomMarker.defaultProps = {
  onClick: () => {},
};
