import React, { createContext, FC, useContext, useState } from "react";
import { DriverLocation } from "../api/nachfahrbarkeit-api/generated";

interface IDriverLocationManagementContextType {
  selectedDriverLocation?: DriverLocation;
  setSelectedDriverLocation: (driverLocation?: DriverLocation) => void;
}

export const DriverLocationManagementContext = createContext<IDriverLocationManagementContextType>(
  {} as IDriverLocationManagementContextType,
);

export const DriverLocationManagementProvider: FC = (props) => {
  const value = useDriverLocationManagementProvider();

  return (
    <DriverLocationManagementContext.Provider value={value}>{props.children}</DriverLocationManagementContext.Provider>
  );
};

export const useDriverLocationManagementContext = (): IDriverLocationManagementContextType =>
  useContext(DriverLocationManagementContext);

const useDriverLocationManagementProvider = (): IDriverLocationManagementContextType => {
  const [selectedDriverLocation, setSelectedDriverLocation] = useState<DriverLocation | undefined>();

  return {
    selectedDriverLocation,
    setSelectedDriverLocation,
  };
};
