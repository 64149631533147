import { Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../../components/heading/page-heading";
import { useCompletedRoutesOverviewContext } from "../../../context/completed-routes-overview-context";
import { CompletedRoutesCards } from "./completed-routes-cards";
import { CompletedRoutesFilter } from "./completed-routes-filter";
import { CompletedRoutesTable } from "./completed-routes-table";

interface ICompletedRoutesOverviewProps {}

export const CompletedRoutesOverview: FunctionComponent<ICompletedRoutesOverviewProps> = (props) => {
  const { t } = useTranslation();

  const { completedRoutesRows, loading, error, pagination } = useCompletedRoutesOverviewContext();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageHeading>{t("completed_routes_overview.title")}</PageHeading>
        </Grid>
      </Grid>

      <Grid container item direction="column" spacing={2}>
        <Grid item>
          <CompletedRoutesFilter />
        </Grid>

        <Grid item>
          <CompletedRoutesCards />
        </Grid>

        <Grid item>
          <CompletedRoutesTable
            completedRoutes={completedRoutesRows}
            loading={loading}
            error={!!error}
            pagination={pagination}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
