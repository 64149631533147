import { Grid, makeStyles, Theme, ThemeProvider } from "@material-ui/core";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import { useGetMeQuery } from "../../api/nachfahrbarkeit-api/generated";
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_OPEN, Sidebar } from "../../components/sidebar/sidebar";
import { useDriverLocation } from "../../context/driver-location-filter-context";
import { useUserContext } from "../../context/user-context";
import { ROUTES } from "../../router/router";
import { UserService } from "../../services/user-service";
import { getTheme } from "../../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  marginLeftDrawerOpen: {
    marginLeft: DRAWER_WIDTH_OPEN,
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
  },
  marginLeftDrawerClosed: {
    marginLeft: DRAWER_WIDTH_CLOSED,
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
  },
}));

interface IPortalPageProps {}

export const PortalPage: FunctionComponent<IPortalPageProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const classes = useStyles();
  const history = useHistory();

  if (!UserService.isUserLoggedIn()) {
    history.push(ROUTES.LOGIN.PATH);
  }

  const { user, userLoading, loadUser } = useUserContext();
  const { setDriverLocationId } = useDriverLocation();

  useEffect(() => {
    if (!user && UserService.isUserLoggedIn()) {
      loadUser();
    }
    if (user) {
      setDriverLocationId(user.default_driver_location_id ?? undefined);
    }
  }, [user, loadUser, setDriverLocationId]);

  const { loading, data } = useGetMeQuery();
  const userTheme = data ? getTheme(data.getMe.theme) : undefined;

  return (
    <>
      {(loading || userLoading) && <Fragment />}
      {!loading && !userLoading && userTheme && (
        <ThemeProvider theme={userTheme}>
          <Grid container>
            <Grid item>
              <Sidebar onOpenChange={(isOpen) => setIsOpen(isOpen)} />
            </Grid>
            <Grid
              item
              className={`${classes.content} ${isOpen ? classes.marginLeftDrawerOpen : classes.marginLeftDrawerClosed}`}
            >
              <Switch>
                {UserService.getAvailablePaths().map((route) => (
                  <Route path={route.PATH} key={route.PATH}>
                    <route.COMPONENT />
                  </Route>
                ))}
              </Switch>
            </Grid>
          </Grid>
        </ThemeProvider>
      )}
    </>
  );
};
