import React, { FunctionComponent } from "react";
import { Theme, makeStyles, Typography } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: "bold",
  },
}));

interface ITextProps extends TypographyProps {
  bold?: boolean;
  className?: string;
}

export const Text: FunctionComponent<ITextProps> = (props) => {
  const classes = useStyles();
  const { bold, className, ...rest } = props;
  return (
    <Typography {...rest} className={`${bold && classes.bold} ${className}`}>
      {props.children}
    </Typography>
  );
};

Text.defaultProps = {
  bold: false,
};
