import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { FC } from "react";

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

export const LoadingButton: FC<LoadingButtonProps> = (props) => {
  const { loading, disabled, children, ...rest } = props;
  return (
    <Button disabled={loading || disabled} {...rest}>
      {loading ? <CircularProgress size={24} /> : children}
    </Button>
  );
};
