import React, { FunctionComponent } from "react";
import { Button, DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "../buttons/loading-button";

interface IConfirmDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  heading: string;
  confirmText: string;
  text?: string;
  showNegativeButton?: boolean;
  negativeText?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const ConfirmDialog: FunctionComponent<IConfirmDialogProps> = (props) => {
  const { t } = useTranslation();
  const {
    open,
    onClose,
    onConfirm,
    heading,
    text,
    confirmText,
    children,
    showNegativeButton = true,
    negativeText,
    loading,
    disabled,
  } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        {showNegativeButton && (
          <Button onClick={onClose} variant="contained">
            {negativeText || t("cancel")}
          </Button>
        )}
        <LoadingButton onClick={onConfirm} color={"primary"} variant="contained" loading={loading} disabled={disabled}>
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
