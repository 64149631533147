export const TRANSLATIONS_EN = {
  general: {
    guided_tour: "Guided Tour",
    button: {
      cancel: "Cancel",
      yes: "Yes",
      no: "No",
      close: "Close",
    },
    login: "Login",
    sign_in: "Login",
    username: "Username",
    password: "Password",
    logout: "Logout",
    logout_header: "Do you really want to log out?",
  },
  date_time: {
    date_format: "dd/MM/yyyy",
    date_time_format: "dd/MM/yyyy hh:mm a",
    date_time_input_format: "dd/MM/yyyy hh:mm a",
    day_of_month_format: "MMM Do",
    date_time_seconds_format: "dd/MM/yyyy hh:mm:ss a",
  },
  delete_dialog: {
    no: "No",
    yes: "Delete",
  },
  errors: {
    no_access: "You have no access to the Guided Tour Portal!",
    wrong_login: "Wrong username or password",
    login_failed: "An error occurred when trying to log in!",
    forbidden: "You do not have access!",
    general_api_error: "An unknown error occurred",
    fetch_error: "An error occurred while loading this data!",
    empty: "Nothing found.",
  },
  dashboard: {
    title: "Dashboard",
    heading: "Dashboard",
    page_title: "Dashboard",
  },
  administration: {
    administration: "Administration",
    users: "Users",
    driver_locations: "Driver locations",
    materials: "Materials",
  },
  driver_location_context: {
    title: "Driver Locations",
  },
  driver_location_select: {
    title: "Driver Locations",
    error: "An error occurred while loading driver locations!",
    none: "No filter",
  },
  delete_route_dialog: {
    head: "Delete route",
    body: "Do you really want to delete the route?",
    error: "Route could not be deleted.",
    success: "Route was successfully deleted.",
  },
  routes: {
    routes_basedata: "Route Management",
  },
  routes_management: {
    title: "Route management",
    import_routes: "Import routes",
    filter_driver_location: "Filter routes by location:",
    dropzone_text: "Drag files or click to upload routes",
    cancel: "Cancel",
    import: "Import",
    filename: "file name",
    name: "Tour name",
    tour_number: "Tour number",
    routes_uploaded: "Routes were successfully uploaded.",
    could_not_upload_routes: "Routes could not be uploaded.",
    replace_routes_title: "Replace existing route",
    replace_routes_text: "Do you really want to replace the following routes?",
    replace: "Replace",
    choose_location: "Location",
    add_stop_info: "Stop info saved succesfully",
    delete_stop_info: "Stop info deleted succesfully",
    delete: "delete",
    open_images: "open images",
    no_images: "no images",
    filter_route_fraction: "Filter routes by material:",
    location_select: {
      success: "Location changed",
      error: "Location could not be changed",
    },
    table: {
      id: "ID",
      tour_number: "Tour number",
      filename: "File name",
      name: "Tour name",
      date: "Date",
      stopName: "Stop",
      lat: "Latitude",
      long: "Longitude",
      eventType: "Type",
      info: "Info",
      sequence_number: "Sequencenumber",
      images: "Images",
      empty: "Route is empty.",
      material: "Material",
    },
    errors: {
      could_not_load: "An error occurred while trying to load routes",
      no_relevant_info: "Could not display event information",
      no_info_provided: "Invalid Data!",
      add_stop_info: "Failed to save stop info",
      delete_stop_info: "Failed to delete stop info",
    },
    file_import: "Upload File",
    rona_import: "Import from RONA",
    routes: "Routes",
  },
  route_details: {
    material: "Material",
    tour_number: "Tour",
    stop_infos: {
      delete_dialog: {
        head: "Delete information",
        body: "Do you really want to delete this information?",
      },
    },
  },
  completed_routes_overview: {
    title: "Completed Routes",
    filter: {
      tour_number: "Tour Number",
      tour_name: "Tour Name",
      from_date: "From",
      to_date: "To",
      driver_name: "Driver Name",
    },
    cards: {
      tour_number: "Tour Number: {{tourNumber}}",
      count: "Count: {{count}}",
    },
    table: {
      tour_number: "Tour Number",
      tour_name: "Tour Name",
      material: "Material",
      start_date: "Start Date",
      end_date: "End Date",
      driver_name: "Driver Name",
      progress: "Progress",
    },
  },
  driver_location_management: {
    create: {
      info: "New driver location",
      success: "Driver location successfully created.",
      error: "Driver location could not be created.",
    },
    update: {
      info: "Update driver location",
      success: "Driver location successfully updated.",
      error: "Driver location could not be updated.",
    },
    delete: {
      dialog: {
        head: "Delete driver location",
        body: "Do you really want to delete the driver location?",
      },
      info: "Delete driver location",
      success: "Driver location successfully deleted.",
      error: "Driver location could not be deleted.",
    },
    driver_location: {
      title: "Driver Locations",
      data: {
        name: "Name",
        name_required: "Missing name",
      },
    },
    action: {
      create: "Create",
      save: "Save",
      cancel: "Cancel",
    },
    filter_driver_location: "Filter routes by location:",
  },
  material_management: {
    create: {
      info: "New material",
      success: "Material successfully created.",
      error: "Material could not be created.",
    },
    update: {
      info: "Update material",
      success: "Material successfully updated.",
      error: "Material could not be updated.",
    },
    delete: {
      dialog: {
        head: "Delete material",
        body: "Do you really want to delete the material?",
      },
      info: "Delete material",
      success: "Material successfully deleted.",
      error: "Material could not be deleted.",
    },
    material: {
      title: "Materials",
      data: {
        name: "Name",
        name_required: "Missing name",
        name_too_short: "Name must be atleast {{length}} characters long",
      },
    },
    action: {
      create: "Create",
      save: "Save",
      cancel: "Cancel",
    },
  },
  user_role: {
    guidance_admin: "Guidance Admin",
    guided_driver: "Guided Driver",
    super_admin: "Super Admin",
    all: "All",
  },
  user_management: {
    heading: "User management",
    please_select_user: "Please select a user",
    data: {
      nothing_assigned: "No {{collection}} are assigned to you",
      title: "User data of {{firstName}} {{lastName}}",
      first_name: "First name",
      last_name: "Last name",
      username: "Username",
      role: "Role",
      password: "Password",
      default_driver_location: "Default Driver Location",
      partner: "Partner",
      create_user: "Create",
      save_user: "Save",
      delete_user: "Delete",
      assign: "{{collection}} Assign",
      password_changed: "Password changed",
      user_created: "User was created",
      user_updated: "User was updated",
      user_deleted: "User was deleted",
      could_not_create_user: "User could not be created",
      could_not_update_user: "User could not be updated",
      could_not_delete_user: "User could not be deleted",
      delete_dialog: {
        heading: "Delete",
        confirm_text: "Delete",
        text: "Do you really want to delete this user?",
      },
    },
    new_user: {
      title: "Create user",
    },
    filter: {
      heading: "Filter",
      first_name: "First name",
      last_name: "Last name",
      role: "Role",
      apply_filter: "Apply filter",
      remove_filter: "Remove filter",
    },
  },
  location_type: {
    location: "Driver Location",
    location_plural: "Driver Locations",
  },
  material_select: {
    title: "Materials",
    none: "No filter",
    error: "An error occurred loading the materials.",
  },
  material_multi_select: {
    label: "Materials",
  },
};
