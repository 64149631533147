import React, { FunctionComponent } from "react";
import { DriverLocationManagementProvider } from "../../context/driver-location-management-context";
import { RefetchProvider } from "../../context/refetch-context";
import { DriverLocationAdministration } from "./partials/driver-location-administration";

interface IDriverLocationsManagementPageProps {}

export const DriverLocationsManagementPage: FunctionComponent<IDriverLocationsManagementPageProps> = (props) => (
  <DriverLocationManagementProvider>
    <RefetchProvider>
      <DriverLocationAdministration />
    </RefetchProvider>
  </DriverLocationManagementProvider>
);
