import { Grid } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  DriverLocation,
  useGetDriverLocationsQuery,
  useRemoveDriverLocationMutation,
} from "../../../api/nachfahrbarkeit-api/generated";
import { ConfirmDialog } from "../../../components/dialogs/confirm-dialog";
import { SimpleEditList } from "../../../components/lists/simple-edit-list/simple-edit-list";
import { Text } from "../../../components/text/text";
import { useDriverLocationManagementContext } from "../../../context/driver-location-management-context";
import { useRefetchContext } from "../../../context/refetch-context";

interface IDriverLocationSelectionProps {}

export const DriverLocationSelection: FunctionComponent<IDriverLocationSelectionProps> = (props) => {
  const { t } = useTranslation();
  const { needToRefetch, setNeedToRefetch } = useRefetchContext();
  const { setSelectedDriverLocation } = useDriverLocationManagementContext();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [removableLocation, setRemovableLocation] = useState<DriverLocation | undefined>(undefined);

  const {
    data: getDriverLocationsData,
    loading: getDriverLocationsLoading,
    error: getDriverLocationsError,
  } = useGetDriverLocationsQuery({
    fetchPolicy: needToRefetch ? "network-only" : "cache-first",
    onCompleted: () => setNeedToRefetch(false),
  });

  const [removeDriverLocation, { loading: removeDriverLocationLoading }] = useRemoveDriverLocationMutation({
    onCompleted: (data) => {
      if (data?.removeDriverLocation.error !== false) {
        toast.error(t("driver_location_management.delete.error"));
        return;
      }
      toast.success(t("driver_location_management.delete.success"));

      setNeedToRefetch(true);
      setDeleteDialogOpen(false);
      setSelectedDriverLocation(undefined);
    },
  });

  const onRemove = async () => {
    if (!removableLocation) return;
    await removeDriverLocation({
      variables: {
        id: removableLocation.id,
      },
    });
  };

  return (
    <Grid container direction="column" spacing={1}>
      <ConfirmDialog
        open={deleteDialogOpen}
        heading={t("driver_location_management.delete.dialog.head")}
        confirmText={t("delete_dialog.yes")}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={onRemove}
        loading={removeDriverLocationLoading}
      >
        <Text>{t("driver_location_management.delete.dialog.body")}</Text>
      </ConfirmDialog>

      <SimpleEditList
        items={getDriverLocationsData?.driverLocations || []}
        loading={getDriverLocationsLoading}
        error={!!getDriverLocationsError}
        getIdentifier={(location) => location.name}
        onEdit={(location) => setSelectedDriverLocation(location)}
        onRemove={(location) => {
          setRemovableLocation(location);
          setDeleteDialogOpen(true);
        }}
      />
    </Grid>
  );
};
