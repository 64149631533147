/*global google*/
import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { Card } from "@material-ui/core";
import { CustomMarker } from "./custom-marker";
import { CustomFlatRouteMarker } from "../../pages/route-details/partials/custom-flat-route-marker";
import { GoogleMap, Polyline } from "@react-google-maps/api";

export type MarkerType = "default" | "circle" | "start" | "end";

export interface IMarker {
  id: number;
  lat: number;
  lng: number;
  type?: MarkerType;
  sequenceNumber?: number;
  doNotDisplayPin?: boolean;
}

export interface ICenterOption {
  id: number;
  lat: number;
  lng: number;
}

interface ICustomMapProps {
  markers?: IMarker[];
  center?: ICenterOption;
  height?: string;
  width?: string;
  style?: string;
  shouldSort?: boolean;
  onMapClicked?: (event: google.maps.MapMouseEvent) => void;
  onMarkerClicked?: (id: number) => void;
  selectedMarkerId?: number;
  zoom?: number;
  disableZoom?: boolean;
  disableInfoWindow?: boolean;
  displayPolyline?: boolean;
  doRenderCustomInfoWindow?: boolean;
  renderCustomInfoWindow?: (markerId: number) => JSX.Element | "";
  doRenderCustomPolylines?: boolean;
  renderCustomPolylines?: () => JSX.Element | "";
  disableFitBounds?: boolean;
}

export const CustomMap: FunctionComponent<ICustomMapProps> = (props) => {
  const {
    style,
    height,
    width,
    center,
    markers,
    shouldSort,
    onMapClicked,
    onMarkerClicked,
    selectedMarkerId,
    zoom,
    disableZoom,
    disableInfoWindow,
    displayPolyline,
    doRenderCustomInfoWindow,
    renderCustomInfoWindow,
    doRenderCustomPolylines,
    renderCustomPolylines = () => {
      return "";
    },
    disableFitBounds,
  } = props;
  const [selectedMarker, setSelectedMarker] = useState<number>(-1);
  const [mapInstance, setMapInstance] = useState<google.maps.Map>();

  useEffect(() => {
    if (onMarkerClicked) {
      onMarkerClicked(selectedMarker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarker]);

  useEffect(() => {
    if (mapInstance && !disableFitBounds) {
      fitMapBounds(mapInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  let sortedMarkers: IMarker[] = [];

  if (markers) {
    if (shouldSort) {
      sortedMarkers = markers.sort(
        (marker1: IMarker, marker2: IMarker) => Number(marker1.sequenceNumber) - Number(marker2.sequenceNumber),
      );
    } else {
      sortedMarkers = markers;
    }
  }

  const onMapInit = (map: google.maps.Map) => {
    setMapInstance(map);
    fitMapBounds(map);
  };

  const fitMapBounds = (map: google.maps.Map) => {
    if (sortedMarkers.length > 1) {
      const bounds = new google.maps.LatLngBounds();

      sortedMarkers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
      });

      map.fitBounds(bounds);
    } else if (sortedMarkers.length === 1) {
      map.setCenter(new google.maps.LatLng(sortedMarkers[0].lat, sortedMarkers[0].lng));
      if (!disableZoom) {
        map.setZoom(15);
      }
    }
  };

  const isSelected = (id: number) =>
    selectedMarkerId !== undefined && selectedMarkerId !== null ? selectedMarkerId === id : false;

  const renderMarkerContent = () => {
    return sortedMarkers
      .filter((marker) => !marker.doNotDisplayPin)
      .map((marker, idx) => {
        switch (marker.type) {
          case "circle":
            return (
              <CustomFlatRouteMarker
                key={`${marker.id}_${idx}`}
                id={marker.id}
                isSelected={isSelected(marker.id)}
                lat={marker.lat}
                lng={marker.lng}
                onClick={(id) => setSelectedMarker(id)}
                disableInfoWindow={disableInfoWindow}
                doRenderCustomInfoWindow={doRenderCustomInfoWindow}
                renderCustomInfoWindow={renderCustomInfoWindow}
              />
            );
          case "start":
          case "end":
          case "default":
          default:
            return (
              <CustomMarker
                key={`${marker.id}_${idx}`}
                id={marker.id}
                isSelected={isSelected(marker.id)}
                lat={marker.lat}
                lng={marker.lng}
                onClick={(id) => setSelectedMarker(id)}
                disableInfoWindow={disableInfoWindow}
                disableZoom={disableZoom}
                renderCustomInfoWindow={renderCustomInfoWindow}
                type={marker.type}
              />
            );
        }
      });
  };

  const getPolylines = () => {
    if (!displayPolyline) {
      return "";
    }

    if (doRenderCustomPolylines) {
      return renderCustomPolylines();
    }

    const pathCoordinates = markers?.map((marker) => {
      return { lat: marker.lat, lng: marker.lng };
    });

    return (
      <Polyline
        path={pathCoordinates}
        options={{
          strokeColor: "#000000",
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      />
    );
  };

  return (
    <Fragment>
      <Card className={style}>
        <GoogleMap
          onClick={onMapClicked}
          onLoad={onMapInit}
          mapContainerStyle={{
            height: height || "250px",
            width: width || "100%",
          }}
          zoom={zoom || 15}
          center={center}
          options={{ gestureHandling: "cooperative" }}
        >
          {getPolylines()}
          {markers && renderMarkerContent()}
        </GoogleMap>
      </Card>
    </Fragment>
  );
};

CustomMap.defaultProps = {
  onMarkerClicked: () => {},
  shouldSort: false,
};
