import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { MaterialData } from "./material-data";
import { MaterialSelection } from "./material-selection";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  dataContainer: {
    padding: theme.spacing(2),
  },
  container: {
    height: "calc(100vh - 56px)",
  },
  selectorContainer: {
    flex: "1",
    overflowY: "auto",
  },
}));

interface IMaterialAdministrationProps {}

export const MaterialAdministration: FunctionComponent<IMaterialAdministrationProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={2} className={classes.container}>
      <Grid container item direction="row" spacing={2} className={classes.dataContainer}>
        <Grid item xs>
          <MaterialData />
        </Grid>
      </Grid>
      <Grid item>
        <Typography className={classes.title}>{t("material_management.material.title")}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.selectorContainer}>
        <MaterialSelection />
      </Grid>
    </Grid>
  );
};
