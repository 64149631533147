import React, { VFC } from "react";
import { CompletedRoutesOverviewProvider } from "../../context/completed-routes-overview-context";
import { RefetchProvider } from "../../context/refetch-context";
import { CompletedRoutesOverview } from "./partials/completed-routes-overview";

interface ICompletedRoutesOverviewPageProps {}

export const CompletedRoutesOverviewPage: VFC<ICompletedRoutesOverviewPageProps> = (props) => {
  return (
    <RefetchProvider>
      <CompletedRoutesOverviewProvider>
        <CompletedRoutesOverview />
      </CompletedRoutesOverviewProvider>
    </RefetchProvider>
  );
};
