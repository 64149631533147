import { TableCell } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IPagination } from "../../../components/table/pagination";
import { ISimpleTableHeader, SimpleTable } from "../../../components/table/simple-table";

export interface ICompletedRouteRow {
  id: string;
  tourNumber: number;
  tourName: string;
  progress: number;
  startDate: string;
  endDate: string;
  driverName: string;
  materialName: string;
}

interface ICompletedRoutesTableProps {
  completedRoutes: ICompletedRouteRow[];
  loading: boolean;
  error?: boolean;
  pagination: IPagination;
}

enum TableHeaders {
  TourNumber = "TourNumber",
  TourName = "TourName",
  DriverName = "DriverName",
  MaterialName = "MaterialName",
  StartDate = "StartDate",
  EndDate = "EndDate",
  Progress = "Progress",
}

export const CompletedRoutesTable: FunctionComponent<ICompletedRoutesTableProps> = (props) => {
  const { t } = useTranslation();
  const { completedRoutes, loading, error, pagination } = props;

  const renderProgressColumn = (item: ICompletedRouteRow) => {
    return <TableCell align="center">{`${item.progress}%`}</TableCell>;
  };

  const headers: ISimpleTableHeader<TableHeaders, ICompletedRouteRow>[] = [
    { id: TableHeaders.TourNumber, title: t("completed_routes_overview.table.tour_number"), key: "tourNumber" },
    { id: TableHeaders.TourName, title: t("completed_routes_overview.table.tour_name"), key: "tourName" },
    { id: TableHeaders.MaterialName, title: t("completed_routes_overview.table.material"), key: "materialName" },
    { id: TableHeaders.StartDate, title: t("completed_routes_overview.table.start_date"), key: "startDate" },
    { id: TableHeaders.EndDate, title: t("completed_routes_overview.table.end_date"), key: "endDate" },
    { id: TableHeaders.DriverName, title: t("completed_routes_overview.table.driver_name"), key: "driverName" },
    {
      id: TableHeaders.Progress,
      title: t("completed_routes_overview.table.progress"),
      key: "progress",
      renderColumn: renderProgressColumn,
    },
  ];

  return (
    <SimpleTable headers={headers} items={completedRoutes} loading={loading} error={error} pagination={pagination} />
  );
};
