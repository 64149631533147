import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../components/heading/page-heading";
import { RefetchProvider } from "../../context/refetch-context";
import { UserManagementProvider } from "../../context/user-management-context";
import { UserManagementContent } from "./partials/user-management-content";
import { UserManagementSelector } from "./partials/user-management-selector";

const useStyles = makeStyles((theme: Theme) => ({
  data: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    overflowY: "auto",
  },
  container: {
    height: "calc(100vh - 56px)", // 56px equals all paddings and margins
    display: "flex",
    flexFlow: "column",
  },
  pageHeadingContainer: {
    padding: `0 ${theme.spacing(1)}px`,
    flex: "0 0 auto",
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  contentContainer: {
    padding: `0 ${theme.spacing(1)}px`,
    flex: "1 1 auto",
    overflowY: "auto",
    height: "100%",
  },
}));

interface UsersManagementPageProps {}

export const UsersManagementPage: FunctionComponent<UsersManagementPageProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <RefetchProvider>
      <UserManagementProvider>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.pageHeadingContainer}>
            <PageHeading>{t("user_management.heading")}</PageHeading>
          </Grid>
          <Grid container item direction="row" className={classes.contentContainer}>
            <UserManagementSelector />
            <Grid className={classes.data}>
              <Grid item>
                <UserManagementContent />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </UserManagementProvider>
    </RefetchProvider>
  );
};
