import React, { createContext, useContext, useState } from "react";

interface IRefetchContextType {
  needToRefetch: boolean;
  setNeedToRefetch: (value: boolean) => void;
}

interface IRefetchProps {
  children: JSX.Element;
}

const RefetchContext = createContext<IRefetchContextType>({} as IRefetchContextType);

export const RefetchProvider = (props: IRefetchProps): JSX.Element => {
  const value = useRefetchProvider(props);
  return <RefetchContext.Provider value={value}>{props.children}</RefetchContext.Provider>;
};

export const useRefetchContext = (): IRefetchContextType => {
  return useContext(RefetchContext);
};

const useRefetchProvider = (props: IRefetchProps): IRefetchContextType => {
  const [needToRefetch, setNeedToRefetch] = useState<boolean>(false);
  return {
    needToRefetch,
    setNeedToRefetch,
  };
};
