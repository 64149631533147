import React, { FunctionComponent } from "react";
import { RouteContextProvider } from "./partials/route-context";
import { RouteDetails } from "./partials/route-details";
import { useParams } from "react-router-dom";
import { RefetchProvider } from "../../context/refetch-context";

interface IRouteDetailsPageUrlParams {
  routeId: string;
}

interface IRouteDetailsPageProps {}

export const RouteDetailsPage: FunctionComponent<IRouteDetailsPageProps> = (props) => {
  const { routeId } = useParams<IRouteDetailsPageUrlParams>();

  return (
    <RefetchProvider>
      <RouteContextProvider routeId={routeId}>
        <RouteDetails />
      </RouteContextProvider>
    </RefetchProvider>
  );
};
