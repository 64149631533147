require("dotenv").config();

/**
 * Usage:
 *  1.) Add the name of your environment variable to `vars`.
 *  2.) `import { config, vars } from "./utils/config";` in your typescript code.
 *  3.) Access environment variables like so `const BASE_API_URI = config().env(vars.REACT_APP_GRAPHQL_URL);`
 *  4.) You can add your variables to `.env` when running `yarn start`
 *                                    OR
 *      You can set *actual environment variables* when running as a Docker container
 */

declare global {
  interface Window {
    env: { [key: string]: string };
  }
}

// List all your environment variables here for better readability
// All environments variables must start with "REACT_APP_" to be recognized automatically in dev as well as in production.
export const vars = Object.freeze({
  REACT_APP_GRAPHQL_URL: "REACT_APP_GRAPHQL_URL",
});

type Config = { env: (varname: string) => string | undefined };

export const config = (): Config => ({
  env: (varname: string): string | undefined => {
    return (window.env && window.env[varname]) || process.env[varname];
  },
});
