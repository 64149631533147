import { Grid, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles((theme) => ({
  infoCard: {
    height: "100%",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "3px",
  },
}));

interface IInfoCardProps {}

export const InfoCard: FC<IInfoCardProps> = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Grid container item direction="column" justifyContent="center" alignItems="center" className={classes.infoCard}>
      {children}
    </Grid>
  );
};
