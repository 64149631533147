import {
  Avatar,
  Button,
  Card,
  Grid,
  makeStyles,
  SnackbarContent,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useLoginMutation, UserRole } from "../../api/nachfahrbarkeit-api/generated";
import { ROUTES } from "../../router/router";
import { UserService } from "../../services/user-service";

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    width: "100%",
    height: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.dark,
  },
  card: {
    height: 370,
    width: 320,
  },
  logo: {
    width: "60%",
  },
  logoContainer: {
    height: 50,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.dark,
  },
  loginContainer: {
    padding: 10,
    flex: 1,
  },
  fieldsContainer: {
    flex: 1,
  },
  button: {
    margin: 10,
  },
  formContainer: {
    height: "100%",
  },
  snackBar: {
    backgroundColor: theme.palette.error.dark,
    marginTop: 10,
  },
}));

interface ILoginPageProps {}

export const LoginPage: FunctionComponent<ILoginPageProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [shownError, setShownError] = useState<string | null>(null);
  const [isUsernameError, setIsUsernameError] = useState<boolean>(false);
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(false);

  if (UserService.isUserLoggedIn()) {
    history.push(ROUTES.PORTAL.ROUTES.ROUTE_MANAGEMENT.PATH);
  }

  const [loginMutation] = useLoginMutation({
    onCompleted: (data) => {
      if (data.login.user.role === UserRole.GUIDED_DRIVER) {
        setIsLoginDisabled(false);
        setShownError(t("errors.no_access"));
      } else {
        UserService.login(data.login.token);
        history.push(ROUTES.PORTAL.ROUTES.ROUTE_MANAGEMENT.PATH);
      }
    },
    onError: (error) => {
      setIsLoginDisabled(false);
      setShownError(t("errors.login_failed"));
    },
  });

  const onSnackBarClose = () => {
    setShownError(null);
  };

  const onLoginButtonClicked = async () => {
    setIsLoginDisabled(true);
    const validUsername = isValidUsername(username);
    const validPassword = isValidPassword(password);
    setIsUsernameError(!validUsername);
    setIsPasswordError(!validPassword);
    if (validUsername && validPassword) {
      loginMutation({ variables: { username, password } });
    } else {
      setIsLoginDisabled(false);
    }
  };

  const isValidUsername = (username: string) => {
    return username.length > 0;
  };

  const isValidPassword = (password: string) => {
    return password.length > 0;
  };

  // eslint-disable-next-line
  const onEnter = (ev: any) => {
    if (ev.key === "Enter") {
      onLoginButtonClicked();
    }
  };

  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.pageContainer}>
      <Grid item>
        <Card raised className={classes.card}>
          <Grid container direction="column" className={classes.formContainer}>
            <Grid container justify="center" alignItems="center" className={classes.logoContainer}>
              <Typography style={{ color: "white" }}>{t("general.guided_tour")}</Typography>
            </Grid>

            <Grid container direction="column" alignItems="center" className={classes.loginContainer}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography>{t("general.sign_in")}</Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label={t("general.username")}
                name="username"
                autoFocus
                error={isUsernameError}
                value={username}
                onChange={(ev) => {
                  setUsername(ev.target.value);
                }}
                onKeyPress={onEnter}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("general.password")}
                type="password"
                id="password"
                autoComplete="current-password"
                error={isPasswordError}
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                onKeyPress={onEnter}
              />
            </Grid>
            <Button
              className={classes.button}
              variant="contained"
              type="button"
              color="primary"
              onClick={onLoginButtonClicked}
              disabled={isLoginDisabled}
            >
              {t("general.login")}
            </Button>
          </Grid>
        </Card>
      </Grid>
      <Grid>
        {shownError && (
          <SnackbarContent
            className={classes.snackBar}
            message={shownError}
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={onSnackBarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}
      </Grid>
    </Grid>
  );
};
