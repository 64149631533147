import React, { FunctionComponent, Fragment, useState } from "react";
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../confirm-dialog";
import { MaterialSelect } from "../../selects/material/material-select";
import { DriverLocationContextSelect } from "../../selects/driver-location/driver-location-context-select";
import { ImportRouteType, useImportRoutesContext } from "./import-route-context";
import { ImportRouteDropZone } from "./import-route-dropzone";
import { useUserContext } from "../../../context/user-context";
import { ImportRouteTabs } from "./import-route-tabs";
import { useRefetchContext } from "../../../context/refetch-context";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: 600,
    padding: theme.spacing(2),
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
  replaceText: {
    fontWeight: "bold",
  },
  locationSelect: {
    marginTop: "10px",
  },
}));

interface IImportRouteDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ImportRouteDialog: FunctionComponent<IImportRouteDialogProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const { setNeedToRefetch } = useRefetchContext();
  const { open, onClose } = props;

  const { user } = useUserContext();

  const {
    setFiles,
    selectedMaterial,
    setSelectedMaterial,
    disableImportButton,
    upload,
    setRonaRoute,
    setImportType,
    duplicateRoutes,
  } = useImportRoutesContext();

  const triggerUpload = async () => {
    setConfirmOpen(false);
    setUploadLoading(true);
    const success = await upload();

    setUploadLoading(false);
    setNeedToRefetch(true);
    if (success) {
      toast.success(t("routes_management.routes_uploaded"));
      handleClose();
    } else {
      toast.error(t("routes_management.could_not_upload_routes"));
    }
  };

  const checkForDuplicates = () => {
    if (duplicateRoutes.length > 0) {
      setConfirmOpen(true);
    } else {
      triggerUpload();
    }
  };

  const handleClose = () => {
    setFiles([]);
    setSelectedMaterial(undefined);
    setRonaRoute(null);
    setImportType(ImportRouteType.FileImport);
    onClose();
  };

  return (
    <Fragment>
      <ConfirmDialog
        confirmText={t("routes_management.replace")}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => triggerUpload()}
        open={confirmOpen}
        heading={t("routes_management.replace_routes_title")}
      >
        <Typography className={classes.replaceText}>{t("routes_management.replace_routes_text")}</Typography>
        <List component="ul">
          {duplicateRoutes.map((r, index) => (
            <ListItem key={`route_${index}`}>{r}</ListItem>
          ))}
        </List>
      </ConfirmDialog>
      <Backdrop open={uploadLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>{t("routes_management.import_routes")}</DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container direction="column" spacing={2}>
            <Grid item className={classes.locationSelect}>
              <DriverLocationContextSelect />
            </Grid>
            <Grid item>
              <MaterialSelect
                materialId={selectedMaterial}
                name={t("material_select.title")}
                onChange={setSelectedMaterial}
              />
            </Grid>
            {user?.groupHasRonaParticipation ? <ImportRouteTabs /> : <ImportRouteDropZone />}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs>
              <Button onClick={handleClose} autoFocus variant="contained" fullWidth>
                {t("routes_management.cancel")}
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                onClick={() => checkForDuplicates()}
                variant="contained"
                color="primary"
                fullWidth
                disabled={disableImportButton}
              >
                {t("routes_management.import")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
