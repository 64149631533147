import React, { FunctionComponent, useCallback, useState, useEffect } from "react";
import { Theme, makeStyles, Typography } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import lodash from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    padding: theme.spacing(3),
    cursor: "pointer",
  },
  text: {
    textAlign: "center",
    color: "#979797",
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

interface IDropzoneProps {
  files: File[];
  text: string;
  updateFiles: (files: File[]) => void;
  accept: string[];
  onAbort?: () => void;
  onError?: () => void;
}

export const Dropzone: FunctionComponent<IDropzoneProps> = (props) => {
  const classes = useStyles();
  const { updateFiles, text, files, accept, onAbort, onError } = props;
  const [currentFile, setCurrentFile] = useState<File>();

  useEffect(() => {
    if (currentFile) {
      updateFiles(files.concat(currentFile));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFile]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => {
        if (lodash.isFunction(onAbort)) {
          onAbort();
        }
      };
      reader.onerror = () => {
        if (lodash.isFunction(onError)) {
          onError();
        }
      };
      reader.onload = () => {
        setCurrentFile(file);
      };
      reader.readAsArrayBuffer(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept });

  return (
    <div {...getRootProps()} className={classes.dropzone}>
      <input {...getInputProps()} />
      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
};
