import React, { createContext, FC, useContext, useState } from "react";
import { Material } from "../api/nachfahrbarkeit-api/generated";

type PartialMaterial = Pick<Material, "id" | "name">;

interface IMaterialManagementContextType {
  selectedMaterial?: PartialMaterial;
  setSelectedMaterial: (material?: PartialMaterial) => void;
}

export const MaterialManagementContext = createContext<IMaterialManagementContextType>(
  {} as IMaterialManagementContextType,
);

export const MaterialManagementProvider: FC = (props) => {
  const value = useMaterialManagementProvider();

  return <MaterialManagementContext.Provider value={value}>{props.children}</MaterialManagementContext.Provider>;
};

export const useMaterialManagementContext = (): IMaterialManagementContextType => useContext(MaterialManagementContext);

const useMaterialManagementProvider = (): IMaterialManagementContextType => {
  const [selectedMaterial, setSelectedMaterial] = useState<PartialMaterial | undefined>();

  return {
    selectedMaterial,
    setSelectedMaterial,
  };
};
