import { Box, Grid, GridJustification, useTheme } from "@material-ui/core";
import React, { FC } from "react";

interface IInfoCardContainerProps {
  children: JSX.Element[];
  size?: "xs" | "sm" | "md" | "lg";
  spacing?: number;
}

export const InfoCardContainer: FC<IInfoCardContainerProps> = (props) => {
  const { children, size, spacing = 2 } = props;
  const theme = useTheme();

  if (children.length <= 0) return null;

  const getSize = () => {
    switch (size) {
      case "xs":
        return 8;
      case "sm":
        return 12;
      case "lg":
        return 20;
      default:
      case "md":
        return 16;
    }
  };

  const getJustifyContent = (): GridJustification => {
    if (children.length <= 3) return "space-evenly";
    return "space-between";
  };

  return (
    <Box position={"relative"} height={theme.spacing(getSize())}>
      <Box position={"absolute"} height={"100%"} width={"100%"} overflow={"scroll hidden"}>
        <Grid container wrap="nowrap" style={{ height: "100%" }} justifyContent={getJustifyContent()}>
          {children.map((child) => (
            <Grid
              key={child.key}
              item
              style={{ marginRight: `${theme.spacing(spacing)}px`, flex: `0 0 ${getSize()}em` }}
            >
              {child}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
