import { createTheme, Theme } from "@material-ui/core/styles";

interface ITheme {
  PRIMARY_COLOR: IThemeColor;
  SECONDARY_COLOR: IThemeColor;
  ERROR_COLOR: string;
  BACKGROUND_COLOR: string;
  TEXT_COLOR: string;
  LOGO_PATH: string;
}

interface IThemeColor {
  main: string;
  light: string;
  dark: string;
  contrastText: string;
}

export const theme: ITheme = {} as ITheme;

// eslint-disable-next-line
export const getTheme = (newTheme: any) => {
  theme.BACKGROUND_COLOR = newTheme.portal.background;
  theme.ERROR_COLOR = newTheme.portal.error;
  theme.LOGO_PATH = newTheme.logo_url;
  theme.PRIMARY_COLOR = {
    main: newTheme.portal.primary_main,
    dark: newTheme.portal.primary_dark,
    light: newTheme.portal.primary_light,
    contrastText: newTheme.portal.primary_contrast_text,
  };
  theme.SECONDARY_COLOR = {
    main: newTheme.portal.secondary_main,
    dark: newTheme.portal.secondary_dark,
    light: newTheme.portal.secondary_light,
    contrastText: newTheme.portal.secondary_contrast_text,
  };
  theme.TEXT_COLOR = newTheme.portal.text;
  return createAppTheme(theme);
};

export const SPACING = 8;
export const DRAWER_WIDTH_OPEN = 220;
export const DRAWER_WIDTH_CLOSED = 73;
export const CHART_COLORS = ["#58AF47", "#A4243B", "#275EA5", "#1C0F13", "#FF9F1C"];

export let AppTheme: Theme;

const createAppTheme = (newTheme: ITheme) => {
  return createTheme({
    palette: {
      primary: {
        light: newTheme.PRIMARY_COLOR.light,
        main: newTheme.PRIMARY_COLOR.main,
        dark: newTheme.PRIMARY_COLOR.dark,
        contrastText: newTheme.PRIMARY_COLOR.contrastText,
      },
      secondary: {
        light: newTheme.SECONDARY_COLOR.light,
        main: newTheme.SECONDARY_COLOR.main,
        dark: newTheme.SECONDARY_COLOR.dark,
        contrastText: newTheme.SECONDARY_COLOR.contrastText,
      },
      error: {
        main: newTheme.ERROR_COLOR,
      },
    },
    spacing: SPACING,
    // @ts-ignore
    customColors: {
      background: "#16181f",
    },
    typography: {
      fontFamily: "'Roboto', 'Helvetica Neue',Helvetica,Arial,sans-serif",
    },
    overrides: {
      MuiInput: {
        underline: {
          "&:hover:not($disabled):not($focused):not($error):before": {
            borderBottom: "1px solid #3b474f",
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          height: "40px",
          "& $notchedOutline": {
            borderColor: newTheme.PRIMARY_COLOR.main,
          },
        },
        notchedOutline: {
          borderRadius: "4",
          borderWidth: "1px",
        },
      },
      MuiTableCell: {
        root: {
          borderBottomColor: newTheme.SECONDARY_COLOR.light,
          borderRight: `1px solid ${newTheme.SECONDARY_COLOR.light}`,
          paddingLeft: SPACING,
          paddingRight: SPACING,
          minWidth: 100,
          "&:first-child": {
            minWidth: 40,
          },
          "&:last-child": {
            paddingRight: SPACING,
            minWidth: 50,
            borderRight: "none",
          },
        },
      },
      MuiTableSortLabel: {
        icon: {
          opacity: 1,
        },
      },
      MuiCheckbox: {
        root: {
          color: "#84d1e0",
        },
      },
      MuiInputLabel: {
        outlined: {
          transform: "translate(14px, 12px) scale(1)",
        },
      },
      MuiButton: {
        root: {
          borderRadius: "0",
          boxShadow: "none",
        },
        contained: {
          borderRadius: 2,
          boxShadow: "0 0 12px -5px rgba(0, 0, 0, 0.3)",
          minWidth: 105,
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: "none",
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: 14,
        },
      },
    },
  });
};
