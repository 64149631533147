import React, { FunctionComponent, useCallback, useState } from "react";
import { Theme, makeStyles, Grid, Tabs, Tab } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TabPanel } from "../../tabs/tab-panel";
import { ImportRouteDropZone } from "./import-route-dropzone";
import { RonaImportContent } from "./rona-import-content";
import { ImportRouteType, useImportRoutesContext } from "./import-route-context";

const useStyles = makeStyles((theme: Theme) => ({
  tabPanel: {
    padding: theme.spacing(2),
    background: "rgba(0, 0, 0, 0.05)",
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
  tabs: {
    // color: theme.palette.secondary.dark,
    background: "rgba(0, 0, 0, 0.05)",
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
  },
}));

interface IImportRouteTabsProps {}

export const ImportRouteTabs: FunctionComponent<IImportRouteTabsProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState<number>(0);

  const { setImportType } = useImportRoutesContext();

  const updateTab = useCallback(
    (index: number) => {
      setCurrentTab(index);
      setImportType(index === 0 ? ImportRouteType.FileImport : ImportRouteType.RonaImport);
    },
    [setImportType],
  );

  return (
    <Grid container item>
      <Grid item xs={12}>
        <Tabs value={currentTab} onChange={(_, value) => updateTab(value)} variant="fullWidth" className={classes.tabs}>
          <Tab label={t("routes_management.file_import")} />
          <Tab label={t("routes_management.rona_import")} />
        </Tabs>
      </Grid>
      <Grid item xs={12} className={classes.tabPanel}>
        <TabPanel value={currentTab} index={0}>
          <ImportRouteDropZone />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <RonaImportContent />
        </TabPanel>
      </Grid>
    </Grid>
  );
};
