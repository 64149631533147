import React, { VFC } from "react";
import { InfoCard } from "../../../components/info-card/info-card";
import { InfoCardContainer } from "../../../components/info-card/info-card-container";
import { Text } from "../../../components/text/text";
import { useCompletedRoutesOverviewContext } from "../../../context/completed-routes-overview-context";
import lodash from "lodash";
import { useTranslation } from "react-i18next";

const MIN_PROGRESS = 10;

interface ICompletedRoutesCardsProps {}

export const CompletedRoutesCards: VFC<ICompletedRoutesCardsProps> = (props) => {
  const { t } = useTranslation();
  const { completedRoutesRows } = useCompletedRoutesOverviewContext();

  const drivenRoutes = completedRoutesRows.filter((route) => route.progress >= MIN_PROGRESS);
  const countedByTourNumber = lodash.countBy(drivenRoutes, (route) => route.tourNumber);
  const sortedTourNumberCountPair = Object.entries(countedByTourNumber).sort(
    ([_tourNumber1, count1], [_tourNumber2, count2]) => count2 - count1,
  );

  return (
    <InfoCardContainer size="md" spacing={2}>
      {sortedTourNumberCountPair.map(([tourNumber, count]) => (
        <InfoCard key={tourNumber}>
          <Text bold>{t("completed_routes_overview.cards.tour_number", { tourNumber })}</Text>
          <Text>{t("completed_routes_overview.cards.count", { count })}</Text>
        </InfoCard>
      ))}
    </InfoCardContainer>
  );
};
