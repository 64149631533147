export const TRANSLATIONS_DE = {
  general: {
    guided_tour: "Nachfahrbarkeit",
    button: {
      cancel: "Abbrechen",
      yes: "Ja",
      no: "Nein",
      close: "Schließen",
    },
    login: "Login",
    sign_in: "Login",
    username: "Benutzername",
    password: "Passwort",
    logout: "Abmelden",
    logout_header: "Wollen Sie sich wirklich abmelden?",
  },
  date_time: {
    date_format: "dd.MM.yyyy",
    date_time_format: "dd.MM.yyyy HH:mm",
    date_time_input_format: "dd.MM.yyyy HH:mm",
    day_of_month_format: "dd. MMM",
    date_time_seconds_format: "dd.MM.yyyy HH:mm:ss",
  },
  delete_dialog: {
    no: "Nein",
    yes: "Löschen",
  },
  errors: {
    no_access: "Sie haben keinen Zugriff auf das Nachfahrbarkeits Portal!",
    wrong_login: "Falscher Benutzername oder falsches Passwort.",
    login_failed: "Fehler beim Anmelden!",
    forbidden: "Sie haben keine Berechtigungen!",
    general_api_error: "Es ist ein unbekannter Fehler aufgetreten",
    fetch_error: "Beim Laden ist ein Fehler aufgetreten!",
    empty: "Es konnte nichts gefunden werden.",
  },
  dashboard: {
    title: "Dashboard",
    heading: "Dashboard",
    page_title: "Dashboard",
  },
  administration: {
    administration: "Administration",
    users: "Benutzer",
    driver_locations: "Fahrer Standorte",
    materials: "Fraktionen",
  },
  driver_location_context: {
    title: "Fahrer Standorte",
  },
  driver_location_select: {
    title: "Fahrer Standorte",
    error: "Fehler beim Laden der Fahrer Standorte!",
    none: "Kein Filter",
  },
  delete_route_dialog: {
    head: "Route löschen",
    body: "Wollen Sie die Route wirklich löschen?",
    error: "Route konnte nicht gelöscht werden.",
    success: "Route wurde erfolgreich gelöscht.",
  },
  routes: {
    routes_basedata: "Routenverwaltung",
  },
  routes_management: {
    title: "Routenverwaltung",
    import_routes: "Routen importieren",
    filter_driver_location: "Routen für Fahrer Standort filtern:",
    dropzone_text: "Dateien hierherziehen oder klicken, um Routen hochzuladen",
    cancel: "Abbrechen",
    import: "Importieren",
    filename: "Dateiname",
    name: "Tourname",
    tour_number: "Tournummer",
    routes_uploaded: "Routen wurden erfolgreich hochgeladen.",
    could_not_upload_routes: "Routen konnten nicht hochgeladen werden.",
    replace_routes_title: "Bestehende Routen ersetzen",
    replace_routes_text: "Wollen Sie folgende Routen ersetzen?",
    replace: "Ersetzen",
    choose_location: "Standort",
    add_stop_info: "Stop Info erfolgreich gespeichert",
    delete_stop_info: "Stop Info erfolgreich gelöscht",
    delete: "Löschen",
    open_images: "Fotos öffnen",
    no_images: "Keine Fotos vorhanden",
    filter_route_fraction: "Routen für Fraktionen filtern:",
    location_select: {
      success: "Standort geändert",
      error: "Standort konnte nicht geändert werden",
    },
    table: {
      id: "ID",
      tour_number: "Tournummer",
      filename: "Dateiname",
      name: "Tourname",
      date: "Datum",
      stopName: "Stop",
      lat: "Länge",
      long: "Breite",
      eventType: "Typ",
      info: "Info",
      sequence_number: "Reihenfolge",
      images: "Fotos",
      empty: "Route ist leer.",
      material: "Fraktion",
    },
    errors: {
      could_not_load: "Beim Laden ist ein Fehler aufgetreten",
      no_relevant_info: "Information konnte nicht angezeigt werden",
      no_info_provided: "Fehlerhafte Daten!",
      add_stop_info: "Fehler beim speichern der Stop Info",
      delete_stop_info: "Fehler beim löschen der Stop Info",
    },
    file_import: "Datei hochladen",
    rona_import: "Aus RONA importieren",
    routes: "Routen",
  },
  route_details: {
    material: "Fraktion",
    tour_number: "Tour",
    stop_infos: {
      delete_dialog: {
        head: "Info löschen",
        body: "Wollen Sie die Info wirklich löschen?",
      },
    },
  },
  completed_routes_overview: {
    title: "Gefahrene Routen",
    filter: {
      tour_number: "Tournummer",
      tour_name: "Tourname",
      from_date: "Von",
      to_date: "Bis",
      driver_name: "Fahrername",
    },
    cards: {
      tour_number: "Tournummer: {{tourNumber}}",
      count: "Anzahl: {{count}}",
    },
    table: {
      tour_number: "Tournummer",
      tour_name: "Tourname",
      material: "Fraktion",
      start_date: "Start der Route",
      end_date: "Ende der Route",
      driver_name: "Fahrer",
      progress: "Fortschritt",
    },
  },
  driver_location_management: {
    create: {
      info: "Neuer Fahrer Standort",
      success: "Fahrer Standort erfolgreich erstellt.",
      error: "Fahrer Standort konnte nicht erstellt werden.",
    },
    update: {
      info: "Update Fahrer Standort",
      success: "Fahrer Standort erfolgreich aktualisiert.",
      error: "Fahrer Standort konnte nicht aktualisiert werden.",
    },
    delete: {
      dialog: {
        head: "Fahrer Standort löschen",
        body: "Wollen Sie den Fahrer Standort wirklich löschen?",
      },
      info: "Lösche Fahrer Standort",
      success: "Fahrer Standort erfolgreich gelöscht.",
      error: "Fahrer Standort konnte nicht gelöscht werden.",
    },
    driver_location: {
      title: "Fahrer Standorte",
      data: {
        name: "Name",
        name_required: "Name fehlt",
      },
    },
    action: {
      create: "Erstellen",
      save: "Speichern",
      cancel: "Abbrechen",
    },
    filter_driver_location: "Routen für Fahrer Standort filtern:",
  },
  material_management: {
    create: {
      info: "Neue Fraktion",
      success: "Fraktion erfolgreich erstellt.",
      error: "Fraktion konnte nicht erstellt werden.",
    },
    update: {
      info: "Update Fraktion",
      success: "Fraktion erfolgreich aktualisiert.",
      error: "Fraktion konnte nicht aktualisiert werden.",
    },
    delete: {
      dialog: {
        head: "Fraktion löschen",
        body: "Wollen Sie die Fraktion wirklich löschen?",
      },
      info: "Lösche Fraktion",
      success: "Fraktion erfolgreich gelöscht.",
      error: "Fraktion konnte nicht gelöscht werden.",
    },
    material: {
      title: "Fraktionen",
      data: {
        name: "Name",
        name_required: "Name fehlt",
        name_too_short: "Name muss mindestens {{length}} Buchstaben lang sein",
      },
    },
    action: {
      create: "Erstellen",
      save: "Speichern",
      cancel: "Abbrechen",
    },
  },
  user_role: {
    guidance_admin: "Nachfahrbarkeitsadmin",
    guided_driver: "Nachfahrer",
    super_admin: "Super Admin",
    all: "Alle",
  },
  user_management: {
    heading: "Benutzerverwaltung",
    please_select_user: "Bitte wählen Sie einen Benutzer aus",
    data: {
      nothing_assigned: "Es sind Ihnen keine {{collection}} zugewiesen",
      title: "Benutzerdaten von {{firstName}} {{lastName}}",
      first_name: "Vorname",
      last_name: "Nachname",
      username: "Benutzername",
      role: "Rolle",
      password: "Passwort",
      default_driver_location: "Standardstandort",
      partner: "Partner",
      create_user: "Anlegen",
      save_user: "Speichern",
      delete_user: "Löschen",
      assign: "{{collection}} Zuweisen",
      password_changed: "Passwort wurde geändert",
      user_created: "Benutzer wurde erstellt",
      user_updated: "Benutzer wurde editiert",
      user_deleted: "Benutzer wurde gelöscht",
      could_not_create_user: "Benutzer konnte nicht erstellt werden",
      could_not_update_user: "Benutzer konnte nicht editiert werden",
      could_not_delete_user: "Benutzer konnte nicht gelöscht werden",
      delete_dialog: {
        heading: "Löschen",
        confirm_text: "Löschen",
        text: "Möchten Sie diesen Benutzer wirklich löschen?",
      },
    },
    new_user: {
      title: "Benutzer anlegen",
    },
    filter: {
      heading: "Filter",
      first_name: "Vorname",
      last_name: "Nachname",
      role: "Rolle",
      apply_filter: "Filter anwenden",
      remove_filter: "Filter entfernen",
    },
  },
  location_type: {
    location: "Fahrer Standort",
    location_plural: "Fahrer Standorte",
  },
  material_select: {
    title: "Fraktionen",
    none: "kein Filter",
    error: "Beim Laden der Fraktionen ist ein Fehler aufgetreten",
  },
  material_multi_select: {
    label: "Fraktionen",
  },
};
