import { ThemeProvider } from "@material-ui/core";
import React, { Fragment, FunctionComponent } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDefaultThemeQuery } from "../api/nachfahrbarkeit-api/generated";
import { ROUTES } from "../router/router";
import { getTheme } from "../theme/theme";

export const App: FunctionComponent = () => {
  const { data, loading } = useDefaultThemeQuery();
  const theme = data ? getTheme(data.defaultTheme) : undefined;

  return (
    <>
      {loading && <Fragment />}
      {!loading && theme && (
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path={ROUTES.LOGIN.PATH}>
              <ROUTES.LOGIN.COMPONENT />
            </Route>
            <Route path={ROUTES.PORTAL.PATH}>
              <ROUTES.PORTAL.COMPONENT />
            </Route>
            <Redirect to={ROUTES.LOGIN.PATH} />
          </Switch>
        </ThemeProvider>
      )}
    </>
  );
};
