import React, { ChangeEvent, FunctionComponent } from "react";
import { Grid, TextField, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Dropzone } from "../../dropzone/dropzone";
import { Cancel } from "@material-ui/icons";
import { useImportRoutesContext } from "./import-route-context";

interface IImportRouteDropZoneProps {}

export const ImportRouteDropZone: FunctionComponent<IImportRouteDropZoneProps> = (props) => {
  const { t } = useTranslation();

  const { files, setFiles } = useImportRoutesContext();

  const handleChangeTourName = (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const copy = files.slice();
    copy[index].tourName = event.target.value ?? "";
    setFiles(copy);
  };

  const handleChangeTourNumber = (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const copy = files.slice();
    copy[index].tourNumber = parseInt(event.target.value, 10) || 0;
    setFiles(copy);
  };

  const handleRemoveFile = (index: number) => {
    const copy = files.slice();
    copy.splice(index, 1);
    setFiles(copy);
  };

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Dropzone
          accept={[".csv", ".gpx"]}
          text={t("routes_management.dropzone_text")}
          updateFiles={(newFiles) =>
            setFiles(
              newFiles.map((file) => ({
                tourName: file.name ?? "",
                tourNumber: 0,
                file,
              })),
            )
          }
          files={files.map((file) => file.file)}
        />
      </Grid>
      <Grid item container direction="column">
        {files.map((file, index) => (
          <Grid item container direction="row" alignItems="center" key={`${file.file.name}_${index}`} spacing={1}>
            <Grid item container spacing={1} xs>
              <Grid item xs={6}>
                <TextField
                  label={t("routes_management.filename")}
                  type="search"
                  fullWidth
                  variant="outlined"
                  value={file.file.name}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={t("routes_management.name")}
                  type="search"
                  fullWidth
                  required
                  variant="outlined"
                  value={file.tourName}
                  onChange={handleChangeTourName(index)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={t("routes_management.tour_number")}
                  type="number"
                  inputProps={{ min: 0 }}
                  fullWidth
                  required
                  variant="outlined"
                  value={file.tourNumber}
                  onChange={handleChangeTourNumber(index)}
                />
              </Grid>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleRemoveFile(index)}>
                <Cancel color="error" />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
