import { Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ImportRouteButton } from "../../../components/buttons/import-route/import-route-button";
import { PageHeading } from "../../../components/heading/page-heading";
import { useRoutesOverviewContext } from "../../../context/routes-overview-context";
import { RoutesFilter } from "./routes-filter";
import { RoutesTable } from "./routes-table";

interface IRoutesOverviewProps {}

export const RoutesOverview: FunctionComponent<IRoutesOverviewProps> = (props) => {
  const { t } = useTranslation();

  const { filters, setFilters, routesRows, routes, loading, error } = useRoutesOverviewContext();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageHeading>{t("routes_management.title")}</PageHeading>
        </Grid>
        <Grid item>
          <ImportRouteButton routes={routes} />
        </Grid>
      </Grid>

      <Grid item>
        <RoutesFilter
          materialId={filters.materialId}
          onMaterialIdChange={(newMaterialId) => setFilters({ materialId: newMaterialId })}
        />
        <RoutesTable routes={routesRows} loading={loading} error={!!error} />
      </Grid>
    </Grid>
  );
};
