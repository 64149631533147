// eslint-disable-next-line
export const sortASC = (a: any, b: any): number => {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
};
