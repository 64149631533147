import { CircularProgress, Grid, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import classnames from "classnames";
import lodash from "lodash";
import React, { Fragment, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../context/user-context";
import { Badge } from "../badge/badge";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(2),
    height: 96,
  },
  badge: {
    display: "flex",
    justifyContent: "center",
  },
  item: {
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  name: {
    fontWeight: 600,
  },
  username: {
    fontSize: 14,
  },
  icon: { color: "white" },
}));

interface IUserInfoProps {
  sidebarCollapsed: boolean;
}

export const UserInfo: FunctionComponent<IUserInfoProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sidebarCollapsed } = props;
  const [isFullnameOverflowing, setIsFullnameOverflowing] = useState(false);
  const [isUsernameOverflowing, setIsUsernameOverflowing] = useState(false);
  const { user, userLoading } = useUserContext();

  const fullname = [user?.first_name, user?.last_name].filter((namePart) => !!namePart).join(" ");

  return (
    <Grid container direction="column" className={classes.container} justify="center" alignContent="center">
      {sidebarCollapsed && (
        <Grid item>
          <Person className={classes.icon} />
        </Grid>
      )}
      {!sidebarCollapsed && userLoading && (
        <Grid
          container
          direction="row"
          justify="center"
          alignContent="center"
          spacing={2}
          className={classes.container}
        >
          <Grid item className={classes.icon}>
            <Person />
          </Grid>
          <Grid item>
            <CircularProgress size={24} />
          </Grid>
        </Grid>
      )}
      {!sidebarCollapsed && (
        <Fragment>
          <Tooltip
            PopperProps={{
              style: {
                visibility: isFullnameOverflowing ? "visible" : "hidden",
              },
            }}
            placement="top"
            title={fullname}
          >
            <Grid
              item
              className={classnames(classes.item, classes.name)}
              ref={(ref) => setIsFullnameOverflowing(lodash.isNil(ref) ? false : ref.offsetWidth < ref.scrollWidth)}
            >
              {fullname}
            </Grid>
          </Tooltip>
          <Tooltip
            PopperProps={{
              style: {
                visibility: isUsernameOverflowing ? "visible" : "hidden",
              },
            }}
            placement="top"
            title={user?.username || ""}
          >
            <Grid
              item
              className={classnames(classes.item, classes.username)}
              ref={(ref) => setIsUsernameOverflowing(lodash.isNil(ref) ? false : ref.offsetWidth < ref.scrollWidth)}
            >
              {user?.username || ""}
            </Grid>
          </Tooltip>
          {user?.role && (
            <Grid item className={classes.badge}>
              <Badge color="primary" fontSize={12}>
                {t(`user_role.${user?.role.toLowerCase()}`)}
              </Badge>
            </Grid>
          )}
        </Fragment>
      )}
    </Grid>
  );
};
