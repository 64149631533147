import { TextField } from "@material-ui/core";
import React, { VFC } from "react";

interface ISimpleTextFieldProps {
  label: string;
  value: string;
  required?: boolean;
  error?: boolean;
  className?: string;
  color?: "primary" | "secondary";
  fullWidth?: boolean;
  variant?: "standard" | "outlined" | "filled";
  onChange: (newValue: string) => void;
}

export const SimpleTextField: VFC<ISimpleTextFieldProps> = (props) => {
  const { label, value, onChange, required, error, className, color, fullWidth = true, variant = "outlined" } = props;

  return (
    <TextField
      label={label}
      className={className}
      color={color}
      value={value}
      required={required}
      fullWidth={fullWidth}
      variant={variant}
      error={error}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
