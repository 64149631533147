import React, { FunctionComponent } from "react";
import { Theme, makeStyles, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Menu } from "@material-ui/icons";
import { Text } from "../../../components/text/text";

const useStyles = makeStyles((theme: Theme) => ({
  emptyState: {
    height: "100%",
    width: "100%",
  },
}));

interface IUserManagementEmptyStateProps {}

export const UserManagementEmptyState: FunctionComponent<IUserManagementEmptyStateProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item xs={12} className={classes.emptyState}>
      <Grid
        spacing={2}
        container
        className={classes.emptyState}
        justify="center"
        alignItems="center"
        alignContent="center"
        direction="row"
      >
        <Grid item xs="auto">
          <Grid container>
            <Menu color="secondary" />
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Text color="secondary" align="center">
            {t("user_management.please_select_user")}
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
