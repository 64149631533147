import { DatePicker } from "@material-ui/pickers";
import { DateTime } from "luxon";
import React, { VFC } from "react";
import { useTranslation } from "react-i18next";

interface ISimpleDatePickerProps {
  value: DateTime | null;
  label?: string;
  required?: boolean;
  minDate?: DateTime | null;
  maxDate?: DateTime | null;
  disablePast?: boolean;
  disableFuture?: boolean;
  fullWidth?: boolean;
  className?: string;
  onChange: (newDate: DateTime | null) => void;
}

export const SimpleDatePicker: VFC<ISimpleDatePickerProps> = (props) => {
  const {
    label,
    value,
    required,
    minDate,
    maxDate,
    disablePast,
    disableFuture,
    fullWidth = true,
    className,
    onChange,
  } = props;
  const { t } = useTranslation();

  return (
    <DatePicker
      label={label}
      value={value}
      required={required}
      minDate={minDate}
      maxDate={maxDate}
      fullWidth={fullWidth}
      inputVariant="outlined"
      disablePast={disablePast}
      disableFuture={disableFuture}
      className={className}
      format={t("date_time.date_format")}
      onChange={onChange}
    />
  );
};
