import { Polyline } from "@react-google-maps/api";
import lodash from "lodash";
import React, { Fragment, FunctionComponent, useContext, useState } from "react";
import { EventType, RouteStop, RouteStopEvent } from "../../../api/nachfahrbarkeit-api/generated";
import { CustomMap, IMarker, MarkerType } from "../../../components/maps/custom-map";
import { RouteContext } from "./route-context";
import { RouteDetailsMapInfoWindow } from "./route-details-map-info-window";

interface LatLong {
  lat: number;
  lng: number;
}

interface PolygonlineCoords {
  coords: LatLong[];
  color: PolygonlineColor;
}

enum PolygonlineColor {
  BLACK = "#000000",
  RED = "#ff0000",
}

const fallBackMarker: IMarker[] = [
  { id: 0, lat: 37.24054452695688, lng: -115.81196378347039, sequenceNumber: 0, doNotDisplayPin: true },
];
interface IRouteDetailsMapProps {}

export const RouteDetailsMap: FunctionComponent<IRouteDetailsMapProps> = (props) => {
  const { route } = useContext(RouteContext);
  const [selectedStop, setSelectedStop] = useState<number>();

  const getMarkers = () => {
    const stopsWithBulks =
      route?.stops.filter((stop) => stop.events?.find((event) => event.event_type === EventType.bulk)) ?? [];
    const firstBulk = lodash.minBy(stopsWithBulks, (stop) => stop.sequence_number)?.sequence_number;
    const lastBulk = lodash.maxBy(stopsWithBulks, (stop) => stop.sequence_number)?.sequence_number;
    const mappedMarkers = route?.stops.map((stop) => {
      const marker: IMarker = {
        id: Number(stop.id),
        lat: stop.latitude,
        lng: stop.longitude,
        sequenceNumber: stop.sequence_number,
        type: getMarkerType(stop, firstBulk, lastBulk),
      };

      return marker;
    });
    const result = mappedMarkers as IMarker[];
    return result.length ? result : fallBackMarker;
  };

  const getMarkerType = (
    stop: RouteStop,
    sequenceNumberOfFirstBulk?: number,
    sequenceNumberOfLastBulk?: number,
  ): MarkerType => {
    if (sequenceNumberOfLastBulk != null && stop.sequence_number === sequenceNumberOfLastBulk) return "end";
    if (sequenceNumberOfFirstBulk != null && stop.sequence_number === sequenceNumberOfFirstBulk) return "start";
    if (!hasStopRelevantInfo(stop)) return "circle";
    return "default";
  };

  const hasStopRelevantInfo = (stop: RouteStop) => {
    return stop.events && stop.events.some((event) => event.event_type === EventType.bulk && event.info);
  };

  const renderCustomInfoWindow = (id: number) => {
    const stop = route?.stops.find((stop) => Number(stop.id) === id);
    if (!stop) {
      return "";
    }

    return <RouteDetailsMapInfoWindow stop={stop} />;
  };

  const getPrioritizedStopEvents = (events: RouteStopEvent[]) => {
    if (!events.length) {
      return 0;
    }

    const prioritizedStopEvents = events.sort((e1, e2) => {
      if (e1.event_type === e2.event_type) {
        return 0;
      }

      return e1.event_type === EventType.backwards ? -1 : 1;
    });

    return prioritizedStopEvents;
  };

  const renderCustomPolylines = () => {
    const stops = route?.stops ?? [];
    const coords: PolygonlineCoords[] = [];
    for (let i = 0; i < stops.length; i++) {
      const currentStop = stops[i];
      if (!currentStop?.events) {
        break;
      }
      const currentStopPrioritizedStopEvents = getPrioritizedStopEvents(currentStop.events);
      const currentType = currentStopPrioritizedStopEvents === 0 ? 0 : currentStopPrioritizedStopEvents[0].event_type;
      const lineCoords: PolygonlineCoords = {
        color: currentType === EventType.backwards ? PolygonlineColor.RED : PolygonlineColor.BLACK,
        coords: [],
      };

      lineCoords.coords.push({ lat: currentStop.latitude, lng: currentStop.longitude });
      for (let y = i + 1; y < stops.length; y++) {
        const nextStop = stops[y];
        if (!nextStop?.events) break;
        const nextStopPrioritizedStopEvents = getPrioritizedStopEvents(nextStop.events);
        const nextStopType = nextStopPrioritizedStopEvents === 0 ? 0 : nextStopPrioritizedStopEvents[0].event_type;
        lineCoords.coords.push({ lat: nextStop.latitude, lng: nextStop.longitude });

        if (currentType !== nextStopType) {
          break;
        }
        i = i + 1;
      }

      coords.push(lineCoords);
    }

    return (
      <Fragment>
        {coords.map((data) => {
          return (
            <Polyline
              path={data.coords}
              options={{
                strokeColor: data.color,
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          );
        })}
      </Fragment>
    );
  };

  const markers = getMarkers();
  return (
    <CustomMap
      height="400px"
      onMarkerClicked={(id: number) => {
        setSelectedStop(id);
      }}
      markers={markers}
      selectedMarkerId={selectedStop}
      displayPolyline={true}
      doRenderCustomInfoWindow={true}
      renderCustomInfoWindow={renderCustomInfoWindow}
      doRenderCustomPolylines={true}
      renderCustomPolylines={renderCustomPolylines}
      disableZoom={true}
      disableFitBounds={true}
    />
  );
};
