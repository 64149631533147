import { IconButton, TableCell } from "@material-ui/core";
import { Delete, Map } from "@material-ui/icons";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DeleteRouteDialog } from "../../../components/dialogs/delete-route/delete-route-dialog";
import { ISimpleTableHeader, SimpleTable } from "../../../components/table/simple-table";
import { ROUTE_DETAILS_ROUTE_ITEM } from "../../../router/router";

export interface IRouteRow {
  id: string;
  tourNumber: number;
  tourName: string;
  filename: string;
  date: string;
  materialName: string;
}

interface IRoutesTableProps {
  routes: IRouteRow[];
  loading?: boolean;
  error?: boolean;
}

enum TableHeaders {
  Id = "Id",
  Filename = "Filename",
  TourName = "TourName",
  TourNumber = "TourNumber",
  MaterialName = "MaterialName",
  Date = "Date",
  Edit = "Edit",
  Delete = "Delete",
}

export const RoutesTable: FunctionComponent<IRoutesTableProps> = (props) => {
  const { t } = useTranslation();
  const { routes, loading, error } = props;

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const history = useHistory();

  const deleteClicked = async (id: string) => {
    setCurrentId(id);
    setDeleteDialogOpen(true);
  };

  const editClicked = (id: string) => {
    const path = ROUTE_DETAILS_ROUTE_ITEM.PATH.replace(":routeId", id);
    history.push(path);
  };

  const closeDeleteRouteDialog = () => {
    setDeleteDialogOpen(false);
    setCurrentId(null);
  };

  const renderEditColumn = (item: IRouteRow) => {
    return (
      <TableCell align="center">
        <IconButton onClick={() => editClicked(item.id)}>
          <Map />
        </IconButton>
      </TableCell>
    );
  };

  const renderDeleteColumn = (item: IRouteRow) => {
    return (
      <TableCell align="center">
        <IconButton onClick={() => deleteClicked(item.id)}>
          <Delete />
        </IconButton>
      </TableCell>
    );
  };

  const headers: ISimpleTableHeader<TableHeaders, IRouteRow>[] = [
    { id: TableHeaders.Id, title: t("routes_management.table.id"), key: "id" },
    { id: TableHeaders.TourNumber, title: t("routes_management.table.tour_number"), key: "tourNumber" },
    { id: TableHeaders.Filename, title: t("routes_management.table.filename"), key: "filename" },
    { id: TableHeaders.TourName, title: t("routes_management.table.name"), key: "tourName" },
    { id: TableHeaders.MaterialName, title: t("routes_management.table.material"), key: "materialName" },
    { id: TableHeaders.Date, title: t("routes_management.table.date"), key: "date" },
    { id: TableHeaders.Edit, title: "", key: "id", renderColumn: renderEditColumn },
    { id: TableHeaders.Delete, title: "", key: "id", renderColumn: renderDeleteColumn },
  ];

  return (
    <>
      <DeleteRouteDialog routeId={currentId ?? ""} open={deleteDialogOpen} onClose={() => closeDeleteRouteDialog()} />
      <SimpleTable headers={headers} items={routes} loading={loading} error={error} />
    </>
  );
};
