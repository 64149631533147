import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useGetMaterialsQuery } from "../../../api/nachfahrbarkeit-api/generated";
import { SimpleSelect } from "../simple-select";

interface IMaterialSelectProps {
  name?: string;
  materialId?: string;
  onChange: (materialId: string) => void;
  allowEmpty?: boolean;
}

export const MaterialSelect: FunctionComponent<IMaterialSelectProps> = (props) => {
  const { t } = useTranslation();
  const { name, materialId, onChange, allowEmpty } = props;

  const { data: materialsData, loading: materialsDataLoading, error: materialsError } = useGetMaterialsQuery();

  if (materialsError) {
    toast.error(t("material_select.error"));
    return <></>;
  }

  if (materialsDataLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Skeleton height={50} width="100%" />
      </Grid>
    );
  }

  const materials = materialsData
    ? materialsData.getMaterials.map((material) => ({
        value: material.id,
        title: material.name,
      }))
    : [];
  if (allowEmpty) {
    materials.push({ title: t("material_select.none"), value: "" });
  }

  return (
    <SimpleSelect
      options={materials}
      name={name || t("material_select.title")}
      onChange={onChange}
      value={materialId ?? ""}
    />
  );
};
