import React, { ChangeEvent, FunctionComponent, useCallback, useMemo } from "react";
import { Grid, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { RonaRoute, useGetRoutesFromRonaQuery } from "../../../api/nachfahrbarkeit-api/generated";
import { useImportRoutesContext } from "./import-route-context";

interface IRonaImportContentProps {}

export const RonaImportContent: FunctionComponent<IRonaImportContentProps> = (props) => {
  const { t } = useTranslation();

  const { ronaRoute, setRonaRoute } = useImportRoutesContext();

  const { data, loading } = useGetRoutesFromRonaQuery({ fetchPolicy: "network-only" });

  const updateSelectedRoute = useCallback(
    (route: RonaRoute | null) => {
      if (route === null) {
        setRonaRoute(null);
      } else {
        const newTourName = route.filename.split(".")[0];
        let newTourNumber = Number(newTourName?.split("-")[0]);
        if (isNaN(newTourNumber)) {
          newTourNumber = 0;
        }
        setRonaRoute({
          filename: route.filename,
          tourName: newTourName,
          tourNumber: newTourNumber,
        });
      }
    },
    [setRonaRoute],
  );

  const sortRoutes = useCallback((a: RonaRoute, b: RonaRoute) => {
    if (a.imported && !b.imported) {
      return 1;
    }
    if (b.imported && !a.imported) {
      return -1;
    }
    return a.filename.localeCompare(b.filename);
  }, []);

  const options: RonaRoute[] = useMemo(() => {
    return data?.getRoutesFromRona.sort(sortRoutes) || [];
  }, [data, sortRoutes]);

  const selectedRoute: RonaRoute | null = useMemo(() => {
    if (ronaRoute === null) {
      return null;
    }
    return options.find((o) => o.filename === ronaRoute.filename) || null;
  }, [ronaRoute, options]);

  const handleChangeTourName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (ronaRoute) {
      setRonaRoute({ ...ronaRoute, tourName: event.target.value ?? "" });
    }
  };

  const handleChangeTourNumber = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (ronaRoute) {
      setRonaRoute({ ...ronaRoute, tourNumber: parseInt(event.target.value, 10) || 0 });
    }
  };

  return (
    <Grid item container spacing={2}>
      {loading ? (
        <Grid container justifyContent="center" alignItems="center">
          <Skeleton height={50} width="100%" />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Autocomplete<RonaRoute>
              options={options}
              value={selectedRoute}
              getOptionDisabled={(option) => option.imported}
              getOptionLabel={(route) => route.filename}
              onChange={(_event, newValue) => updateSelectedRoute(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  margin="none"
                  fullWidth
                  label={t("routes_management.routes")}
                />
              )}
            />
          </Grid>
          {ronaRoute && (
            <>
              <Grid item xs={6}>
                <TextField
                  label={t("routes_management.name")}
                  type="search"
                  fullWidth
                  required
                  variant="outlined"
                  value={ronaRoute.tourName}
                  onChange={handleChangeTourName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t("routes_management.tour_number")}
                  type="number"
                  inputProps={{ min: 0 }}
                  fullWidth
                  required
                  variant="outlined"
                  value={ronaRoute.tourNumber}
                  onChange={handleChangeTourNumber}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};
