import React from "react";
import { Grid } from "@material-ui/core";
import { SimpleEditItem } from "./simple-edit-item";
import lodash from "lodash";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

interface SimpleEditListProps<T> {
  items: T[];
  loading?: boolean;
  error?: boolean;
  getIdentifier: (item: T) => string;
  onEdit?: (item: T) => void;
  onRemove?: (item: T) => void;
}

export const SimpleEditList = <T,>(props: SimpleEditListProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const { items, loading, error, getIdentifier, onEdit, onRemove } = props;

  return (
    <Grid container direction="column" spacing={1}>
      {loading && lodash.range(0, 3).map((_) => <Skeleton height="100px" />)}
      {!loading && error && (
        <Grid item style={{ textAlign: "center" }}>
          {t("errors.fetch_error")}
        </Grid>
      )}
      {!loading && !error && items.length <= 0 && (
        <Grid item style={{ textAlign: "center" }}>
          {t("errors.empty")}
        </Grid>
      )}
      {items.map((item, index) => (
        <SimpleEditItem
          key={`${getIdentifier(item)}_${index}`}
          item={item}
          getIdentifier={getIdentifier}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      ))}
    </Grid>
  );
};
