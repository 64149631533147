import React, { FunctionComponent, useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";

export interface IImage {
  url: string;
  title: string | null;
  caption: string | null;
}

interface ICustomLightBoxProps {
  images: IImage[];
  onClose: () => void;
}

export const CustomLightBox: FunctionComponent<ICustomLightBoxProps> = (props) => {
  const { images, onClose } = props;

  const [imageIndex, setImageIndex] = useState<number>(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Lightbox
      mainSrc={images[imageIndex]?.url}
      nextSrc={images.length > 1 ? images[imageIndex % images.length].url || "invalid" : undefined}
      prevSrc={
        images.length > 1 ? images[(imageIndex + images.length - 1) % images.length].url || "invalid" : undefined
      }
      onCloseRequest={onClose}
      onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}
      onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
      reactModalStyle={{ overlay: { zIndex: 1300 } }}
      imageTitle={images[imageIndex]?.title}
      imageCaption={images[imageIndex]?.caption}
    />
  );
};
