import React, { createContext, useContext, useState } from "react";
import { useGetMeLazyQuery, User } from "../api/nachfahrbarkeit-api/generated";

interface IUserContextType {
  user: User | undefined;
  resetUser: () => void;
  userLoading: boolean;
  loadUser: () => void;
}

interface IUserProps {
  children: JSX.Element;
}

const UserContext = createContext<IUserContextType>({} as IUserContextType);

export const UserProvider = (props: IUserProps): JSX.Element => {
  const value = useUserProvider(props);
  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};

export const useUserContext = (): IUserContextType => {
  return useContext(UserContext);
};

const useUserProvider = (props: IUserProps): IUserContextType => {
  const [user, setUser] = useState<User | undefined>();
  const [loadUser, { loading: isUserLoading }] = useGetMeLazyQuery({
    onCompleted: (d) => setUser(d.getMe),
  });
  const resetUser = () => {
    setUser(undefined);
  };

  return {
    user,
    userLoading: isUserLoading,
    resetUser,
    loadUser,
  };
};
