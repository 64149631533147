import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { DriverLocationData } from "./driver-location-data";
import { DriverLocationSelection } from "./driver-location-selection";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  dataContainer: {
    padding: theme.spacing(2),
  },
  container: {
    height: "calc(100vh - 56px)",
  },
  selectorContainer: {
    flex: "1",
    overflowY: "auto",
  },
}));

interface IDriverLocationAdministrationProps {}

export const DriverLocationAdministration: FunctionComponent<IDriverLocationAdministrationProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={2} className={classes.container}>
      <Grid container item direction="row" spacing={2} className={classes.dataContainer}>
        <Grid item xs>
          <DriverLocationData />
        </Grid>
      </Grid>
      <Grid item>
        <Typography className={classes.title}>{t("driver_location_management.driver_location.title")}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.selectorContainer}>
        <DriverLocationSelection />
      </Grid>
    </Grid>
  );
};
