import { Grid } from "@material-ui/core";
import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useGetMaterialsQuery, useRemoveMaterialMutation } from "../../../api/nachfahrbarkeit-api/generated";
import { ConfirmDialog } from "../../../components/dialogs/confirm-dialog";
import { SimpleEditList } from "../../../components/lists/simple-edit-list/simple-edit-list";
import { Text } from "../../../components/text/text";
import { useMaterialManagementContext } from "../../../context/material-management-context";
import { useRefetchContext } from "../../../context/refetch-context";

interface IMaterialSelectionProps {}

export const MaterialSelection: VFC<IMaterialSelectionProps> = (props) => {
  const { t } = useTranslation();
  const { needToRefetch, setNeedToRefetch } = useRefetchContext();
  const { setSelectedMaterial } = useMaterialManagementContext();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [materialIdToRemove, setMaterialIdToRemove] = useState<string | undefined>(undefined);

  const {
    data: getMaterialsData,
    loading: getMaterialsLoading,
    error: getMaterialsError,
  } = useGetMaterialsQuery({
    fetchPolicy: needToRefetch ? "network-only" : "cache-first",
    onCompleted: () => setNeedToRefetch(false),
  });

  const [removeMaterial, { loading: removeMaterialLoading }] = useRemoveMaterialMutation({
    onCompleted: (data) => {
      if (data?.removeMaterial.error !== false) {
        toast.error(t("material_management.delete.error"));
        return;
      }
      toast.success(t("material_management.delete.success"));

      setNeedToRefetch(true);
      setDeleteDialogOpen(false);
      setSelectedMaterial(undefined);
    },
  });

  const onRemove = async () => {
    if (materialIdToRemove == null) return;
    await removeMaterial({
      variables: {
        materialId: materialIdToRemove,
      },
    });
  };

  return (
    <Grid container direction="column" spacing={1}>
      <ConfirmDialog
        open={deleteDialogOpen}
        heading={t("material_management.delete.dialog.head")}
        confirmText={t("delete_dialog.yes")}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={onRemove}
        loading={removeMaterialLoading}
      >
        <Text>{t("material_management.delete.dialog.body")}</Text>
      </ConfirmDialog>

      <SimpleEditList
        items={getMaterialsData?.getMaterials ?? []}
        loading={getMaterialsLoading}
        error={!!getMaterialsError}
        getIdentifier={(material) => material.name}
        onEdit={setSelectedMaterial}
        onRemove={(material) => {
          setMaterialIdToRemove(material.id);
          setDeleteDialogOpen(true);
        }}
      />
    </Grid>
  );
};
