import React, { FunctionComponent } from "react";
import { Theme, makeStyles } from "@material-ui/core";
import classnames from "classnames";

interface IStyleProps {
  color: "primary" | "secondary";
  fontSize?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    border: (props: IStyleProps) => `2px solid ${theme.palette[props.color].main}`,
    color: (props: IStyleProps) => theme.palette[props.color].main,
    fontSize: (props: IStyleProps) => props.fontSize,
    borderRadius: 4,
    padding: 1,
    fontWeight: "bold",
    width: "fit-content",
    textTransform: "uppercase",
  },
}));

interface IBadgeProps {
  color?: "primary" | "secondary";
  fontSize?: number;
  className?: string;
}

export const Badge: FunctionComponent<IBadgeProps> = (props) => {
  const { children, color = "primary", fontSize, className } = props;
  const classes = useStyles({ color, fontSize });
  return <div className={classnames(classes.badge, className)}>{children}</div>;
};
