// import { DashboardPage } from "./../pages/dashboard/dashboard";
import { PortalPage } from "../pages/portal/portal-page";
import { RouteManagementPage } from "../pages/route-management/route-management";
import { FunctionComponent } from "react";
import { LoginPage } from "../pages/login/login-page";
import { UserRole } from "../services/user-service";
import { UsersManagementPage } from "../pages/user-management/users-management";
import { DriverLocationsManagementPage } from "../pages/driver-location-management/driver-location-management";
import { RouteDetailsPage } from "../pages/route-details/route-details-page";
import { MaterialsManagementPage } from "../pages/material-management/material-management";
import { CompletedRoutesOverviewPage } from "../pages/completed-routes-overview/completed-routes-overview-page";

export interface IRouteItem {
  PATH: string;
  COMPONENT: FunctionComponent;
  // not shown in sidebar if either is undefined
  I18NKEY?: string;
  ICON?: string;
  SUBROUTES?: IRouteItem[];
  ROLES: UserRole[];
}

// maybe needed in future
// const dashboardRouteItem: IRouteItem = {
//   PATH: "/portal/dashboard",
//   COMPONENT: DashboardPage,
//   I18NKEY: "dashboard.page_title",
//   ICON: "dashboard",
//   ROLES: [UserRole.SUPER_ADMIN, UserRole.GUIDANCE_ADMIN, UserRole.GUIDED_DRIVER],
// };

export const ROUTE_DETAILS_ROUTE_ITEM: IRouteItem = {
  PATH: "/portal/route/:routeId",
  COMPONENT: RouteDetailsPage,
  ROLES: [UserRole.SUPER_ADMIN, UserRole.GUIDANCE_ADMIN],
};

export const ROUTE_MANAGEMENT_ROUTE_ITEM: IRouteItem = {
  PATH: "/portal/routes",
  COMPONENT: RouteManagementPage,
  I18NKEY: "routes.routes_basedata",
  ICON: "navigation",
  ROLES: [UserRole.SUPER_ADMIN, UserRole.GUIDANCE_ADMIN],
  SUBROUTES: [ROUTE_DETAILS_ROUTE_ITEM],
};

export const COMPLETED_ROUTES_OVERVIEW_ROUTE_ITEM: IRouteItem = {
  PATH: "/portal/completed-routes",
  COMPONENT: CompletedRoutesOverviewPage,
  I18NKEY: "completed_routes_overview.title",
  ICON: "checkmark",
  ROLES: [UserRole.SUPER_ADMIN, UserRole.GUIDANCE_ADMIN],
};

export const ADMINISTRATION_ROUTE_ITEM: IRouteItem = {
  PATH: "/portal/administration/users",
  COMPONENT: UsersManagementPage,
  I18NKEY: "administration.administration",
  ICON: "tune",
  ROLES: [UserRole.SUPER_ADMIN, UserRole.GUIDANCE_ADMIN],
  SUBROUTES: [
    {
      PATH: "/portal/administration/users",
      COMPONENT: UsersManagementPage,
      I18NKEY: "administration.users",
      ICON: "people",
      ROLES: [UserRole.SUPER_ADMIN, UserRole.GUIDANCE_ADMIN],
    },
    {
      PATH: "/portal/administration/locations",
      COMPONENT: DriverLocationsManagementPage,
      I18NKEY: "administration.driver_locations",
      ICON: "edit_location_alt",
      ROLES: [UserRole.SUPER_ADMIN],
    },
    {
      PATH: "/portal/administration/materials",
      COMPONENT: MaterialsManagementPage,
      I18NKEY: "administration.materials",
      ICON: "category",
      ROLES: [UserRole.SUPER_ADMIN],
    },
  ],
};

export const ROUTES = {
  PORTAL: {
    PATH: "/portal",
    COMPONENT: PortalPage,
    ROUTES: {
      // DASHBOARD: dashboardRouteItem,
      ROUTE_MANAGEMENT: ROUTE_MANAGEMENT_ROUTE_ITEM,
      COMPLETED_ROUTES_OVERVIEW: COMPLETED_ROUTES_OVERVIEW_ROUTE_ITEM,
      ADMINISTRATION: ADMINISTRATION_ROUTE_ITEM,
    },
  },
  LOGIN: {
    PATH: "/login",
    COMPONENT: LoginPage,
  },
};
