import { DateTime } from "luxon";
import React, { createContext, FC, useContext, useEffect, useState } from "react";
import { useGetCompletedRoutesQuery } from "../api/nachfahrbarkeit-api/generated";
import { IPagination } from "../components/table/pagination";
import { usePagination } from "../hooks/use-pagination";
import { ICompletedRouteRow } from "../pages/completed-routes-overview/partials/completed-routes-table";
import { useCompletedRoutesTableMapper } from "../pages/completed-routes-overview/partials/use-completed-routes-table-mapper";
import { useDriverLocation } from "./driver-location-filter-context";
import { useRefetchContext } from "./refetch-context";

interface ICompletedRoutesOverviewContext {
  filter: IFilter;
  setFilter: (newFilter: Partial<IFilter>) => void;
  pagination: IPagination;
  fetchCompletedRoutes: VoidFunction;
  loading: boolean;
  error: boolean;
  completedRoutesRows: ICompletedRouteRow[];
}

interface IFilter {
  materialIds: string[];
  from: DateTime | null;
  to: DateTime | null;
  tourNumber: string;
  tourName: string;
  driverName: string;
}

const useCompletedRoutesOverviewProvider = (): ICompletedRoutesOverviewContext => {
  const [filter, setFilter] = useState(defaultValue.filter);
  const { page, setPage, take, setTake } = usePagination(defaultValue.pagination.take);
  const { mappedCompletedRoutes, setCompletedRoutesQuery } = useCompletedRoutesTableMapper();

  const { needToRefetch, setNeedToRefetch } = useRefetchContext();
  const { driverLocationId } = useDriverLocation();

  const isLocationSet = (): boolean => !!driverLocationId?.length;

  const isFilterValid = (): boolean => {
    const isDateValid = (date: DateTime | null): boolean => !!date && date.isValid;
    const isTourNumberValid = (tourNumber: string): boolean =>
      tourNumber === "" || Number.isInteger(Number(tourNumber));

    return isDateValid(filter.from) && isDateValid(filter.to) && isTourNumberValid(filter.tourNumber);
  };

  const skipRefetch = () => {
    return !isLocationSet() || !isFilterValid();
  };

  const { data, loading, error, refetch } = useGetCompletedRoutesQuery({
    fetchPolicy: "network-only",
    variables: {
      locationId: driverLocationId ?? "",
      page,
      take,
      filters: {
        driverName: filter.driverName,
        from: filter.from?.startOf("day"),
        to: filter.to?.endOf("day"),
        materialIds: filter.materialIds,
        tourName: filter.tourName,
        tourNumber: filter.tourNumber === "" ? null : Number(filter.tourNumber),
      },
    },
    skip: skipRefetch(),
  });

  const handleFilterChange = (newFilter: Partial<IFilter>) => {
    setFilter({ ...filter, ...newFilter });
  };

  const getPaginationTotal = () => {
    return data?.getCompletedRoutes.total ?? 0;
  };

  useEffect(() => {
    if (error) {
      setCompletedRoutesQuery(undefined);
    } else {
      setCompletedRoutesQuery(data);
    }
  }, [data, error, setCompletedRoutesQuery]);

  useEffect(() => {
    if (needToRefetch) {
      refetch();
      setNeedToRefetch(false);
    }
  }, [refetch, needToRefetch, setNeedToRefetch]);

  return {
    filter,
    setFilter: handleFilterChange,
    pagination: {
      page,
      onPageChange: setPage,
      take,
      onTakeChange: setTake,
      total: getPaginationTotal(),
    },
    fetchCompletedRoutes: refetch,
    loading,
    error: !!error,
    completedRoutesRows: mappedCompletedRoutes,
  };
};

const defaultValue: ICompletedRoutesOverviewContext = {
  filter: {
    driverName: "",
    materialIds: [],
    tourName: "",
    tourNumber: "",
    from: DateTime.now().startOf("month"),
    to: DateTime.now().endOf("day"),
  },
  setFilter: () => {},
  pagination: {
    page: 0,
    take: 50,
    total: 0,
    onPageChange: () => {},
    onTakeChange: () => {},
  },
  fetchCompletedRoutes: () => {},
  loading: false,
  error: false,
  completedRoutesRows: [],
};

const CompletedRoutesOverviewContext = createContext<ICompletedRoutesOverviewContext>(defaultValue);

export const CompletedRoutesOverviewProvider: FC = ({ children }): JSX.Element => {
  const value = useCompletedRoutesOverviewProvider();
  return <CompletedRoutesOverviewContext.Provider value={value}>{children}</CompletedRoutesOverviewContext.Provider>;
};

export const useCompletedRoutesOverviewContext = (): ICompletedRoutesOverviewContext => {
  return useContext(CompletedRoutesOverviewContext);
};
