import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompletedRoute, GetCompletedRoutesQuery, Material } from "../../../api/nachfahrbarkeit-api/generated";
import { ICompletedRouteRow } from "./completed-routes-table";

interface IUseCompletedRoutesTableMapper {
  mappedCompletedRoutes: ICompletedRouteRow[];
  setCompletedRoutesQuery: (query?: GetCompletedRoutesQuery) => void;
}

type PartialCompletedRoute = Pick<
  CompletedRoute,
  "id" | "progress" | "startDate" | "endDate" | "tourName" | "tourNumber" | "driverName"
> & { material: Pick<Material, "id" | "name"> };

export const useCompletedRoutesTableMapper = (): IUseCompletedRoutesTableMapper => {
  const { t } = useTranslation();
  const [completedRoutesQuery, setCompletedRoutesQuery] = useState<GetCompletedRoutesQuery>();

  const formatDate = (date: string) => DateTime.fromISO(date).toFormat(t("date_time.date_time_format"));

  const convertCompletedRoutesToTableRow = (
    completedRoutes: GetCompletedRoutesQuery | undefined,
  ): ICompletedRouteRow[] => {
    const items = completedRoutes?.getCompletedRoutes.items;
    if (items == null) return [];

    return items.map(convertCompletedRouteToTableRow);
  };

  const convertCompletedRouteToTableRow = (completedRoute: PartialCompletedRoute): ICompletedRouteRow => ({
    id: completedRoute.id,
    progress: completedRoute.progress,
    startDate: formatDate(completedRoute.startDate),
    endDate: formatDate(completedRoute.endDate),
    materialName: completedRoute.material.name,
    tourName: completedRoute.tourName,
    tourNumber: completedRoute.tourNumber,
    driverName: completedRoute.driverName,
  });

  return { mappedCompletedRoutes: convertCompletedRoutesToTableRow(completedRoutesQuery), setCompletedRoutesQuery };
};
