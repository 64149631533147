import { DriverLocation } from "./../../../api/nachfahrbarkeit-api/generated";
import { UserRole } from "../../../api/nachfahrbarkeit-api/generated";

export enum LocationType {
  LOCATION = "LOCATION",
}

interface ILocationDictionaryItem {
  accessor: "locations";
}

export const LocationTypeDictionary: {
  [key: string]: ILocationDictionaryItem;
} = {
  [LocationType.LOCATION]: {
    accessor: "locations",
  },
};

interface IRoleOptions {
  hasAssignedLocations: boolean;
  collection: DriverLocation[];
  locationType: LocationType;
  possibleLocationTypes: LocationType[];
}

export const getOptionsForRole = (
  role: UserRole | undefined,
  locations: DriverLocation[] | null = [],
): IRoleOptions => {
  switch (role) {
    case UserRole.GUIDED_DRIVER:
    case UserRole.SUPER_ADMIN:
    case UserRole.GUIDANCE_ADMIN:
    default:
      return {
        collection: locations || [],
        hasAssignedLocations: true,
        locationType: LocationType.LOCATION,
        possibleLocationTypes: [LocationType.LOCATION],
      };
  }
};
